import { BASE_API_URL } from "api/config";
import { createAxios } from "./axios";

const systemSettingsApi = createAxios({
  baseURL: BASE_API_URL + "/settings",
});

export const getAllSettings = () => {
  return systemSettingsApi.get("/categorized");
};

export const createSettings = (body) => {
  return systemSettingsApi.post("/categorized", body);
};
