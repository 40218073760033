import axios from 'axios';
import React from "react";
import {AuthUtils} from 'utils/AuthUtil';

export const createAxios = (config) => {
    const instance = axios.create(config);
    const authType = true;

    instance.interceptors.request.use(config => {
        // todo determine what kind of auth
        const token = authType
            ? AuthUtils.getKeycloakToken()
            : AuthUtils.getMSAuthToken();

        // maybe I should extract this entire function due to possibility of
        // future auths having much more different implementations than this
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token}`;
        }
        config.headers.common['Accept'] = '*/*';
        return config
    })

    const onSuccess = () => {
        return response => response;
    }

    function onError() {
        return error => authType
            ? AuthUtils.handleKeycloakError(error)
            : AuthUtils.handleMsAuthError(error);
    }

    instance.interceptors.response.use(onSuccess(), onError())

    return instance;
}