import {
    SET_EDITOR_UTILITIES,
    SET_EDITOR_FOCUSED
} from 'redux/actions/types/formBuilder';

const INITIAL_STATE = {
    utilities: null,
    isEditorFocused: false
};

const formBuilderReducer = (state = INITIAL_STATE, action) => {
    const { type, utilities, isEditorFocused } = action;

    switch (type) {
        case SET_EDITOR_UTILITIES:
            return {
                ...state,
                utilities
            }

        case SET_EDITOR_FOCUSED:
            return {
                ...state,
                isEditorFocused
            }

        default:
            return state
    }
};

export default formBuilderReducer;