import { connect } from 'react-redux'
import { questionsFetchData } from "redux/actions/questions";
import DataTable from "../../components/Layout/DataTable";
import React from "react";

const mapStateToProps4 = (state) => {
    return {
        questions: state.questions,
        hasErrored: state.questionsHasErrored,
        isLoading: state.questionsIsLoading
    };
};

const mapDispatchToProps4 = (dispatch, props) => {
    return {
        fetchData: (page, start, length, search, sortOrder, sortDirection) => dispatch(questionsFetchData(page, start, length, search, sortOrder, sortDirection, props.appointmentId, props.patientId))
    };
}

class QuestionDataTable extends DataTable {

    render(props) {
        return super.render(props, this.props.questions.data, this.props.questions.recordsTotal);
    }
}

export default connect(mapStateToProps4, mapDispatchToProps4)(QuestionDataTable);