import { Divider } from 'antd';
import React from 'react';
import Colors from './Colors';
import Titles from './Titles';
import Typography from './Typography';

const BrandingSection = ({ branding }) => {
    return  <>
                <Titles branding={branding} />
                <Divider />
                <Typography branding={branding} />
                <Divider />
                <Colors branding={branding} />
            </>;
}

export default BrandingSection;
