import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
const SettingsHeader = ({ title, subTitle, onClick, buttonTitle }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={9} xl={9}>
          <h3 className="fivef-pageTitle font-weight-bolder mt-0 mb-2">
            {title}
          </h3>
          <h5 className="text-muted mb-4">{subTitle}</h5>
        </Col>
        <Col xs={12} sm={12} md={3} xl={3} className="text-right">
          <Button type="primary" onClick={onClick}>
            {buttonTitle}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsHeader;
