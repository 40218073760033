import React from 'react';
import UploadFiles from "../../components/UploadFiles";

const DocumentForm = (props) => {

    return (
        <div className="modal-dialog">
            <div className="modal-content" >
                <div className="modal-header">
                    <h3>Documents</h3>
                </div>
                <div className="modal-body">
                        <UploadFiles appointmentId={props.appointmentId} patientId={props.patientId} />
                </div>
                <div className="modal-footer">
                    <button className="btn btn-default pull-left" data-dismiss="modal" onClick={props.onClose}>Close</button>
                </div>

            </div>
        </div>
    );
};

export default DocumentForm;