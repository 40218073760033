import React from 'react';
import {message,Modal, Select} from 'antd';
import {updateAppointmentStatus} from "../../../api/appointments";
import titleize from "titleize";

const {Option} = Select;

let statusValue;

function StatusModal(props) {
    const {
        setStatusModalVisibility,
        isStatusModalVisible,
        id,
        onSuccess
    } = props;

    const statusList = [
        {
            value: 'UNCONFIRMED',
            text: 'UNCONFIRMED',
        },        
        {
            value: 'DECLINED',
            text: 'DECLINED',
        },
        {
            value: 'CONFIRMED',
            text: 'CONFIRMED',
        },
        {
            value: 'CHECKED_IN_WAITING',
            text: 'CHECKED IN WAITING',
        },
        {
            value: 'CALLED_BACK_TO_QUEUE',
            text: 'CALLED BACK TO QUEUE',
        },
        {
            value: 'CHECKED_IN',
            text: 'CHECKED IN',
        },
        {
            value: 'IN',
            text: 'IN',
        },
        {
            value: 'CHECKED_OUT',
            text: 'CHECKED OUT',
        },
        {
            value: 'NO_SHOW',
            text: 'NO SHOW',
        }
    ]

    return (
        <Modal
            title='Change Appointment Status'
            visible={isStatusModalVisible}
            onCancel={() => setStatusModalVisibility(false)}
            destroyOnClose={true}
            cancelText={'Close'}
            okText={'Save Changes'}
            onOk={async () => { // I think this is how it works?
                if (statusValue !== undefined) {
                   await updateAppointmentStatus({
                        id: id,
                        status: statusValue
                    });
                }
                onSuccess();
                message.success(`${titleize('Appointment status')} changed.`)
                setStatusModalVisibility(false);
            }}
            centered
        >
            <label>Status:</label><br/>
            <Select
                showSearch
                placeholder="Select Status"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                size='large'
                style={{
                    width: '100%',
                }}
                onChange={(value) => statusValue = value}
            >
                {statusList && statusList.length > 0 && statusList.map((status, i) => (
                    <Option key={status.value} value={status.value}>{status.text}</Option>
                ))}
            </Select>
        </Modal>
    );
}

export default StatusModal;