import axios from 'axios';
import BaseService from "./BaseService";

const DOCUMENT_API_BASE_URL = "/api/documents";

class DocumentService extends BaseService {

    constructor() {
        super(DOCUMENT_API_BASE_URL);
    }

    getDocuments(page, start, length, search, sortOrder, sortDirection, appointmentId, patientId){
        return axios.get(DOCUMENT_API_BASE_URL + '/document', {
            timeout: 5000,
            params: {
                page: page,
                draw: 1,
                start: start,
                sortOrder: sortOrder,
                sortDirection: sortDirection,
                length: length,
                search: search,
                appointmentId: appointmentId,
                patientId: patientId
            }
        });
    }
}

export default new DocumentService()