import React, {useState} from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {deleteCollateral, getCollateralsDatatable} from "api/collaterals";
import DeleteModal from "components/Modals/DeleteModal";
import {getCollateralURL} from 'api/collaterals';
import {Link} from "react-router-dom";
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CollateralForm from 'pages/Collateral/components/CollateralForm';

const CollateralPage = (props) => {
    const [draw, setDraw] = useState(0);

    const [collateralId, setCollateralId] = useState("");
    const [collateral, setCollateral] = useState(null);
    const [collateralFormVisibility, setCollateralFormVisibility] = useState(false);

    const columns = [
        {
            title: <strong>Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (value, record, index) => {
                return <Link onClick={async ()=>{
                    let response = await getCollateralURL(record?.id);
                    // console.log(response) how am i supposed to know if it should be file or url?
                    window.open(response.data);
                }}>{value}</Link>;

            },
        },
        {
            title: <strong>Description</strong>,
            dataIndex: "description",
            key: "description",
            sorter: true,
        },
        {
            title: <strong>URL</strong>,
            dataIndex: "url",
            key: "url",
            sorter: true,
        },
        {
            title: <strong>Options</strong>,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text, record, value) => (
                <ActionButtonsDropdown 
                    menus={
                        [
                            {
                                label: 'Update Resource',
                                icon: <EditOutlined />,
                                onClick: async () => {
                                    const response = await getCollateralURL(record?.id);
                                    setCollateral({...record, fileURL: response?.data});
                                    setCollateralFormVisibility(true);
                                }
                            },
                            {
                                label: 'Delete Resource', 
                                icon: <DeleteOutlined />, 
                                onClick: () => setCollateralId(text)
                            }
                        ]
                    }
                />
            ),
        },
    ];

    return (
        <>
            <DeleteModal
                entity="resource"
                visible={collateralId}
                setVisibility={setCollateralId}
                entityId={collateralId}
                deleteFunction={deleteCollateral}
                onSuccess={() => setDraw(draw + 1)}
            />
            <CollateralForm 
                title={'Update Resource'}
                visible={collateralFormVisibility}
                collateral={collateral}
                setVisibility={setCollateralFormVisibility}
                onSuccess={() => setDraw(draw + 1)}
            />
            <CRUDDataTable
                draw={draw}
                entity="resource"
                fetchDataFunction={async (params) => {
                    return await getCollateralsDatatable(params);
                }}
                createModal={<CollateralForm title='New Resource' collateral={null}/>}
                columns={columns}
            />
        </>
    );
};

export default CollateralPage;
