import React, { useState, Suspense, useEffect } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {Button as AntdButton, Modal, Tooltip, Spin, message} from 'antd';

import Editor from 'pages/FormBuilder';

import { FileSearchOutlined, FileTextOutlined, DeleteOutlined } from '@ant-design/icons';
import { getAppointmentFormSubmissions, updateFormSubmissions, deleteFormSubmission } from 'api/formsSubmissions';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import { Badge, Col, Container, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/AppointmentForms.scss'

import CreateFormModal from './CreateFormModal';
import {useMediaQuery, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {Link} from 'react-router-dom';
import DeleteModal from 'components/Modals/DeleteModal';
import { setWillTaskListRefresh } from 'redux/actions/appointment';

const AppointmentForm = ({appointmentId}) => {
  const [isFormModalVisible, setFormModalVisibility] = useState(false);
  const [title, setTitle] = useState();
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [activeSubmissionFormId, setActiveSubmissionFormId] = useState(null);
  const [deleteFormId, setDeleteFormId] = useState('');
  const [draw, setDraw] = useState(0);
  const formBuilderState = useSelector(state => state.formBuilder);
  const dispatch = useDispatch();

  const nonValuedControls = ['Container', 'Card', 'Panel', 'Column', 'Button', 'Text'];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: "formDesign.name",
      key: "formDesign.name",
      sorter: true,
      render: (text, record, value) => {
        let statusBadge = null;

        if (record?.status === 'NEW') {
          statusBadge = <Badge variant="info">NEW</Badge>;
        }

        if (record?.status === 'DRAFT') {
          statusBadge = <Badge variant="secondary">DRAFT</Badge>;
        }

        if (record?.status === 'COMPLETE') {
          statusBadge = <Badge variant="success">COMPLETE</Badge>;
        }

        return (
          <Link onClick={() => previewForm(record)}>
            <FileTextOutlined /> <span style={{position: 'relative', top: '3px'}}>{record?.formDesign.name}</span> {statusBadge}
          </Link>
        ) 
      }
    },
    {
      title: <strong>Options</strong>,
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text, record, value) => (
        <ActionButtonsDropdown 
          menus={
            [
              {
                label: 'Preview Form',
                icon: <FileSearchOutlined />,
                onClick: () => previewForm(record)
              },
              {
                label: 'Delete Form',
                icon: <DeleteOutlined />,
                onClick: () => setDeleteFormId(record?.id)
              }
            ]
          }
        />
      ),
    },
  ];

  const previewForm = record => {
    setActiveSubmissionFormId(record?.id);
    setSelectedFormId(record?.formDesign.id);
    setTitle(record?.formDesign.name);
    setFormModalVisibility(true);
  }

  useEffect(loadAppointmentForm => {
    setDraw(draw + 1);
  }, [appointmentId]);

  const handleModalCancel = () => {
    setFormModalVisibility(false)
  }

  const validateAndGetFormValues = () => {
    const {
      actions: { setProp },
      query,
      nodes,
    } = formBuilderState.utilities;

    let data = [];
    let errors = [];

    function isNodeOrParentNodeHidden(node) {
      if(node.hidden) return true;
      else if(!node.parent) return false;
      else return isNodeOrParentNodeHidden(query.getSerializedNodes()[node.parent])
    }

    Object.keys(nodes).map((serializeNodeKey) => {
      const nodeControl = query.getSerializedNodes()[serializeNodeKey];
      if (!nonValuedControls.includes(nodeControl.displayName)) {
        if (nodeControl.props.type === 'datetime' && !nodeControl.props.value) {  
          nodeControl.props.value = moment();
        }

        if (nodeControl.props.isRequired && !nodeControl.props.value && !isNodeOrParentNodeHidden(nodeControl)) {
          errors.push(serializeNodeKey);

          setProp(serializeNodeKey, (props) => {
            if (props.error) {
              if (
                nodeControl.props.name === "input" &&
                nodeControl.props.type === "datetime"
              )
                props.value = moment();
              if (nodeControl.props.name === "select")
                props.value =
                  props.options &&
                  props.options.length > 0 &&
                  props.options[0].value;
              if (
                nodeControl.props.name === "input" &&
                nodeControl.props.type === "text"
              )
                props.value = "";

              props.error.required.isVisible = true;
            }
          });
        } else {
          if (nodeControl.props.value) {
            setProp(serializeNodeKey, (props) => {
              if (props.error) props.error.required.isVisible = false;
            });

            delete errors[serializeNodeKey];

            data.push({
              id: serializeNodeKey,
              name: nodeControl.props.name,
              label:
                nodeControl.props.labelText &&
                nodeControl.props.labelText.replace(":", ""),
              value: nodeControl.props.value,
            });
          }
        }
      }
    });

    return {
      data,
      errors
    }
  }

  const saveAsDraftClick = async () => {
    const {
      actions: { setProp },
      query,
      nodes
    } = formBuilderState.utilities;

    let data = [];

    Object.keys(nodes).map(serializeNodeKey => {
      const nodeControl = query.getSerializedNodes()[serializeNodeKey];

      if (!nonValuedControls.includes(nodeControl.displayName)) {
        if (nodeControl.props.value) {
          data.push({
            id: serializeNodeKey,
            name: nodeControl.props.name,
            label: nodeControl.props.labelText && nodeControl.props.labelText.replace(':', ''),
            value: nodeControl.props.value
          });
        }
      }
    });

    const hide = message.loading('Saving form as draft, please wait...', 0);

    try {
      await updateFormSubmissions(activeSubmissionFormId, JSON.stringify({
        content: JSON.stringify(data),
        status: 'DRAFT'
      }));

      hide();

      message.success('Form successfully submitted as draft');
    } catch (error) {
      hide();
      console.log('error', error);
      message.error('Form submission failed');
    }
  }

  const saveFormAsComplete = async () => {
    // const {
    //   actions: { setProp },
    //   query,
    //   nodes
    // } = formBuilderState.utilities;

    // let data = [];
    // let errors = [];

    // Object.keys(nodes).map(serializeNodeKey => {
    //   const nodeControl = query.getSerializedNodes()[serializeNodeKey];

    //   if (!nonValuedControls.includes(nodeControl.displayName)) {
    //     if (nodeControl.props.isRequired && !nodeControl.props.value) {
    //       errors.push(serializeNodeKey);

    //       setProp(serializeNodeKey, props => {

    //         if (props.error) {
    //           if (nodeControl.props.name === 'input' && nodeControl.props.type === 'datetime') props.value = moment();
    //           if (nodeControl.props.name === 'select') props.value = props.options && props.options.length > 0 && props.options[0].value;
    //           if (nodeControl.props.name === 'input' && nodeControl.props.type === 'text') props.value = '';

    //           props.error.required.isVisible = true;
    //         }
    //       });
    //     } else {
    //       if (nodeControl.props.value) {
    //         setProp(serializeNodeKey, props => {
    //           if (props.error) props.error.required.isVisible = false;
    //         });

    //         delete errors[serializeNodeKey];

    //         data.push({
    //           id: serializeNodeKey,
    //           name: nodeControl.props.name,
    //           label: nodeControl.props.labelText && nodeControl.props.labelText.replace(':', ''),
    //           value: nodeControl.props.value
    //         });
    //       }
    //     }
    //   }
    // });

    const { data, errors } = validateAndGetFormValues();

    if (errors && errors.length < 1) {
      const hide = message.loading('Saving form, please wait...', 0);

      try {
        await updateFormSubmissions(activeSubmissionFormId, JSON.stringify({
          content: JSON.stringify(data),
          status: 'COMPLETE'
        }));

        hide();
        setDraw(draw + 1);
        dispatch(setWillTaskListRefresh(true));
        message.success('Form successfully submitted');
        setFormModalVisibility(false);
      } catch (error) {
        hide();
        console.log('error', error);
        message.error('Form submission failed');
      }
    } else {
      message.error('Please fill in the required field(s).');
    }
  }


  return (
    <>
      <DeleteModal
        entity="form"
        visible={deleteFormId}
        setVisibility={setDeleteFormId}
        entityId={deleteFormId}
        deleteFunction={deleteFormSubmission}
        onSuccess={() => setDraw(draw + 1)}
      />
      <Suspense fallback={<Spin />}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={isFormModalVisible}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title' style={{
            borderBottom: '1px solid #ddd'
        }}>{title}</DialogTitle>
          <DialogContent>
              <Editor isEditor={false} formId={selectedFormId} activeSubmissionFormId={activeSubmissionFormId} />
          </DialogContent>
          <DialogActions style={{
          borderTop: '1px solid #ddd'
      }}>
            <Container className='View-Form-Action' fluid>
              <Row>
                <Col xs={12} sm={6} className='text-left p-0'>
                  <AntdButton size='large' className='mt-3' onClick={saveAsDraftClick}>Save as draft</AntdButton>
                </Col>
                <Col xs={12} sm={6} className='text-right p-0'>
                  <AntdButton size='large' className='mr-3 mt-3' key="Close" onClick={handleModalCancel}>Close</AntdButton>
                  <AntdButton size='large' className='mt-3' onClick={saveFormAsComplete} type='primary'>Submit</AntdButton>
                </Col>
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
      </Suspense>

      <CRUDDataTable
      fetchDataFunction={async (params) => {
          params = {
            ...params,
            sortOrder: 'id'
          }
          return await getAppointmentFormSubmissions(appointmentId, params);
      }}
        title="Forms"
        entity="form"
        highlightRow={(row, i) => row.staffReadStatus === 'UNREAD'}
        createModal={
          <CreateFormModal appointmentId={appointmentId}/>
        }
        columns={columns}
        draw={draw}
      />
    </>
  );
};

export default AppointmentForm;
