import React from 'react';
import {Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {questionsAddQuestion} from "redux/actions/questions";
import {connect} from "react-redux";

const QuestionForm = (props) => {

    const submitButton = () => {
        if (props.mode === 'Edit') {
            return <button type="submit" className="btn btn-success"
                           id="saveQuestionButton">Save
            </button>
        }
    }
    return (
        <Formik
            initialValues={{ id: props.id, 
                question: props.question,
                answer: props.answer,
                status: props.status,
                appointmentId: props.appointmentId,
                patientId: props.patientId
                }
            }
            validationSchema={Yup.object({
                question: Yup.string()
                    .max(25, 'Must be 3 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, {setSubmitting}) => {
                const question = {
                    id: values.id,
                    question: values.question,
                    answer: values.answer,
                    status: values.status,
                    appointmentId: values.appointmentId,
                    patientId: values.patientId
                }
                props.addQuestion(question);
                props.onClose();
            }

            }
        >
            <div className="modal-dialog">
                <div className="modal-content">

                    <Form id="editQuestionForm" className="form-horizontal">
                        <Field name="id" type="hidden"/>
                        <Field name="appointmentId" type="text"/>
                        <Field name="patientId" type="text"/>
                        <div className="modal-header">
                            <h3>{props.mode} Question</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="question">Question</label>
                                <Field name="question" type="text"/>
                                <ErrorMessage name="question"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="answer">Answer</label>
                                <Field name="answer" type="text"/>
                                <ErrorMessage name="answer"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="status">Status</label>
                                <Field name="status" type="text"/>
                                <ErrorMessage name="status"/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-default pull-left" data-dismiss="modal" onClick={props.onClose}>Close</button>
                            {submitButton()}
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
};

const mapStateToProps2 = (state) => {
    return {
        questions: state.questions,
        hasErrored: state.questionsHasErrored,
        isLoading: state.questionsIsLoading
    };
};
const mapDispatchToProps2 = (dispatch) => {
    return {
        addQuestion: (question) => dispatch(questionsAddQuestion(question))
    };
}


export default connect(mapStateToProps2, mapDispatchToProps2)(QuestionForm);