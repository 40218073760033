import { MODULES } from "./modules"

import { DASHBOARD_PERMISSIONS } from "./dashboard-permissions";
import { APPOINTMENTS_PERMISSIONS } from "./appointments-permissions"
import { TASKS_PERMISSIONS } from './tasks-permissions';
import { RESOURCES_PERMISSIONS } from './resources-permissions';
import { LOCATIONS_PERMISSIONS } from "./locations-permissions";
import { VISITORS_PERMISSIONS } from "./visitors-permissions";
import { CHECK_IN_OUT_PERMISSIONS } from "./check-in-out-permissions";
import { USERS_PERMISSIONS } from "./users-permissions";
import { FORMS_PERMISSIONS } from "./forms-permissions"
import { BROADCAST_PERMISSIONS } from "./broadcast-permissions"
import { SETTINGS_PERMISSIONS } from "./settings-permissions";

export enum PERMISSIONS_ACTIONS {
    SET_PERMISSIONS = "SET_PERMISSIONS"
}

export interface PermissionsAction {
    type: PERMISSIONS_ACTIONS,
    payload: PermissionsState
}

type Permissions = {
    [MODULES.Dashboard]:  Array<keyof typeof DASHBOARD_PERMISSIONS>;
    [MODULES.Appointments]: Array<keyof typeof APPOINTMENTS_PERMISSIONS>;
    [MODULES.Tasks]: Array<keyof typeof TASKS_PERMISSIONS>;
    [MODULES.Resources]: Array<keyof typeof RESOURCES_PERMISSIONS>;
    [MODULES.Locations]: Array<keyof typeof LOCATIONS_PERMISSIONS>;
    [MODULES.Visitors]: Array<keyof typeof VISITORS_PERMISSIONS>;
    [MODULES.Check_In_Out]: Array<keyof typeof CHECK_IN_OUT_PERMISSIONS>;
    [MODULES.Users]: Array<keyof typeof USERS_PERMISSIONS>;
    [MODULES.Forms]: Array<keyof typeof FORMS_PERMISSIONS>;
    [MODULES.Broadcasts]: Array<keyof typeof BROADCAST_PERMISSIONS>;
    [MODULES.Settings]: Array<keyof typeof SETTINGS_PERMISSIONS>;
}

export interface PermissionsState {
    permissions: Partial<Permissions>
}
