import {alertService} from "../../services/AlertService";
import LocationService from '../../services/LocationService'

export function locationsHasErrored(bool) {
    return {
        type: 'LOCATIONS_HAS_ERRORED',
        hasErrored: bool
    };
}

export function locationsIsLoading(bool) {
    return {
        type: 'LOCATIONS_IS_LOADING',
        isLoading: bool
    };
}

export function locationsFetchDataSuccess(locations) {
    return {
        type: 'LOCATIONS_FETCH_DATA_SUCCESS',
        locations
    };
}

export function locationsDeleteDataSuccess(locations) {
    return {
        type: 'LOCATIONS_REMOVE_LOCATION_SUCCESS',
        locations
    };
}

export function locationsRemoveLocation(id) {
    return (dispatch) => {
        LocationService.deleteItem(id)
            .then(function (res) {
                alertService.success('Success!!', {
                    autoClose: true,
                    keepAfterRouteChange: false
                });
                dispatch(locationsDeleteDataSuccess(id))
                return res;
            }).then(function (res) {
            dispatch(locationsFetchData(0, 0, 5, "", []));
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res);
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(locationsHasErrored(true))
        });
    };
}

export function locationsAddLocation(location) {
    return (dispatch) => {
        LocationService.addItem(location)
            .then(function (res) {
                dispatch(locationsFetchData(0, 0, 5, "", []));
            }).then(function (res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(locationsHasErrored(true))
        });
    };
}

export function locationsFetchData(page, start, length, search, sortOrder, sortDirection) {
    return (dispatch) => {
        dispatch(locationsIsLoading(true));
        LocationService.getSystemProperties(page, start, length, search, sortOrder, sortDirection)
            .then(function (res) {
                dispatch(locationsIsLoading(false))
                return res;
            }).then(function (res) {
            dispatch(locationsFetchDataSuccess(res.data))
        }).catch(function (res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(locationsHasErrored(true))
        });
    };
}
