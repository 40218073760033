import React, { useState, useRef, useEffect } from 'react';
import DefaultImage from 'assets/images/default-thumbnail.jpg';
import styled from 'styled-components';
import { setBrandingLogo } from 'redux/actions/branding';
import { getBrandingLogoByTenant } from 'api/branding';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'assets/images/5f-dark-logo.png';

const StyledImagePreviewContainer = styled.div`
    display: grid;

    & > div {
        display: grid;
        background: #D9D9D9;

        &::before {
            content: "";
            padding-bottom: 100%;
            display: block;
            grid-area: 1 / 1 / 2 / 2;
        }

        & > img {
            grid-area: 1 / 1 / 2 / 2;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`;

const StyledFileChooseButton = styled.button`
    border: none;
    background: #0170FE;
    width: 100%;
    padding: 10px 15px;
    color: #fff;
    font-weight: 700;
`;

const ImagePreviewUploader = ({
    onFileChoose,
    fileChooseButtonText = 'Choose Image',
    branding
}) => {
    const inputFileRef = useRef(null);
    const [displayedImage, setDisplayedImage] = useState(Logo);
    const brandingState = useSelector(state => state.branding);

    let currentTenantUniqueId = branding.tenantUniqueId;

    const [currentSelectedTenantUniqueId, setCurrentSelectedTenantUniqueId] = useState(currentTenantUniqueId);

    useEffect(() => {
        setCurrentSelectedTenantUniqueId(currentTenantUniqueId);
    }, [currentTenantUniqueId]);
    
    const dispatch = useDispatch();
    
    const onChangeEvent = event => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            const imageRawFile = event.target.files[0];
            reader.onload = e => {
                setDisplayedImage(e.target.result);
                setCurrentSelectedTenantUniqueId(currentTenantUniqueId);
                dispatch(setBrandingLogo(imageRawFile));
            }
            
            const imageFile = reader.readAsDataURL(event.target.files[0]);

            if (onFileChoose) {
                onFileChoose(imageFile);
            }
        }
    }

    useEffect(() => {
        if (brandingState && brandingState.list.length > 0) {
            console.log('currentSelectedTenantUniqueId', currentSelectedTenantUniqueId);

            const currentBranding = brandingState.list.find(brandingItem => brandingItem.tenantUniqueId === currentSelectedTenantUniqueId);

            if (currentBranding.logo) {
                let fileReader = new FileReader();
                fileReader.readAsDataURL(currentBranding.logo);

                fileReader.onload = e => {
                    setDisplayedImage(e.target.result);
                }
            } else {
                setDisplayedImage(getBrandingLogoByTenant(currentSelectedTenantUniqueId));
            }
        }
    }, [currentSelectedTenantUniqueId]);

    // useEffect(() => {
    //     (currentImage && DefaultImage !== currentImage ? setDisplayedImage(currentImage) : setDisplayedImage(getBrandingLogoByTenant(currentSelectedTenantUniqueId)))
    // }, [currentImage, currentSelectedTenantUniqueId])

    const onButtonChooseClick = () => {
        if (inputFileRef) {
            inputFileRef.current.click();
        }
    }

    return  <>
                {displayedImage && Logo && <StyledImagePreviewContainer>
                    <div>
                        <img src={displayedImage} onError={() => {
                            setDisplayedImage(Logo);
                        }} />
                    </div>
                </StyledImagePreviewContainer>}
                <StyledFileChooseButton
                    className='choose-image'
                    onClick={onButtonChooseClick}
                >
                    {fileChooseButtonText}
                </StyledFileChooseButton>
                <input
                    ref={inputFileRef}
                    type='file'
                    onChange={onChangeEvent}
                    hidden 
                />
            </>;
};

export default ImagePreviewUploader;
