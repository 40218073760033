import React, { useRef, useState, useEffect } from "react";
import { Typography, Modal, Upload, Button, Row, Col, message} from "antd";
import { Formik } from "formik";
import { Form, Input, ResetButton, SubmitButton } from "formik-antd";
import * as yup from "yup";
import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { createCollateral } from "api/collaterals";
import titleize from 'titleize';
import {createDocument} from "../../../../api/documents";
import { createAppointmentAttachment } from "api/attachments";


const { Title } = Typography;

const entity = `attachment`;
const initialValues = {
  description: ""
};

const schema = yup.object().shape({
  // description: yup
  //   .string()
  //   .min(2, "Too short.")
  //   .max(255, "Too long.")
  //   .required("Please enter a description."),
});

const CreateAttachmentModal = ({ visible, setVisibility, icon, appointmentId, onSuccess }) => {
  const [files, setFiles] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState('');
  const formikRef = useRef(null);

  function onRemoveFile(file) {
    const index = files.indexOf(file);
    const newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  async function create(values) {
    try {
        message.loading(`Uploading ${entity}...`, 0);
        const form = new FormData();
        Object.keys(values).forEach(key => form.append(key, values[key]));
        form.append('description', descriptionValue);
        form.append('appointmentId', appointmentId);
        files.forEach(file => form.append("file", file, file.name));
        const res = await createAppointmentAttachment(form);
        message.destroy();
        message.success(`${titleize(entity)} successfully uploaded.`);
        onSuccess();
        setVisibility(false)
    } catch (error) {
        message.destroy();
        message.error(error.response.data.message);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}>{icon}{`New ${titleize(entity)}`}</Title>}
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values => create(values)}
        onReset={() => setFiles([])}
        render={() => (
          <Form layout="vertical" colon={false}>
                        <Row className="mb-4">
              <Col>
              <Upload
              customRequest={() => {
                return;
              }}
              beforeUpload={(file) => {
                setFiles([file]);
                return false;
              }}
              onRemove={onRemoveFile}
              className="mb-4"
              fileList={files}
            >
              <Button icon={<UploadOutlined />}>{`Upload ${titleize(entity)}`}</Button>
            </Upload>
              </Col>
            </Row>
            {/* <Form.Item label="Description" name="description" className="mb-4">
              <Input.TextArea
                name="description"
                autoSize={{ minRows: 3, maxRows: 10 }}
              />
            </Form.Item> */}
            <textarea
                // autoSize={{
                //   minRows: 5,
                //   maxRows: 7,
                // }}
                style={{width: '100%'}}
                onChange={e => setDescriptionValue(e.target.value)}
            />
            <Row gutter={4} className="d-flex justify-content-end">
                <Col>
                    <ResetButton>Reset</ResetButton>
                </Col>
                <Col>
                    <SubmitButton>Create</SubmitButton>
                </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateAttachmentModal;
