import React from "react";
import Aux from "../../components/hoc/AuxFunction";
import AppointmentModalSendMessage from "../Appointments/AppointmentModalSendMessage";

/* Action menu for Messages

This is used when an admin wants to perform edit and delete actions,
and also used when a patient wants to see their messages against an appointment

This is encapsulated within Messages component

*/

const MessageActionMenu = (props) => {

    const viewButton = () => {
        if (props.mode === 'View') {
            return <AppointmentModalSendMessage buttonText="View"
                                        id={props.id}
                                        mode={props.mode}
                                    appointmentId={props.appointmentId}
                                    emailAddress={props.emailAddress}
                                    subject={props.subject}
                                    messageText={props.messageText}
                                    phoneNumber={props.phoneNumber}

            />
        }
    }

    return <Aux> {viewButton()}</Aux>
}

export default MessageActionMenu;
