import {Card, Col, message, Modal, Radio, Row, Select, Space, Typography} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import React, {useEffect, useRef, useState} from "react";
import {createVisitor, getVisitorById, updateVisitor} from "api/visits"
import * as yup from "yup";
import {getAllLocations} from "../../../api/locations";
import HospitalSelect from "../../../components/location/hospitalSelect";

const {Title, Text} = Typography;

const CreateCollateralSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short.")
    .max(30, "Too long.")
    .required("Please enter your first name."),
  lastName: yup
    .string()
    .min(2, "Too short.")
    .max(255, "Too long.")
    .required("Please enter your last name."),
  email: yup
    .string()
    .email(),
  contactNumber: yup
    .number()
});
const EditVisitorForm = ({entity, visible, setVisibility, deleteFunction, onSuccess, visitId}) => {
  const [files, setFiles] = useState([]);
  const formikRef = useRef(null);
  const {Option} = Select;
  const [type, setType] = useState('VISITOR')
  const [contactMethod, setContactMethod] = useState('BOTH')
  const [visitor, setVisitor] = useState({});
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState();
  useEffect(() => {
    (async () => {
      let response = await getAllLocations();
      setHospitalOptions(response.data.data)
    })();
  }, [])

  function onChange(value) {
    setSelectedHospital(value);
  }

  function onBlur() {
  }

  function onFocus() {
  }

  function onSearch(val) {
  }

  useEffect(() => {
    if (!visible) {
      reset();
    }
    if (visitId !== undefined)
      (async () => {
        let res = await getVisitorById(visitId);
        setVisitor(res.data.data);
      })()
  }, [visible])

  async function create(values) {
    try {
      values.type = type;
      values.preferredContactMethod = contactMethod;

      let visit = {
        location: selectedHospital,
        visitor: {
          contactNumber: values.contactNumber,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          preferredContactMethod: values.preferredContactMethod
        },
        type: values.type
      }


      const form = new FormData();
      message.loading('Creating visitor...', 0);
      let data = new Blob([JSON.stringify(visit)], {type: 'application/json'});
      form.append("data", data);
      form.append("file", values.file);

      const res = await createVisitor(form);


      // @TODO: Remove in production, or maybe change it later on

      // Object.keys(values).forEach(key => form.append(key, values[key]));
      // files.forEach(file => form.append("file", file, file.name));
      // const res = await createCollateral(form);
      message.destroy();
      message.success('New visitor created.');
      onSuccess();
      setVisibility(false)
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  const style = {width: '90%'};

  return (<Modal
    title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}>Edit Visitor</Title>}
    visible={visible}
    onCancel={() => setVisibility(false)}
    footer={null}
    width={'70%'}
  >
    <Formik
      innerRef={formikRef}
      initialValues={{firstName: "", lastName: ""}}
      // validationSchema={CreateCollateralSchema}
      onSubmit={values => create(values)}
      onReset={() => setFiles([])}
      render={() => (
        <Form layout="vertical" colon={false}>
          <Space direction="vertical" style={{width: '100%'}}>
            <Card title="Visitor details" style={{width: '100%'}}>
              <Row>


                <Col span={12}>


                  <Form.Item label="First Name" name="firstName" className="mb-4">
                    <Input name="firstName" style={style} value={visitor.visitor?.firstName}
                           onChange={(value) => {
                             visitor.visitor.firstName = value.target.value
                           }}
                    />
                  </Form.Item>
                  <Form.Item label="Last Name" name="lastName" className="mb-4">
                    <Input name="lastName" style={style} value={visitor.visitor?.lastName}
                           onChange={(value) => {
                             visitor.visitor.lastName = value.target.value
                           }}/>
                  </Form.Item>
                  <Form.Item label="Visitor Type" name="type" className="mb-4">
                    <Radio.Group style={style} onChange={e => {
                      setType(e.target.value)
                      visitor.type = e.target.value
                    }} value={visitor.type}
                                 buttonStyle="solid">
                      <Radio.Button value="VISITOR">Visitor</Radio.Button>
                      <Radio.Button value="PATIENT">Patient</Radio.Button>
                      <Radio.Button value="STAFF">Staff</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>

                  <Form.Item label="Email" name="email" className="mb-4">
                    <Input name="email" style={style} value={visitor.visitor?.email}
                           onChange={(value) => {
                             visitor.visitor.email = value.target.value
                           }}/>
                  </Form.Item>
                  <Form.Item label="Contact Number" name="contactNumber" className="mb-4">
                    <Input name="contactNumber" style={style} value={visitor.visitor?.contactNumber}
                           onChange={(value) => {
                             visitor.visitor.contactNumber = value.target.value
                           }}/>
                  </Form.Item>
                  <Form.Item label="Contact Method" name="contactMethod" className="mb-4">
                    <Radio.Group style={style} onChange={e => {
                      setContactMethod(e.target.value)
                      visitor.visitor.preferredContactMethod = e.target.value
                    }}
                                 value={visitor.visitor?.preferredContactMethod}
                                 buttonStyle="solid">
                      <Radio.Button value="EMAIL">Email</Radio.Button>
                      <Radio.Button value="SMS">Sms</Radio.Button>
                      <Radio.Button value="BOTH">Both</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title="Patient details">
              <HospitalSelect onChange={onChange}
                              removeAllOption={true}
                              defaultValue={visitor.location}
                              useEntity={true}
              />
            </Card>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton>Save</SubmitButton>
              </Col>
            </Row>
          </Space>
        </Form>
      )}
    />
  </Modal>)
}

export default EditVisitorForm;