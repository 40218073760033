import React from 'react';
import {FaCommentDots} from "react-icons/fa";
import {IconContext} from "react-icons";
import Modal from '@material-ui/core/Modal';
import {Formik} from "formik";
import Constants from "../../components/Layout/ModalConstants";
import {connect} from 'react-redux';
import { appointmentsAddMessage } from "../../redux/actions/appointments";
import SendMessageForm from "../Messages/SendMessageForm";

const AppointmentModalSendMessage = (props) => {

    const classes = Constants.useStyles();
    const [modalStyle] = React.useState(Constants.getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseEvent = () => {
        setOpen(false);
    };

    return (
        <IconContext.Provider value={{style: {fontSize: '26px', paddingRight: '10px'}}}>
            <button type="button" className={props.classes} onClick={handleOpen}>
                <FaCommentDots/> {props.buttonText}
            </button>
            <Modal open={open} onClose={handleCloseEvent} aria-labelledby="Appointments" aria-describedby="Appointments"
            >
                <div style={modalStyle} className={classes.paper}>
                    <Formik
                        initialValues={{}}
                        onSubmit={(values, {setSubmitting}) => {
                            props.sendAppointmentMessage(values.id);
                            handleCloseEvent();
                        }
                        }
                    >
                        <SendMessageForm name={props.firstName}
                                         appointmentId={props.appointmentId}
                                         mode={props.mode}
                                         emailAddress={props.emailAddress}
                                         subject={props.subject}
                                         messageText={props.messageText}
                                         phoneNumber={props.phoneNumber}
                                         onClose={handleCloseEvent}/>
                    </Formik>
                </div>
            </Modal>
        </IconContext.Provider>
    );
};

const mapDispatchToProps1 = (dispatch) => {
    return {
        sendAppointmentMessage: (id) => dispatch(appointmentsAddMessage(id))
    };
}


export default connect(null, mapDispatchToProps1)(AppointmentModalSendMessage);
