import React from "react";
import Aux from "../../components/hoc/AuxFunction";
import DataTable from "./MessageDataTable";
import MessageActionMenu from "./MessageActionMenu";

const Messages = (props) => {

    const cols = [

        {
            name: "emailAddress",
            label: "Email",
            options: {
                filter: false
            },
        },
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: false
            }
        },
        {
            name: "messageText",
            label: "Message",
            options: {
                filter: false
            }
        },
        {
            name: "phoneNumber",
            label: "Phone Number",
            options: {
                filter: false
            }
        },
        // hidden columns
        {
            name: "id",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return <MessageActionMenu
                        mode={props.mode}
                        id={tableMeta.rowData[4]}
                        appointmentId={props.appointmentId}
                        emailAddress={tableMeta.rowData[0]}
                        subject={tableMeta.rowData[1]}
                        messageText={tableMeta.rowData[2]}
                        phoneNumber={tableMeta.rowData[3]}

                    />
                }
            },
        },
    ];
    return (
        <Aux>
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="page-header">Messages</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">

                        </div>
                        <div className="panel-body">
                            <DataTable appointmentId={props.appointmentId} columns={cols} heading="Messages"/>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
};

export default Messages;
