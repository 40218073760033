import React from "react";
import { Paper } from "@material-ui/core";
import { Container as BsContainer } from 'react-bootstrap';
import cx from 'classnames';

import { useNode, useEditor } from '@craftjs/core';

const Container = ({background, padding = 10, children}) => {
    const { 
        connectors: { connect, drag },
    } = useNode();
    const { query } = useEditor();

    return (
        <Paper 
            className={cx({
                'With-Mark': query.getOptions().enabled
            }, 'Container-Element')} 
            ref={ref => {
                connect(drag(ref));
            }} 
            style={{margin: "5px 0", background, minHeight: '100vw', padding: `${padding}px`}}
        >
            <BsContainer>
            {children}
            </BsContainer>
        </Paper>
    );
};

Container.craft = {
    displayName: 'Container'
}

export default Container;
