export enum Color {
    Green = "#0EAC51",
    WhiteGray = "#efefef",
    White = "#fff",
    Red = "#A70C00",
    Orange = "#C86400",
    Blue = "#0077C0",
    BlueGreen = "#07ABA0",
    Gray = "#636e72",
    Purple = "#8e44ad",
    Black = "#2d3436",
    Yellow = "#FEF445",
}