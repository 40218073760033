import React from 'react';
import 'styles/Nav.scss';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { Button, Modal } from 'antd';
import { connect, useSelector } from 'react-redux';

import { toggleSideBarVisibility } from '../../../redux/actions/ui';
import {keycloak} from "api/keycloak";

function SlickTopMenu(props) {
    const { fullName, isSidebarVisible, toggleSideBarVisibility } = props;
    const { confirm } = Modal;
    const tenantState = useSelector(state => state.tenant);

    const showLogoutModal = e => {
        confirm({
            title: 'Confirm',        
            content: 'Are you sure you want to logout',
            okText: 'Yes',
            cancelText: 'No',
            maskClosable: true,
            onOk: keycloak.logout
        });
    }

    return (
        <Navbar className='fivef-navbar' expand="lg" bg="light" variant="light">
            <Navbar.Brand
                className='fivef-navbar-brand text-center'
                href="#home"
                style={{ 
                    textTransform: 'uppercase',
                    lineHeight: '15px'
                }}
            >
                {tenantState.branding.page.navbarHeaderTitle}<br/>
                <small style={{ 
                    color: `${tenantState.branding.theme.palette.primary.light}`, 
                    fontSize: '11px', 
                    fontWeight: '700', 
                    letterSpacing: '1px' 
                }}>
                    Digital Patient Journey
                </small>
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
            <Button className='btn-toggle-mb-sidebar d-block d-sm-block d-lg-none' onClick={() => toggleSideBarVisibility()}>
                {isSidebarVisible ? <MenuOpen fontSize='large' /> : <Menu fontSize='large' />}
            </Button>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link onClick={() => toggleSideBarVisibility()}>
                        {isSidebarVisible ? <MenuOpen fontSize='large' /> : <Menu fontSize='large' />}
                    </Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                    <Nav.Link className='fivef-user-dropdown'>
                        <PersonIcon fontSize='large' />
                    </Nav.Link>
                    <NavDropdown className='fivef-user-dropdown' title={`Welcome ${fullName} `} id="collasible-nav-dropdown">
                        <NavDropdown.Item href="#" onClick={showLogoutModal}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default connect(
    state => {
        return {
            isSidebarVisible: state.ui.isSidebarVisible
        }
    },
    dispatch => {
        return {
            toggleSideBarVisibility: () => dispatch(toggleSideBarVisibility())
        }
    }
)(SlickTopMenu);