import { createAxios } from './axios';
import { BASE_API_URL } from './config';

const broadcastApi = createAxios({
  baseURL: `${BASE_API_URL}/broadcast`
});

export const getBroadcasts = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        data: [
          {
            name: "CoVID 19 - Vaccinations",
            message: "Vaccines are now readily available at Balmain Hospital",
            location: "Balmain Hospital",
            recipient: "Patients",
            type: "SMS",
            date: "April 9, 2021, 12:15 PM",
          },
          {
            name: "Reminder: Second Doses",
            message:
              "Remember that there is a second dose that one needs in order for the vaccine to take full effect.",
            location: "Balmain Hospital",
            recipient: "Patients",
            type: "SMS",
            date: "April 9, 2021, 2:37 PM",
          },
        ],
      },
    });
  });
};

export const getBroadcastDataTable = (params: any) => {
  return broadcastApi.get('', {
    params: params
  });
}

export const createBroadcast = (params: any, queryParam: string | null) => {
  let payload = {}

  if (params.id) {
    payload = {
      id: params.id
    }
  } else {
    payload = {
      locationId: params.locationId,
      message: params.message,
      name: params.name,
      recipientType: params.recipientType,
      type: params.type
    };
  }

  return broadcastApi.post(`${queryParam ? queryParam : ''}`, payload);
}

export const deleteBroadcast = (id: any) => {
  return broadcastApi.delete(`/${id}`);
}