import React from "react";
import {IconContext} from "react-icons"
import {NavLink} from "react-router-dom";
import {connect} from 'react-redux';
import {Badge} from "antd";

import {
    Dashboard,
    PermContactCalendar,
    MenuBook,
    GroupAdd,
    Domain,
    People,
    QuestionAnswer,
    CheckBox,
    Description,
    Settings,
    Person,
    ExitToApp,
    FileCopy,
    FormatListNumbered,
    Announcement, Notifications, DoubleArrow
} from '@material-ui/icons';
import {useAuthContext} from "hooks/index";
import {useQuery} from 'react-query';
import {getIncompleteStaffTasks} from 'api/staffTasks';
import {useAppSelector} from './../../hooks/use-app-selector';
import {keycloak} from 'api/keycloak';
import NotificationsTemplates from "../../pages/NotificationsTemplates";

const SideMenu = (props) => {
    const {fullName, isSidebarVisible} = props;
    const logout = keycloak.logout;
    const REFETCH_INTERVAL_IN_MILLISECONDS = 5000;

    const {data: incompleteStaffTasks} = useQuery('getIncompleteStaffTasks', async () => {
        return await getIncompleteStaffTasks();
    }, {
        refetchInterval: REFETCH_INTERVAL_IN_MILLISECONDS
    })

    const permissions = useAppSelector(state => state.permissions.permissions);

    return (
        <IconContext.Provider value={{style: {fontSize: '26px', paddingRight: '10px'}}}>
            <div className={`navbar-default sidebar ${isSidebarVisible ? 'active' : ''}`} role="navigation">
                <div className="sidebar-nav navbar-collapse">
                    <ul className="nav" id="side-menu">
                        <li className='Sidebar-Sign-In d-none d-block d-sm-block d-md-none'><Person
                            fontSize='large'/> {`Signed in as:`} <strong>{fullName}</strong></li>
                        {permissions.Dashboard ?
                            <li><NavLink to="/dashboard" exact><Dashboard fontSize='large'/> Dashboard</NavLink>
                            </li> : null}
                        {permissions.Appointments ? <li><NavLink to="/appointments"><PermContactCalendar
                            fontSize='large'/> Appointments</NavLink></li> : null}
                        {permissions.Tasks ?
                            <li><NavLink to="/tasks"><Badge count={incompleteStaffTasks?.data}><FormatListNumbered
                                fontSize='large'/></Badge>Tasks </NavLink></li> : null}
                        {permissions.Resources ?
                            <li><NavLink to="/collateral"><MenuBook fontSize='large'/> Resources</NavLink></li> : null}
                        {permissions.Locations ?
                            <li><NavLink to="/locations"><Domain fontSize='large'/> Hospitals</NavLink></li> : null}
                        {permissions.Visitors ?
                            <li><NavLink to="/visitors"><GroupAdd fontSize='large'/> Accepted Visitors</NavLink>
                            </li> : null}
                        {permissions.Check_In_Out ?
                            <li><NavLink to="/checkin"><CheckBox fontSize='large'/> Check In</NavLink></li> : null}
                        {permissions.Users ?
                            <li><NavLink to="/users"><People fontSize='large'/> Users</NavLink></li> : null}
                        {/* <li><NavLink to="/questions"><QuestionAnswer fontSize='large' />{" "}Bot Questions</NavLink></li> */}
                        {permissions.Forms ?
                            <li><NavLink to="/forms"><FileCopy fontSize='large'/> Forms</NavLink></li> : null}
                        {/*<li><NavLink to="/documents"><Description fontSize='large' /> Documents</NavLink></li>*/}
                        {permissions.Broadcasts ?
                            <li><NavLink to="/broadcasts"><Announcement fontSize='large'/> Broadcasts</NavLink></li> : null}
                        <li><NavLink to="/checked-in-patients"><DoubleArrow fontSize="large" /> Checked In Patients</NavLink></li>
                        <li><NavLink to="/notifications-templates"><Notifications fontSize="large" /> Notifications Templates</NavLink></li>
                        {permissions.Settings ?
                            <li><NavLink to="/settings"><Settings fontSize='large'/> Settings</NavLink></li> : null}
                        <li className='d-none d-block d-sm-block d-lg-none'><NavLink to='' onClick={logout}
                                                                                     exact><ExitToApp
                            fontSize='large'/> Logout</NavLink></li>
                    </ul>
                </div>

            </div>
        </IconContext.Provider>
    )
};

export default connect(
    state => {
        return {
            isSidebarVisible: state.ui.isSidebarVisible,
        }
    }
)(SideMenu);
