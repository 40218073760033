import React, { useState } from "react";
import { Tabs, message } from "antd";
import Aux from "components/hoc/AuxFunction";
import SystemProperties from "./SystemProperties";
import ActionTemplates from "./actionTemplates/ActionTemplates";
import BrandingAndLogo from "./BrandingAndLogo";
import { updateThemeSettings } from "api/branding";
import { createOrUpdateConfig } from "api/formSelection";
import { useDispatch, useSelector } from "react-redux";
import { setTenantBranding } from "redux/actions/tenant";
import SettingsHeader from "pages/Settings/SettingsHeader";
import { createSettings } from "api/systemSettings";
import FormSelection from "./FormSelection";
import { setAppointmentFormsData } from "redux/actions/appointmentForms";

message.config({
  duration: 2,
});

const Settings = () => {
  const { TabPane } = Tabs;
  const [propertyFormVisible, setPropertyFormVisibility] = useState(false);
  const [actionFormVisible, setActionFormVisibility] = useState(false);
  const [isBrandingLogoButtonLoading, setBrandingLogoLoading] = useState(false);
  const [isFirstTimeLanded, setFirstTimeLanded] = useState(true);
  const tenantState = useSelector((state) => state.tenant);
  const brandingState = useSelector((state) => state.branding);
  const sysPropertiesState = useSelector((state) => state.sysProperties);
  const formSelectionState = useSelector((state) => state.appointmentForms);
  const dispatch = useDispatch();

  const onSaveFormSelection = async () => {
    if (formSelectionState?.data) {
      try {
        const response = await createOrUpdateConfig(formSelectionState.data);

        dispatch(setAppointmentFormsData(response.data));
        message.success("Appointment Forms configuration successfully saved.");
      } catch (error) {
        message.error(
          "Error occurred while trying to save the appointment forms configuration, please try again."
        );
      }
    }
  };
  const saveSystemProperties = () => {
    try {
      setPropertyFormVisibility(true);
      (async () => {
        const result = await createSettings(sysPropertiesState.staging);
        if (result.status === 202) {
          message.success("System Properties succesfully saved", 2);
        } else {
          message.error(
            "Error occurred while trying to save the System Properties",
            2
          );
        }
      })();
    } catch (error) {
      message.error(
        "Error occurred while trying to save the System Properties",
        2
      );
    }
  };

  const onSaveBrandingButtonClick = async (brandingGlobalState) => {
    setBrandingLogoLoading(true);
    try {
      if (brandingState && brandingState.list.length > 0) {
        let promises = [];

        brandingState.list.map(async (brandingItem) => {
          const brandingPayload = brandingItem;
          brandingPayload.centraliseBranding = brandingState.centraliseBranding;
          brandingPayload.isPoweredByVisible = brandingState.isPoweredByVisible;

          promises.push(
            updateThemeSettings(brandingItem.tenantUniqueId, brandingPayload)
          );
        });

        await Promise.all(promises)
          .then((responses) => {
            setBrandingLogoLoading(false);

            const response = responses.find(
              (response) =>
                JSON.parse(response.data.data).tenantUniqueId ===
                tenantState.uniqueId
            );

            const responseJson = JSON.parse(response.data.data);

            dispatch(setTenantBranding(responseJson));
            message.success("Tenant Branding succesfully saved", 2);
          })
          .catch((error) => {
            console.log(error);
            setBrandingLogoLoading(false);
            message.error(
              "Error occurred while trying to save the branding",
              2
            );
          });
      }
    } catch (error) {
      setBrandingLogoLoading(false);
      message.error("Error occurred while trying to save the branding", 2);
    }
  };

  const saveActionTemplate = () => {
    setActionFormVisibility(true);
  };
  // TDOO currently unused
  const [activeTab, setActiveTab] = useState(1);

  const changeTab = (key) => {
    console.log(key);
    setActiveTab(Number(key));
    setFirstTimeLanded(false);
  };

  return (
    <Aux>
      <Tabs
        onChange={changeTab}
        tabBarStyle={{ borderBottom: "none" }}
        tabPosition={"left"}
      >
        <TabPane tab="System Properties" key="1">
          <SettingsHeader
            title="System Properties"
            subTitle="Configure System related settings"
            onClick={saveSystemProperties}
            buttonTitle="Save System Properties"
          />
          <SystemProperties />
        </TabPane>
        <TabPane tab="Branding &amp; Logo" key="2">
          <SettingsHeader
            title="Branding and Logo"
            subTitle="Configure System Branding and Logo"
            onClick={onSaveBrandingButtonClick}
            buttonTitle="Save Branding and Logo"
          />
          <BrandingAndLogo />
        </TabPane>
        <TabPane tab="Action Templates" key="3">
          <SettingsHeader
            title="Action Templates"
            subTitle="Configure System Action Templates"
            onClick={saveActionTemplate}
            buttonTitle="Create Template"
            //extraContent={activeButton}
          />
          <ActionTemplates
            formVisible={actionFormVisible}
            setFormVisibility={setActionFormVisibility}
          />
        </TabPane>
        <TabPane tab="Appointment Forms" key="4">
          <SettingsHeader
            title="Appointment Forms"
            subTitle="This section is use to assign forms to a specific appointment type automatically."
            buttonTitle="Save Appointment Forms"
            onClick={onSaveFormSelection}
            //extraContent={activeButton}
          />
          <FormSelection />
        </TabPane>
      </Tabs>
    </Aux>
  );
};

export default Settings;
