import {BASE_API_URL} from "./config";
import {createAxios} from "./axios";

const formSubmissionsApi = createAxios({
    baseURL: BASE_API_URL + '/form-submissions'
})

export const getAppointmentFormSubmissions = (id, params) => {
    return formSubmissionsApi.get(``, {
        params: {
            appointmentId: id,
            ...params
        }
    });
}

export const getAppointmentFormSubmission = id => formSubmissionsApi.get(`/${id}`);

export const updateFormSubmissions = (id, data) =>
    formSubmissionsApi.put(`/${id}`, data, {headers: {'Content-Type': 'application/json'}})

export const addFormToAppointment = (id, data) => {
    return formSubmissionsApi.post(``, {
        appointmentId: id,
        ...data
    })
}
export const downloadFormResponses = (id, startDate, endDate) => {
    return formSubmissionsApi.get(`/responses/download?designId=${id}&startDate=${encodeURI(startDate)}&endDate=${encodeURI(endDate)}`, {
        responseType: "blob"
    })
}

export const deleteFormSubmission = id => {
    return formSubmissionsApi.delete(`/${id}`);
}