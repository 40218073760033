import {alertService} from "../../services/AlertService";
import QuestionService from '../../services/QuestionService'

export function questionsHasErrored(bool) {
    return {
        type: 'QUESTIONS_HAS_ERRORED',
        hasErrored: bool
    };
}

export function questionsIsLoading(bool) {
    return {
        type: 'QUESTIONS_IS_LOADING',
        isLoading: bool
    };
}

export function questionsFetchDataSuccess(questions) {
    return {
        type: 'QUESTIONS_FETCH_DATA_SUCCESS',
        questions
    };
}

export function questionsDeleteDataSuccess(questions) {
    return {
        type: 'QUESTIONS_REMOVE_QUESTION_SUCCESS',
        questions
    };
}

export function questionsRemoveQuestion(id) {
    return (dispatch) => {
        QuestionService.deleteItem(id)
        .then(function(res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(questionsDeleteDataSuccess(id))
            return res;
        }).then(function(res) {
            dispatch(questionsFetchData(0, 0, 5, "", []));
        }).catch(function(res) {
            console.log('OH NO THERE IS AN ERROR', res);
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(questionsHasErrored(true))
        });
    };
}

export function questionsAddQuestion(location) {
    return (dispatch) => {
        QuestionService.addItem(location)
        .then(function(res) {
            dispatch(questionsFetchData(0, 0, 5, "", []));
        }).then(function(res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function(res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(questionsHasErrored(true))
        });
    };
}

export function questionsFetchData(page, start, length, search, sortOrder, sortDirection, appointmentId, patientId) {

    return (dispatch) => {
        dispatch(questionsIsLoading(true));
            QuestionService.getQuestions(page, start, length, search, sortOrder, sortDirection, appointmentId, patientId)
               .then(function(res) {
                dispatch(questionsIsLoading(false))
                return res;
            }).then(function(res) {
                dispatch(questionsFetchDataSuccess(res.data))
            }).catch(function(res) {
                console.log('OH NO THERE IS AN ERROR', res)
                alertService.error(res, {
                    autoClose: true,
                    keepAfterRouteChange: false
                });
                dispatch(questionsHasErrored(true))
            });
        };
}
