import React, { useEffect, useState } from 'react';
import Aux from 'components/hoc/AuxFunction';
import StatusModal from 'components/Appointments/Modals/StatusModal';
import { Alert } from 'components/Layout/Alert';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Badge, DatePicker, Input, Menu, Select, Tooltip, Tag, Button, Space} from 'antd';
import {
  EditOutlined,
  FileAddOutlined,
  FileOutlined,
  FileTextOutlined,
  FileZipOutlined,
  MessageOutlined,
  PaperClipOutlined,
  ContainerTwoTone
} from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import CreateMessageModal from '../Appointment/components/Messages/CreateMessageModal';
import CreateNoteModal from '../Appointment/components/Notes/CreateNoteModal';
import CreateCollateralModal from '../Appointment/components/Collateral/CreateCollateralModal';
import moment from 'moment';
import { getAllLocations } from '../../api/locations';
import CRUDDataTable from '../../components/DataTable/CRUDDataTable';
import CreateVisitorForm from '../Visitors/components/CreateVisitorForm';
import { getAppointmentsDatatable } from '../../api/appointments';
import HospitalSelect from '../../components/location/hospitalSelect';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import { FcFile } from 'react-icons/fc';
import DocumentIcon from 'assets/icons/document-icon';
import MessageIcon from 'assets/icons/message-icon';
import ResourceIcon from 'assets/icons/resource-icon';
import AttachmentIcon from 'assets/icons/attachment-icon';
import PatientIcon from 'assets/icons/patient-icon';
import NurseIcon from 'assets/icons/nurse-icon';
import CreateFormModal from 'pages/Appointment/components/Forms/CreateFormModal';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import InvitePatientModal from "./components/InvitePatientModal";

const AppointmentsList = (props) => {
  const {
    setMessageModalVisibility,
    setCollateralModalVisibility,
    setNoteModalVisibility,
    setActiveAppointmentId,
  } = props;
  const { RangePicker } = DatePicker;
  const [
    isCreateMessageModalVisible,
    setCreateMessageModalVisibility,
  ] = useState(false);
  const [isCreateNoteModalVisible, setCreateNoteModalVisibility] = useState(
    false
  );
  const [
    isCreateCollateralModalVisible,
    setCreateCollateralModalVisibility,
  ] = useState(false);
  const [isStatusModalVisible, setStatusModalVisibility] = useState(false);
  const [draw, setDraw] = useState(0);
  const [customFilters, setCustomFilters] = useState([]);
  const [timeFilters, setTimeFilters] = useState([]);
  const dispatch = useDispatch();

  const [id, setId] = useState(0);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const satisfied = async () => {
    let response = await getAllLocations();
    // two nested data calls because it's returning a datatable
    // console.log(response.data.data)
    setHospitalOptions(response.data.data);
  };
  useEffect(() => {
    satisfied();
  }, []);

  const [isCreateFormModalVisible, setCreateFormModalVisibility] = useState(false);

  const [isInvitePatientModalVisible, setInvitePatientModalVisible] = useState(false);

  const renderMenu = (id, appointmentTitle) => (
    <Menu>
      <Menu.Item>
        <Link
          to={`/appointments/${id}`}
          onClick={() => setActiveAppointmentId(id)}
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setStatusModalVisibility({
              id: id,
              isVisible: true,
            });
          }}
        >
          Change Status
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateMessageModalVisibility(true);
            setId(id);
          }}
        >
          Send Message
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateCollateralModalVisibility(true);
            setId(id);
          }}
        >
          Attach Collateral
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateNoteModalVisibility(true);
            setId(id);
          }}
        >
          Add Note
        </Link>
      </Menu.Item>
    </Menu>
  );

  const notificationTypes = [
    {
      name: 'newForms',
      icon: <DocumentIcon />,
    },
    {
      name: 'newMessages',
      icon: <MessageIcon />,
    },
    {
      name: 'newResources',
      icon: <ResourceIcon />,
    },
    {
      name: 'newAttachments',
      icon: <AttachmentIcon />,
    },
    {
      name: 'incompletePatientTasks',
      icon: <PatientIcon />,
    },
    {
      name: 'incompleteStaffTasks',
      icon: <ListAltTwoToneIcon />,
    },
  ];

  const columns = [
    {
      title: <strong>Patient</strong>,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            <Link
              to={`/appointments/${record?.id}`}
              onClick={() => {
                // setActiveAppointmentId(record?.id)
              }}
            >
              {record?.firstName} {record?.lastName}
            </Link>
          </>
        );
      },
      filter: {
        paramName: 'search',
        label: 'Patient',
        // hidden: true,
        component: {
          name: 'text',
          placeholder: 'Enter patient name',
        },
      },
    },
    {
      title: <strong>Date / Time</strong>,
      dataIndex: 'appointmentDateTime',
      key: 'appointmentDateTime',
      sorter: true,
      render: (text, record, value) => moment(text).format('LLL'),
      filter: {
        paramName: 'date',
        label: 'Date',
        component: {
          name: 'daterange',
        },
        displayFormat: (value) => {
          const dates = value && value.map((date) => date.format('DD-MM-yyyy'));
          return `${dates[0]} to ${dates[1]}`;
        },
        beforeRequest: (value) =>
          value &&
          value
            .map((date) => date.format('yyyy-MM-DD'))
            .reduce((result, date, i) => {
              if (i === 0) result.startDate = date;
              else result.endDate = date;

              return result;
            }, {}),
      },
    },
    {
        title: <strong>Appointment</strong>,
        dataIndex: 'appointmentType',
        key: 'appointmentType',
        sorter: true,
        render: (text, record, value) => {
            return (
                <>
                    <div>{record?.appointmentType}</div>
                    <Tag color={record?.attendanceType === 'CLINIC' ? 'blue' : 'green'}>{record?.attendanceType}</Tag>
                </>
            )
        },
        filter: {
            paramName: 'appointmentType',
            label: 'Appointment Type',
            component: {
                name: 'text',
                placeholder: 'Enter Appointment Type',
            },
        },
    },
    {
      title: <strong>Clinician</strong>,
      dataIndex: 'attendingDoctorName',
      key: 'attendingDoctorName',
      sorter: true,
    },
    {
      title: <strong>Status</strong>,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      filter: {
        paramName: 'status',
        label: 'Status',
        component: {
          name: 'select',
          placeholder: 'Select a status',
          fetchData: () => [
            { value: 'UNCONFIRMED', displayText: 'UNCONFIRMED' },
            { value: 'DECLINED', displayText: 'DECLINED' },
            { value: 'CONFIRMED', displayText: 'CONFIRMED' },
            { value: 'CHECKED_IN_WAITING', displayText: 'CHECKED_IN_WAITING' },
            {
              value: 'CALLED_BACK_TO_QUEUE',
              displayText: 'CALLED_BACK_TO_QUEUE',
            },
            { value: 'CHECKED_IN', displayText: 'CHECKED_IN' },
            { value: 'IN', displayText: 'IN' },
            { value: 'CHECKED_OUT', displayText: 'CHECKED_OUT' },
            { value: 'NO_SHOW', displayText: 'NO_SHOW' },
          ],
        },
      },
    },
      {
          title: <strong>Time Waiting</strong>,
          dataIndex: 'timeWaiting',
          key: 'timeWaiting',
          sorter: true,
          render: (text, record, value) => {
              if (record.status === "CHECKED_IN") {
                  const sampleSecondsElapsed = moment().diff(moment().startOf('h').subtract(Math.random() * (1800 - 0 + 1) + 0, 's'), 's');
                  return `${Math.floor(sampleSecondsElapsed / 60) % 10}:${sampleSecondsElapsed % 60 < 10 ? `0${sampleSecondsElapsed % 60}` : sampleSecondsElapsed % 60 }`
              }
          }
      },
    {
      title: <strong>Options</strong>,
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        return (
          <>
            <ActionButtonsDropdown
              menus={[
                {
                  label: 'Update Status',
                  icon: <EditOutlined />,
                  onClick: () => {
                    setStatusModalVisibility(true);
                    setId(record.id);
                  },
                },
                {
                  label: 'Add Collateral',
                  icon: <PaperClipOutlined />,
                  onClick: () => {
                    setCreateCollateralModalVisibility(true);
                    setId(record.id);
                  },
                },
                {
                  label: 'Send Message',
                  icon: <MessageOutlined />,
                  onClick: () => {
                    setCreateMessageModalVisibility(true);
                    setId(record.id);
                  },
                },
                {
                  label: 'Add Note',
                  icon: <FileTextOutlined />,
                  onClick: () => {
                    setCreateNoteModalVisibility(true);
                    setId(record.id);
                  },
                },
                {
                  label: 'Add Form',
                  icon: <FileOutlined />,
                  onClick: () => {
                    setCreateFormModalVisibility(true);
                    setId(record.id);
                  }
                }
              ]}
            />
          </>
        );
      },
    },
    {
      title: <strong>Actionables</strong>,
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        return (
          <div>
            {notificationTypes.map((type) =>
              record[type.name] ? (
                <Tooltip title={`${record[type.name]} ${type.name.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(' ')}`}>
                <Badge count={record[type.name]}>
                  {React.cloneElement(type.icon, {
                    width: 25,
                    height: 25,
                    className: 'm-1 actionables',
                  })}
                </Badge>
                </Tooltip>
              ) : null
            )}
          </div>
        );
      },
    },
    {
      filter: {
        paramName: 'doctorName',
        label: 'Clinician',
        component: {
          name: 'text',
          placeholder: 'Enter clinician name',
        },
      },
    },
    {
      filter: {
        paramName: 'span',
        label: 'Span',
        defaultValue: 'UPCOMING',
        component: {
          name: 'select',
          placeholder: 'Select span',
          fetchData: () => {
            return [
              { value: 'ALL', displayText: 'ALL' },
              { value: 'PAST', displayText: 'PAST' },
              { value: 'UPCOMING', displayText: 'UPCOMING' },
            ];
          },
        },
      },
    },
  ];

  function onBlur() {
    // console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }

  const fetchAppointments = async(params) => {
    params = {
      ...params,
      sortColumn: 'appointmentDateTime'
    }
    return await getAppointmentsDatatable(params);
  }

  return (
    <Aux>
        <InvitePatientModal visible={isInvitePatientModalVisible} setVisibility={setInvitePatientModalVisible} />
      <StatusModal
        isStatusModalVisible={isStatusModalVisible}
        setStatusModalVisibility={setStatusModalVisibility}
        id={id}
        onSuccess={() => setDraw(draw + 1)}
      />
      <Alert />
      <CreateNoteModal
        visible={isCreateNoteModalVisible}
        setVisibility={setCreateNoteModalVisibility}
        icon={<FileTextOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateMessageModal
        visible={isCreateMessageModalVisible}
        setVisibility={setCreateMessageModalVisibility}
        icon={<MessageOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateCollateralModal
        visible={isCreateCollateralModalVisible}
        setVisibility={setCreateCollateralModalVisibility}
        icon={<PaperClipOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateFormModal
        visible={isCreateFormModalVisible}
        setVisibility={setCreateFormModalVisibility}
        icon={<FileOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <Row>
        <Col md='3'>
          <HospitalSelect
            width={{width: '275px'}}
            direction='horizontal'
            onChange={(value) => {
              const paramName = 'locationId';
              const filters = [...customFilters];
              const hospitalFilter = filters.filter(
                (filter) => filter.paramName === paramName
              )[0];

              if (hospitalFilter) {
                hospitalFilter.value = value;
              } else {
                filters.push({ paramName: paramName, value: value });
              }

              setCustomFilters(filters);
            }}
          />
        </Col>
      </Row>
      <br />
      <CRUDDataTable
        draw={draw}
        title={<div style={{
            display: "flex"
        }}>
            <Space size="large">
            <span
                className="text-primary font-weight-bold">Appointments</span>
            <Button type="primary" onClick={() => setInvitePatientModalVisible(true)}>Invite</Button>
            </Space>
        </div>}
        fetchDataFunction={fetchAppointments}
        continuousFetchInterval={60000}
        customFilters={customFilters}
        createModal={<CreateVisitorForm />}
        columns={columns}
        sortOrder='appointmentDateTime'
        button={<></>}
        highlightRow={(rec, i) => {
          return (
            rec.highlightDeclinedForStaff ||
            rec.newForms > 0 ||
            rec.newMessages > 0 ||
            rec.newAttachments > 0 ||
            rec.incompleteStaffTasks > 0
          );
        }}
      />
    </Aux>
  );
};

export default AppointmentsList;
