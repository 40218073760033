import React, {
  FunctionComponent,
  useEffect,
  useRef,
  MutableRefObject,
  useState,
} from "react";
import { Modal, Typography, Row, Col, message } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import { Form, Input, ResetButton, SubmitButton, Radio } from "formik-antd";
import HospitalSelect from "components/location/hospitalSelect";
import {createBroadcast} from "../../api/broadcasts";

const { Title } = Typography;

interface CreateBroadcastModal {
  visible?: boolean;
  setVisibility?: (visibility: boolean) => void;
  setDraw?: (draw: any) => void;
  draw?: any;
}

const CreateBroadcastModal: FunctionComponent<CreateBroadcastModal> = ({
  visible,
  setVisibility,
    setDraw,
   draw
}) => {
  const formikRef = useRef<any>(null);
  const [location, setLocation] = useState("");
  const [messageValue, setMessageValue] = useState('');

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  // useEffect(() => {
  //     setModalVisibility(visible);
  // }, [visible]);

  function reset() {
    if (formikRef?.current) {
      formikRef?.current.resetForm();
      setMessageValue('');
    }
  }

  function postBroadcast(body:any) {
      body.locationId = location;
      body.type = 'SMS';
      body.message = messageValue;
      createBroadcast(body, null).then(res => {
        console.log('success');
        message.success('Broadcast successfully sent');
        if (setVisibility) {
            setVisibility(false);
        }

        if (setDraw) {
            setDraw(++draw)
        }
      }).catch(err => {
          message.error('Broadcast sending failed');
      });
  }

  return (
    <Modal
      title={
        <Title
          level={4}
          className="mb-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <AudioOutlined className="mr-2" /> New Broadcast
        </Title>
      }
      visible={visible}
      onCancel={() => {
        if (setVisibility) {
          setVisibility(false);
        }
      }}
      footer={null}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ name: "", recipientType: "PATIENTS", locationId: 5}}
        // validationSchema={CreateCollateralSchema}
        onSubmit={(values) => {
            postBroadcast(values);
        }}
        onReset={() => {}}
        render={() => (
          <Form layout="vertical" colon={false}>
                          <Form.Item label="Name" name="name" className="mb-4">
              <Input name="name" />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label="Type" name="type" className="mb-4">
                  <Radio.Group name="type" defaultValue="SMS">
                    <Radio name="" value="SMS">
                      SMS
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Recipients" name="recipientType" className="mb-4">
                  <Radio.Group name="recipientType" defaultValue="PATIENTS">
                    <Radio name="recipientType" value="PATIENTS">
                      Patients
                    </Radio>
                    <Radio name="recipientType" value="STAFF">
                      Staff
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="" name="location" className="mb-4">
              <HospitalSelect removeAllOption={true} onChange={setLocation} />
            </Form.Item>
            <Form.Item label="Message" name="message" className="mb-20">
              <textarea
                // autoSize={{
                //   minRows: 5,
                //   maxRows: 7,
                // }}
                style={{width: '100%'}}
                maxLength={160}
                onChange={e => setMessageValue(e.target.value)}
              />
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton>Send</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateBroadcastModal;
