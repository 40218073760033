import axios from 'axios';
import BaseService from "./BaseService";

const QUESTION_API_BASE_URL = "/api/questions";

class QuestionService extends BaseService {

    constructor() {
        super(QUESTION_API_BASE_URL);
    }

    getQuestions(page, start, length, search, sortOrder, sortDirection, appointmentId, patientId){
        return axios.get(QUESTION_API_BASE_URL + '/question', {
            timeout: 5000,
            params: {
                page: page,
                draw: 1,
                start: start,
                sortOrder: sortOrder,
                sortDirection: sortDirection,
                length: length,
                search: search,
                appointmentId: appointmentId,
                patientId: patientId
            }
        });
    }
}

export default new QuestionService()