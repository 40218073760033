import React from "react";

import './MainLayout.scss';
import Navigation from "./Navigation"
import PageWrapper from "./PageWrapper"

import Aux from '../hoc/AuxFunction';

const MainLayout = (props) => {
    const { children } = props;
    return (
        <Aux>
            <Navigation fullName={props.fullName}/>
            <PageWrapper page={children} />
        </Aux>
    );
};

export default MainLayout;
