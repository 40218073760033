import React, { useRef, useState, useEffect } from "react";
import { Typography, Modal, Upload, Button, Row, Col, message} from "antd";
import { Formik } from "formik";
import { Form, Input, ResetButton, SubmitButton } from "formik-antd";
import * as yup from "yup";
import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { createCollateral, updateCollateral} from "api/collaterals";
import {Link} from "react-router-dom";

const { Title, Text } = Typography;
const CreateCollateralSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Too short.")
    .max(30, "Too long.")
    .required("Please enter a name."),
  // description: yup
  //   .string()
  //   .min(2, "Too short.")
  //   .max(255, "Too long.")
  //   .required("Please enter a description."),
    url: yup
        .string()
});

const CreateCollateralForm = ({ title, visible, collateral, setVisibility, onSuccess }) => {
  const [files, setFiles] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState('');
  const formikRef = useRef(null);
  const initialValues = { 
    name: collateral ? collateral.name : '', 
    url: collateral ? collateral.url : '' 
  }

  useEffect(() => {
    if (collateral && collateral.description) {
      setDescriptionValue(collateral.description);
    }
  }, [collateral]);

  function onRemoveFile(file) {
    const index = files.indexOf(file);
    const newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  console.log('collateral', collateral)

  async function create(values) {
    try {
        message.loading('Uploading resource...', 0);
        const form = new FormData();

        // @TODO: Remove in production, or maybe change it later on
        form.append("locationId", 0);
        form.append('description', descriptionValue);
        Object.keys(values).forEach(key => form.append(key, values[key]));
        files.forEach(file => form.append("file", file, file.name));
        const res = !collateral ? await createCollateral(form) : await updateCollateral(collateral.id, form);  
        message.destroy();
        message.success('Resource successfully uploaded.');
        onSuccess();
        setVisibility(false)
    } catch (error) {
        message.destroy();
        message.error(error.response.data.message);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}><PaperClipOutlined className="mr-2"/>{title}</Title>}
      visible={visible}
      onCancel={() => setVisibility(false)}
      destroyOnClose
      footer={null}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={CreateCollateralSchema}
        onSubmit={values => create(values)}
        onReset={() => setFiles([])}
        render={() => (
          <Form layout="vertical" colon={false}>
            <Form.Item label="Name" name="name" className="mb-4">
              <Input name="name" />
            </Form.Item>
            <label>Description</label>
            <textarea
                style={{width: '100%'}}
                value={descriptionValue}
                onChange={e => setDescriptionValue(e.target.value)}
            />
            {/* <Form.Item label="Description" name="description" className="mb-4">
              <Input.TextArea
                name="description"
                autoSize={{ minRows: 3, maxRows: 10 }}
              />
            </Form.Item> */}
              <Form.Item label="Url" name="url" className="mb-4">
                  <Input name="url" />
              </Form.Item>
            <Row className="mb-4">
              <Col>
              <Upload
              customRequest={() => {
                return;
              }}
              beforeUpload={(file) => {
                setFiles([file]);
                return false;
              }}
              onRemove={onRemoveFile}
              className="mb-4"
              fileList={files}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
              { collateral?.file && !files?.length &&
               <Link onClick={() => window.open(collateral.fileURL)}>{collateral.file.originalFilename}</Link>
              }
              </Col>
            </Row>
            <Row gutter={4} className="d-flex justify-content-end">
                <Col>
                    <ResetButton>Reset</ResetButton>
                </Col>
                <Col>
                    <SubmitButton>{ !collateral ? 'Create' : 'Update' }</SubmitButton>
                </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateCollateralForm;
