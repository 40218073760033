import { Modal, Typography, Button, Space } from 'antd';
import { getMessage } from 'api/messages';
import React from 'react';
import { useQuery } from 'react-query';
import titleize from 'titleize';

const { Title } = Typography;

const ViewMessageModal = (props) => {

  const {isLoading, error, data} = useQuery(["getMessage", props.messageId], async () => {
      const res = await getMessage(props.messageId);
      props.setDraw(props.draw + 1); // to refresh the table, marking the message as read
      return res.data
  }, {
    enabled: props.messageId
  })

  return (
    <Modal
      title={
        <Title
          level={4}
          className="mb-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          {props.icon}
          {data?.replyingToMessage && 'Re: '}
          {data ? titleize(data.subject) : ""}
        </Title>
      }
      visible={props.visible}
      onCancel={() => {
        props.setVisibility(undefined);
      }}
      footer={null}
      destroyOnClose={true}
    >
      {isLoading ? <div className="flex items-center justify-center"></div> : data?.message}
      <div className="mt-2 text-right">
        <Space>
          <Button onClick={() => props.onReply()}>Reply</Button>
          <Button danger onClick={() => props.onDelete()}>Delete</Button>
        </Space>
      </div>
    </Modal>
  );
};

export default ViewMessageModal;
