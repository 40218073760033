import React from "react";
import Aux from "../../components/hoc/AuxFunction";
import DataTable from "./DocumentDataTable";
import DocumentModalForm from "./DocumentModalForm";
import DocumentActionMenu from "./DocumentActionMenu";
import {Alert} from "../../components/Layout/Alert";

/*

 */
const Documents = (props) => {

    const cols = [

        {
            name: "description",
            label: "Description",
            options: {
                filter: false
            }
        },
        // hidden columns
        {
            name: "id",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "appointmentId",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "patientId",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return <DocumentActionMenu
                        mode={props.mode}
                        description={tableMeta.rowData[0]}
                        id={tableMeta.rowData[1]}
                        appointmentId={tableMeta.rowData[2]}
                        patientId={tableMeta.rowData[3]}
                    />
                }
            },
        },
    ];
    return (
        <Aux>
            <Alert/>
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="page-header">Documents</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className="col-xs-12 col-md-3 col-lg-2 pull-right w-o-gutter">
                                <DocumentModalForm buttonText="New Document" appointmentId={props.appointmentId} patientId={props.patientId} mode={props.mode} actionMenuItem="False"/>
                            </div>
                            <div className="clearfix"/>
                        </div>
                        <div className="panel-body">
                            <DataTable appointmentId={props.appointmentId} patientId={props.patientId} columns={cols} heading="Documents"/>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
};

export default Documents;
