import { connect } from 'react-redux'
import { documentsFetchData } from "redux/actions/documents";
import DataTable from "../../components/Layout/DataTable";
import React from "react";

const mapStateToProps4 = (state) => {
    return {
        documents: state.documents,
        hasErrored: state.documentsHasErrored,
        isLoading: state.documentsIsLoading
    };
};

const mapDispatchToProps4 = (dispatch, props) => {
    return {
        fetchData: (page, start, length, search, sortOrder, sortDirection) => dispatch(documentsFetchData(page, start, length, search, sortOrder, sortDirection, props.appointmentId, props.patientId))
    };
}

class DocumentDataTable extends DataTable {

    render(props) {
        return super.render(props, this.props.documents.data, this.props.documents.recordsTotal);
    }
}

export default connect(mapStateToProps4, mapDispatchToProps4)(DocumentDataTable);