import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ColorPaletteItems from 'components/BrandingSettings/ColorPaletteItems';

export default ({ branding }) => {
    let currentState = branding;

    const [currentPalette, setCurrentPalette] = useState(currentState.theme.palette);

    useEffect(() => {
        setCurrentPalette(currentState.theme.palette);
    }, [currentState.theme.palette]);

    return  <>
                <h3 className='font-weight-bolder Branding-Logo-Settings-Header'>Colors</h3>
                <Row>
                    <Col
                        className='text-center'
                        style={{ display: 'flex' }}
                    >
                        <ColorPaletteItems
                            currentBranding={currentState}
                            palettes={currentPalette} 
                        />
                    </Col>
                </Row>
            </>;
}