import React, {useEffect, useState} from 'react';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import {Redirect, Switch} from 'react-router';
import KeycloakLandingPage from 'pages/Landing/KeycloakLandingPage';
import MainLayout from 'components/Layout/MainLayout';
import {AuthContext} from 'hooks';
import {BrowserRouter, Route} from 'react-router-dom';
import {keycloak} from 'api/keycloak';
import routes from 'routes/index';
import {AuthUtils} from 'utils/AuthUtil';

const KeycloakAuthPage = () => {
    const landingUri = '/';
    const baseURI = ''

    const [sessionState, setSessionState] = useState({
        sessionDetails: [
            {fullName: 'Hisham Alabri'}],
        otherState: 'not sure what this is'
    });

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const defaultUri = routes(false)[0]?.path;

    useEffect(() => {
        console.log('trying to initialize keycloak');
        AuthUtils.keycloak()
            .then((auth) => {
                console.log('in keycloak init ', auth);
                setIsAuthenticated(auth);
            }).catch((e) => {
                console.error('Authentication Failed');
                console.error(e);
            });
    }, []);
    return <AuthContext.Provider value={isAuthenticated}>
        <BrowserRouter basename=''>
            <Switch>
                <Route path='/console/meeting/:meetingNumber/:meetingPassword/:appointmentId'
                       component={(props) => {

                           console.log('starting a meeting with', props.match.params.meetingNumber);
                           window.location.href = 'http://localhost:3001/meeting/'
                               + props.match.params.meetingNumber + '/'
                               + props.match.params.meetingPassword + '/'
                               + props.match.params.appointmentId;
                           return null;
                       }}/>

                <AuthenticatedRoute
                    exact path={landingUri}
                    onAuthenticated={
                        () => {
                            console.log(`on Authenticated route landing: redirect to ${defaultUri}`);
                            return <Redirect to={defaultUri}/>;
                        }
                    }
                    onUnauthenticated={
                        () => {
                            console.log('on Unauthenticated route Landing: render Landing');
                            return <KeycloakLandingPage keycloak={keycloak}/>;
                        }
                    }
                />
                <AuthenticatedRoute
                    onAuthenticated={
                        () => {
                            console.log('on Authenticated route main: render Main');
                            return <MainLayout fullName={sessionState.sessionDetails[0].fullName}/>;
                        }
                    }
                    onUnauthenticated={
                        () => {
                            console.log('on Unauthenticated route main: redirect to Landing.')
                            return <Redirect to={landingUri}/>;
                        }
                    }
                />
            </Switch>
        </BrowserRouter>
    </AuthContext.Provider>
}

export default KeycloakAuthPage;