export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const FORBIDDEN_URL = BASE_URL + 'forbidden';
export const KEYCLOAK_AUTH_URL = process.env.REACT_APP_KEYCLOAK_AUTH_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const msalConfig = {
    //Five Faces Concierge app
    appId: 'b7288c67-18a2-4053-b28a-ba879b09190e',
    tenantId: 'db22c75a-5289-4193-9327-70732230ff28',
    //SLHD app
    // appId: '38fdf9d8-118a-4d39-b428-a4ba3f45a635',
    // tenantId: 'a687a7bf-02db-43df-bcbb-e7a8bda611a2',
    // Paulo C app
    // appId: '0daf856c-3a7f-40b6-a20c-70042121382c',
    // tenantId: '2395fb50-7c92-4be9-8e14-1e7b82a73b45',
    scopes: ['user.read'],
}
