import axios from 'axios';

const FILE_UPLOAD_API_BASE_URL = "/api/files";

class UploadFilesService {
    upload(file, appointmentId, patientId, onUploadProgress) {
        let formData = new FormData();

        console.log('appointmerhskj id is', appointmentId);
        formData.append("file", file);

        if (undefined !== appointmentId) {
            formData.append("appointmentId", appointmentId);
        }

        if (undefined !== patientId) {
            formData.append("patientId", patientId);
        }

        return axios.post(FILE_UPLOAD_API_BASE_URL + "/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    getFiles() {
        return axios.get("/files");
    }
}

export default new UploadFilesService();