import React, {useRef, useEffect, useState} from "react";
import {Typography, Modal, Row, Col, message} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton, Select} from "formik-antd";
import * as yup from "yup";
import titleize from 'titleize';
import {getForms} from "api/forms";
import {addFormToAppointment} from "api/formsSubmissions";
import { useDispatch } from 'react-redux';
import { setWillTaskListRefresh } from 'redux/actions/appointment';

const {Title} = Typography;
const {Option} = Select;

const entity = `form`;
const initialValues = {
  designUniqueId: "",
};

const schema = yup.object().shape({
  designUniqueId: yup.string().required("Please choose a form.")
});

const CreateFormModal = ({visible, setVisibility, icon, appointmentId, onSuccess}) => {
  const [forms, setForms] = useState([]);
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  async function readyForms() {
    try {
      const res = await getForms({
          start: 0,
          draw: 1,
          page: 0,
          length: 10
      });
      setForms(res.data.data);
    } catch (error) {
      message.error(error.toString());
    }
  }

  useEffect(() => {
    readyForms();
  }, [])

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  async function create(values) {
    try {
      let data = {
        designUniqueId: values.designUniqueId
      }
      message.loading(`Creating ${entity}...`, 0);
      const res = await addFormToAppointment(appointmentId, data);
      message.destroy();
      onSuccess();
      message.success(`${titleize('Form')} successfully linked.`);
      setVisibility(false)
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0"
                    style={{display: "flex", alignItems: "center"}}>{icon}{`New ${titleize(entity)}`}</Title>}
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values => {
          create(values);
          dispatch(setWillTaskListRefresh(true));
        }}
        render={() => (
          <Form layout="vertical" colon={false}>
            <Form.Item label="Form" name="designUniqueId" className="mb-4">
              <Select name="designUniqueId" defaultValue="" style={{width: "100%"}}>
                {forms.filter(form => form.status === 'LIVE').map(form => <Option value={form.designUniqueId}>{form.name}</Option>)}
              </Select>
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton>Add</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateFormModal;
