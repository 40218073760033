import React, {useState} from "react";
import CreateVisitorForm from "../Visitors/components/CreateVisitorForm";
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";
import ActionButtonsDropdown from "../../components/ActionButtonsDropdown";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import {Button} from "antd";
import CreateNotificationsTemplateModal from "./CreateNotificationsTemplateModal";

const NotificationsTemplates = () => {

    const [draw, setDraw] = useState<number>(1);
    const columns = [
        {
            title: <strong>Name</strong>,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: <strong>Email Subject</strong>,
            dataIndex: 'emailSubject',
            key: 'emailSubject',
            sorter: true
        },
        {
            title: <strong>Email Body</strong>,
            dataIndex: 'emailBody',
            key: 'emailBody',
            sorter: true
        },
        {
            title: <strong>SMS Body</strong>,
            dataIndex: 'smsBody',
            key: 'smsBody',
            sorter: true
        },
        {
            title: <strong>Actions</strong>,
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            render: (text: any, record: any, value: any) => (
                <ActionButtonsDropdown
                    menus={
                        [
                            {
                                label: 'Edit Template',
                                icon: <EditOutlined />,
                                onClick: () => {}
                            },
                            {
                                label: 'Delete Template',
                                icon: <DeleteOutlined />,
                                onClick: () => {}
                            }
                        ]
                    }
                />
            ),
        }
    ]

    return <CRUDDataTable
        draw={draw}
        entity='Notifications Templates'
        fetchDataFunction={async (params: any) => {
            return await {
                data: {
                    data: [
                            {
                                name: "Invitation - SOP - Pfizer",
                                emailSubject: "Invitation to register for your COVID-19 vaccination",
                                emailBody: <><p>To confirm your eligibility please pre-register your details by following this link: <a href="https://bit.ly/mycovaxvc2">https://bit.ly/mycovaxvc2</a></p>

                                    <p>If you have any questions, please call our free COVID-19 Vaccination Program hotline on 1800 57 11 55 between 8am – 9.30pm, 7 days a week.</p>

                                    <p>For more information on the COVID-19 Vaccination Program go to the <a href="https://www.health.nsw.gov.au/infectious/covid-19/vaccine/Pages/default.aspx "> CNSW Health website</a>. </p>

                                    <p>Thank you for your help to keep you, your loved ones and our community safe from COVID-19.</p><p>Sincerely,</p><p>NSW Health</p></>,
                                smsBody: `You are invited to pre-register for the free COVID-19 vaccination at: https://bit.ly/mycovaxvc2 If you have questions, please call our COVID-19 Vaccination Program hotline on 1800 57 11 55 between 8am - 9:30pm, 7 days a week`,
                            }
                    ]
                }}
            }}
        continuousFetchInterval={60000}
        createModal={<CreateNotificationsTemplateModal />}
        columns={columns}
        sortOrder='name'
        button={<Button type='primary'>New Notification Template</Button>}

    />
}

export default NotificationsTemplates;