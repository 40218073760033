import React, { useEffect, useState } from "react";
import { Button, Card, Space, Table } from "antd";
import Aux from "components/hoc/AuxFunction";
import { deleteTemplates, getAll } from "../../../api/actionTemplates";
import { getAllOptions } from "../../../api/actionTemplatesOptions";
import CreateActionTemplateForm from "./CreateActionTemplateForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import CRUDDataTable from "../../../components/DataTable/CRUDDataTable";
import { Link } from "react-router-dom";

const ActionTemplates = ({ formVisible, setFormVisibility }) => {
  const [actionTemplates, setActionTemplates] = useState([]);
  const [actionOptionTemplates, setActionOptionTemplates] = useState([]);
  const [id, setId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [templateId, setTemplateId] = useState();

  const cols = [
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, value) => {
        return (
          <Link
            onClick={() => {
              setTemplateId(record?.id);
              setFormVisibility(true);
            }}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: <strong>Options</strong>,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, value) => {
        return (
          <Space>
            {/*<Button>Edit</Button>*/}
            <Button
              onClick={() => {
                setId(record.id);
                setDeleteVisible(true);
              }}
              danger
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  function fetchData() {
    let params = {
      length: 10,
      page: 0,
      sortColumn: "name",
      sortDirection: "ASC",
    };
    getAll(params).then((res) => {
      setActionTemplates(res.data);
    });
    getAllOptions(params).then((res) => {
      setActionOptionTemplates(res.data);
    });
  }

  const [draw, setDraw] = useState(0);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Aux>
      <CreateActionTemplateForm
        visible={formVisible}
        setVisible={setFormVisibility}
        onSuccess={fetchData}
        templateId={templateId}
        actionOptionTemplates={actionOptionTemplates}
      />
      <DeleteModal
        visible={deleteVisible}
        setVisibility={setDeleteVisible}
        deleteFunction={deleteTemplates}
        onSuccess={() => setDraw(draw + 1)}
        entityId={id}
        entity="Action Template"
      />
      <Card style={{ marginBottom: "10px" }}>
        {/*<Table columns={cols} dataSource={actionTemplates}/>*/}
        <CRUDDataTable
          draw={draw}
          entity="Action Template"
          fetchDataFunction={async (params) => {
            return await getAll(params);
          }}
          createModal={<></>}
          button={<></>}
          columns={cols}
        />
      </Card>
    </Aux>
  );
};

export default ActionTemplates;
