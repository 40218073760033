import React from 'react';
import {FaTrash} from "react-icons/fa";
import {IconContext} from "react-icons";
import Modal from '@material-ui/core/Modal';
import {Formik} from "formik";
import Constants from "../../components/Layout/ModalConstants";
import Delete from "../../components/Layout/Delete";
import {connect} from 'react-redux';
import { documentsRemoveDocument } from "redux/actions/documents";

const DocumentModalDelete = (props) => {

    const classes = Constants.useStyles();
    const [modalStyle] = React.useState(Constants.getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseEvent = () => {
        setOpen(false);
    };

    return (
        <IconContext.Provider value={{style: {fontSize: '26px', paddingRight: '10px'}}}>
            <button type="button" className={props.classes} onClick={handleOpen}>
                <FaTrash/> {props.buttonText}
            </button>
            <Modal open={open} onClose={handleCloseEvent} aria-labelledby="Documents" aria-describedby="Documents"
            >
                <div style={modalStyle} className={classes.paper}>
                    <Formik
                        initialValues={{id: props.id}}
                        onSubmit={(values, {setSubmitting}) => {
                            props.removeDocument(values.id);
                            handleCloseEvent();
                        }
                        }
                    >
                        <Delete name={props.description} onClose={handleCloseEvent} />
                    </Formik>
                </div>
            </Modal>
        </IconContext.Provider>
    );
};

const mapStateToProps1 = (state) => {
    return {
        documents: state.documents,
        hasErrored: state.documentsHasErrored,
        isLoading: state.documentsIsLoading
    };
};
const mapDispatchToProps1 = (dispatch) => {
    return {
        removeDocument: (id) => dispatch(documentsRemoveDocument(id))
    };
}


export default connect(mapStateToProps1, mapDispatchToProps1)(DocumentModalDelete);
