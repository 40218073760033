import Tasks from 'pages/tasks/Tasks';
import { PermissionsAction, PermissionsState, PERMISSIONS_ACTIONS } from './../types/permissions-types/index';

  const initialState: PermissionsState = {
        permissions: {
          Dashboard: [],
          Appointments: [
            "appointment:telehealth",
            "appointment:tasks",
            "appointment:forms",
            "appointment:collateral",
            "appointment:messages"
          ],
          Tasks: [],
          Resources: [],
          Users: [],
          Locations: [],
          Forms: [],
          Broadcasts: [],
          Settings: []
        }
  };
  
  export default function permissionsReducer(
    state = initialState,
    action: PermissionsAction
  ): PermissionsState {
    const { type, payload } = action;
    switch (type) {
      case PERMISSIONS_ACTIONS.SET_PERMISSIONS:
        return {
          ...state,
          ...payload,
        };
      default:
        return { ...state };
    }
  }
  