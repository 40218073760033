import {Button, Col, Divider, Modal, Row} from "antd";
import { Formik } from "formik";
import { Form, Input, ResetButton, SubmitButton } from "formik-antd";
import React, { useEffect, useRef, useState } from "react";
import RuleCreation from "./RuleCreation";
import TaskCreation from "./TaskCreation";
import { createTemplate, getTemplate } from "../../../api/actionTemplates";
import ScheduleCreation from "./ScheduleCreation";

const CreateActionTemplateForm = ({
  visible,
  setVisible,
  onSuccess,
  templateId,
  actionOptionTemplates,
}) => {
  const formikRef = useRef(null);
  const [rules, setRules] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [name, setName] = useState("");
  console.log(actionOptionTemplates);
  useEffect(() => {
    console.log(templateId);
    if (templateId !== undefined) {
      getTemplate(templateId).then((res) => {
        let template = res.data;
        console.log(template);
        setRules(template.rules);
        setTasks(template.tasks);
        setName(template.name);
      });
    }
  }, [templateId]);

  const addRequiredValue = (value, index) => {
    let newArr = [...rules];
    newArr[index].requiredValue = value;
    setRules(newArr);
  };

  const addCheckedRule = (rule, index) => {
    let newArr = [...rules];
    newArr[index].checkedValue = rule;
    setRules(newArr);
  };

  const addTaskType = (type, index) => {
    let newArr = [...tasks];
    newArr[index].taskType = type;
    setTasks(newArr);
  };

  const addTaskValue = (value, index) => {
    let newArr = [...tasks];
    newArr[index].value = value;
    setTasks(newArr);
  };

  const changeCheckbox = (check, index) => {
    let newArr = [...tasks];
    newArr[index].createPatientTask = check;
    setTasks(newArr);
  };

  function reset() {
    setRules([]);
    setTasks([]);
    formikRef.current.resetForm();
  }

  return (
    <Modal
      title={<strong>Create Action Template</strong>}
      visible={visible}
      onCancel={() => {
        setVisible(false);
        reset();
      }}
      footer={null}
      // width={'100%'}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ name: "" }}
        onSubmit={(val) => {
          /*
          re-assigning to new request object to remove
          unnecessary data retrieved by formik-antd library
          */
          let request = {
            name: val.name,
            rules,
            tasks,
          };
          console.log(request);
          createTemplate(request).then((res) => {
            // console.log(res)
            onSuccess();
          });
          setVisible(false);
        }}
        render={() => (
          <Form layout="vertical">
            <Form.Item name="name" label="Name">
              <Input
                name="name"
                onChange={(value) => {
                  // location.uniqueId = value.target.value
                }}
              />
            </Form.Item>

            <Divider orientation="left">Rules</Divider>
            <Button
              onClick={() => {
                setRules(rules.concat({ checkedValue: "", requiredValue: "" }));
              }}
            >
              Add Rule
            </Button>
            <br />
            <br />
            {rules.map((val, index) => {
              console.log(val);
              return (
                <RuleCreation
                  value={val}
                  addRule={addCheckedRule}
                  addValue={addRequiredValue}
                  index={index}
                  actionOptionTemplates={actionOptionTemplates}
                />
              );
            })}

            <Divider orientation="left">Task</Divider>
            <Button
              onClick={() => {
                setTasks(
                  tasks.concat({
                    createPatientTask: false,
                    taskType: "",
                    value: "",
                  })
                );
              }}
            >
              Add Task
            </Button>
            <br />
            <br />
            {tasks.map((val, index) => {
              return (
                <TaskCreation
                  value={val}
                  addType={addTaskType}
                  addValue={addTaskValue}
                  updateCheckBox={changeCheckbox}
                  index={index}
                  actionOptionTemplates={actionOptionTemplates}
                />
              );
            })}
            <Divider orientation="left">Scheduling</Divider>
              <ScheduleCreation />
            <footer>
                <Row gutter={4} className="d-flex justify-content-end">
                    <Col>
                        <ResetButton>Reset</ResetButton>
                    </Col>
                    <Col>
                        <SubmitButton>Create</SubmitButton>
                    </Col>
                </Row>
            </footer>
          </Form>
        )}
      />
    </Modal>
  );
};
export default CreateActionTemplateForm;
