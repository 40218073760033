import React, { useEffect, useState } from 'react';
import CardView from 'components/Layout/CardView';
import Selector from 'pages/Settings/FormSelection/Selector';
import List from './List';
import { Button, message, Space } from 'antd';
import { setAppointmentFormsData } from 'redux/actions/appointmentForms';
import { fetchAllFormSelection } from 'api/formSelection';
import { fetchAllAvailableCodes } from 'api/formSelectionAptCode';
import { useSelector, useDispatch } from 'react-redux';

message.config({
    duration: 2
});

const FormSelection = () => {
    const [appointmentTypeList, setAppointmentTypeList] = useState([]);
    const [appointmentFormData, setAppointmentFormData] = useState([]);
    const [listData, setListData] = useState([]);
    const appointmentFormState = useSelector(state => state.appointmentForms);
    const dispatch = useDispatch();

    useEffect(() => {
        if (appointmentFormState?.data) {
            setAppointmentFormData(appointmentFormState.data);
        }
    }, [appointmentFormState]);

    useEffect(() => {
        (async () => {
            try {
                let formSelectionlist = [];
                const appointmentTypesResponse = await fetchAllAvailableCodes();
                const formSelectionResponse = await fetchAllFormSelection();

                if (formSelectionResponse?.data) {
                    const responseData = formSelectionResponse.data;
                    responseData.map((appointmentForm) => {
                        const { 
                            uniqueId, 
                            appointmentType, 
                            appointmentCodeUniqueId, 
                            forms 
                        } = appointmentForm;

                        formSelectionlist.push({
                            key: appointmentCodeUniqueId,
                            uniqueId,
                            appointmentType,
                            appointmentCodeUniqueId,
                            forms
                        });
                    });

                    dispatch(setAppointmentFormsData(formSelectionlist));
                    setListData(formSelectionlist);
                }

                if (appointmentTypesResponse?.data) {
                    let appointmentTypeList = appointmentTypesResponse.data;
                    
                    if (formSelectionlist
                        && Array.isArray(formSelectionlist)
                        && formSelectionlist.length > 0) {
                            appointmentTypeList = [];

                            appointmentTypesResponse.data.filter(appointmentType => {
                                if (!formSelectionlist.find(formSelection => (formSelection.appointmentCodeUniqueId === appointmentType.appointmentCodeUniqueId))) {
                                    appointmentTypeList.push(appointmentType);
                                }
                            });
                    }

                    setAppointmentTypeList(appointmentTypeList);
                }
            } catch (error) {
                message.error('Error occurred while fetching the appointments, please refresh the page.');
            }
        })();
    }, []);

    const columns = [
        { 
            title: 'Appointment Type', 
            dataIndex: 'appointmentType', 
            key: 'uniqueId',
            render: (record, row) => {
                return <h4 key={row.uniqueId} className='font-weight-bolder'>{row.appointmentType}</h4>;
            }
        },
        {
            key: 'uniqueId',
            title: 'Action',
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (record, row) => (
                <div key={row.uniqueId} className='text-right'>
                    <Button
                        danger
                        onClick={() => {
                            const newListData = [...listData];
                            if (newListData && Array.isArray(newListData)) {
                                const filteredAppointments = newListData.filter((appointment) => appointment.appointmentCodeUniqueId !== row.appointmentCodeUniqueId);
                                (async() => {
                                    await setListData(filteredAppointments);
                                    await dispatch(setAppointmentFormsData(filteredAppointments));
                                    let newAppointmentTypeList = [...appointmentTypeList];

                                    newAppointmentTypeList = [
                                        ...newAppointmentTypeList,
                                        row
                                    ];
                                    setAppointmentTypeList(newAppointmentTypeList);
                                })();
                            }
                        }}
                    >
                        Remove
                    </Button>
                </div>
            )
        },
    ];
    
    return (
        <CardView className='pb-5'>
            <Space
                direction={'vertical'}
                size={'middle'}
                style={{
                    width: '100%'
                }}
            >
                <Selector
                    label={'Appointment Type'}
                    placeholder={'Select Appointment Type'}
                    valueKey={null}
                    titleKey={'appointmentType'}
                    dataList={appointmentTypeList}
                    selectClearThreshold={0}
                    onSelectorChange={(value) => {
                        const newAppointmentTypeList = [...appointmentTypeList];
                        let latestAppointmentTypeList = [];

                        const jsonData = JSON.parse(value);
                        let newAppointmentFormData = [...appointmentFormData];
                        
                        if (newAppointmentFormData) {
                            const newFormData = {
                                appointmentCodeUniqueId: jsonData.appointmentCodeUniqueId,
                                appointmentType: jsonData.appointmentType,
                                forms: [],
                                key: jsonData.appointmentCodeUniqueId,
                                uniqueId: jsonData.uniqueId
                            };

                            const latestAppointmentFormData = [
                                newFormData,
                                ...newAppointmentFormData
                            ];

                            latestAppointmentTypeList = newAppointmentTypeList.filter(appointmentType => {
                                return appointmentType.appointmentCodeUniqueId !== jsonData.appointmentCodeUniqueId
                            });

                            dispatch(setAppointmentFormsData(latestAppointmentFormData));
                            setListData(latestAppointmentFormData);
                            setAppointmentTypeList(latestAppointmentTypeList);
                        }
                    }}
                    onSelectClear
                />
                <List
                    columns={columns}
                    listData={listData}
                />
            </Space>
        </CardView>
    )
};

export default FormSelection;
