import React, { useEffect, useState } from 'react';
import { Button, Card, Empty, message, Space } from 'antd';
import { getForms } from 'api/forms';
import { FcDocument } from 'react-icons/fc';
import { BsTrash } from 'react-icons/bs';
import Selector from './Selector';
import { useSelector, useDispatch } from 'react-redux';
import { setAppointmentFormsData } from 'redux/actions/appointmentForms';

const gridStyle = {
    width: '25%',
    minHeight: '150px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
};

const FormItems = ({
    appointmentCodeUniqueId,
    data
}) => {
    const [formDesignList, setFormDesignList] = useState([]);
    const [formListData, setFormListData] = useState([]);
    const appointmentFormState = useSelector(state => state.appointmentForms);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            setFormListData(data);
        }
    }, [data]);

    useEffect(() => {
        if (appointmentFormState.data) {
            const currentAppointmentForm = appointmentFormState.data.find(appointmentFormData => appointmentFormData.appointmentCodeUniqueId === appointmentCodeUniqueId);
            setFormListData(currentAppointmentForm.forms);
        }
    }, [appointmentFormState.data]);

    useEffect(() => {
        (async () => {
            try {
                const response = await getForms();
                const responseData = response?.data?.data;
                
                if (responseData && Array.isArray(responseData)) {
                    const liveForms = responseData.filter(form => {
                        return form.status === 'LIVE'
                    });
                    setFormDesignList(liveForms);
                }
            } catch (err) {
                message.error('Error occurred while getting the forms. Please refresh the page', 2000);
            }
        })();
    }, []);

    const onAppointmentFormsAdd = (value) => {
        const jsonValue = JSON.parse(value);

        if (jsonValue) {
            let appointmentFormStateData = [...appointmentFormState.data];

            let currentAppointmentForm = appointmentFormStateData.find(appointmentForm => appointmentForm.appointmentCodeUniqueId === appointmentCodeUniqueId);
            
            let currentAppointmentFormsList = [...currentAppointmentForm.forms];

            const { designUniqueId, name, status } = jsonValue;

            const newFormItem = {
                formDesignUniqueId: designUniqueId,
                formDesignTitle: name,
                formDesignStatus: status
            };

            if (appointmentFormState?.data) {
                if (!currentAppointmentFormsList.find(currentAppointmentForm => currentAppointmentForm.formDesignUniqueId === designUniqueId)) {
                    currentAppointmentFormsList.push(newFormItem);

                    const newAppointmentFormStateData = appointmentFormStateData.map(appointmentFormState => {
                        if (appointmentFormState.appointmentCodeUniqueId === appointmentCodeUniqueId) {
                            appointmentFormState.forms = currentAppointmentFormsList;
                        }

                        return appointmentFormState;
                    });

                    dispatch(setAppointmentFormsData(newAppointmentFormStateData));
                }
            }
        }
    }

    const onFormDelete = (designUniqueId) => {
        let appointmentFormStateData = [...appointmentFormState.data];

        let currentAppointmentForm = appointmentFormStateData.find(appointmentForm => appointmentForm.appointmentCodeUniqueId === appointmentCodeUniqueId);
        let currentAppointmentFormsList = [...currentAppointmentForm.forms];

        if (appointmentFormState?.data) {
            let newCurrentAppointmentFormList = currentAppointmentFormsList.filter(appointmentForm => {
                return appointmentForm.formDesignUniqueId !== designUniqueId
            });

            const newAppointmentFormStateData = appointmentFormStateData.map(appointmentFormState => {
                if (appointmentFormState.appointmentCodeUniqueId === appointmentCodeUniqueId) {
                    appointmentFormState.forms = newCurrentAppointmentFormList;
                }

                return appointmentFormState;
            });

            dispatch(setAppointmentFormsData(newAppointmentFormStateData));
        }
    }

    return (
        <>
            <Card 
                title={
                    <Space
                        direction={'vertical'}
                        size={'middle'}
                        style={{
                            width: '100%'
                        }}
                    >
                        <Selector
                            label={'Select a Form to add'}
                            placeholder={'Select a Form to add'}
                            dataList={formDesignList}
                            titleKey={'name'}
                            valueKey={null}
                            onSelectorChange={(value) => onAppointmentFormsAdd(value)}
                            onSelectClear
                        />
                        <h4 style={{
                            marginTop: '10px',
                            lineHeight: '0',
                            marginBottom: '5px',
                            fontWeight: '700'
                        }}>Currently Assigned Forms:</h4>
                    </Space>
                }
            >
                {formListData 
                    && formListData.length > 0 ? 
                    formListData.map((form, idx) => {
                        return (<Card.Grid
                                    key={idx}
                                    style={gridStyle}
                                    hoverable={false}
                                >
                                    <FcDocument
                                        style={{
                                            fontSize: '40px'
                                        }} 
                                    /><br/>
                                    <span 
                                        style={{
                                            width: '100%',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {form.formDesignTitle}
                                    </span>
                                    <Button
                                        type={'link'}
                                        onClick={() => onFormDelete(form.formDesignUniqueId)}
                                    >
                                        <span
                                            style={{
                                                color: '#FF4D4F'
                                            }}
                                        >
                                            <BsTrash
                                                style={{
                                                    fontSize: '15px'
                                                }}
                                            /> Remove
                                        </span>
                                    </Button>
                                </Card.Grid>)
                    })
                : <Empty />}
            </Card>
        </>
    )
};

export default FormItems;
