import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { hexToRgbA } from 'utils';

export default ({ branding }) => {
    const GlobalStyle = createGlobalStyle`
        html,
        body,
        .MuiTypography-h6 {
            font-family: ${branding.theme.typography.fontFamily}, sans-serif!important;
        }
    
        #page-wrapper {
            background: ${branding.theme.palette.background.default}!important;
        }

        .fivef-navbar {
            background: ${branding.theme.palette.navbar.background}!important;
            border-radius: 0!important;
            
            .fivef-navbar-brand,
            #responsive-navbar-nav,
            .nav-link,
            .navbar-light .navbar-nav .nav-link {
                color: ${branding.theme.palette.navbar.text}!important;
            }

            .btn-toggle-mb-sidebar {
                background: ${branding.theme.palette.navbar.background}!important;
                color: ${branding.theme.palette.navbar.text}!important;
            }
        }

        .ant-select {
            &:not(.ant-select-disabled):hover .ant-select-selector,
            &:not(.ant-select-disabled):focus .ant-select-selector,
            &:not(.ant-select-disabled):active .ant-select-selector {
                border-color: ${branding.theme.palette.primary.main}!important;
            }
        }

        .ant-pagination-item-active {
            border-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-input {
            &:hover {
                border-color: ${branding.theme.palette.primary.main}!important;
            }

            &:focus {
                border-color: ${branding.theme.palette.primary.main}!important;
                outline: 0;
                -webkit-box-shadow: 0 0 0 2px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};
                box-shadow: 0 0 0 2px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${branding.theme.palette.primary.main}!important;
            border-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-radio-checked .ant-radio-inner {
            border-color: ${branding.theme.palette.primary.main}!important;
        }
        
        .ant-radio-inner::after {
            background-color: ${branding.theme.palette.primary.main}!important;
        }
    
        .ant-btn-primary {
            background-color: ${branding.theme.palette.button.primaryBackground}!important;
            color: ${branding.theme.palette.button.text}!important;
        }
    
        .fivef-pageTitle {
            color: ${branding.theme.palette.header.primary}!important;
        }
    
        .fivef-user-dropdown {
            a {
                color: ${branding.theme.palette.primary.main}!important;
            }
        }
    
        .sidebar {
            margin-top: 10px!important;
            padding-top: 15px!important;
            ul {
                li {
                    a {
                        color: ${branding.theme.palette.sidebar.mainText}!important;
                        font-weight: 600!important;
    
                        &:hover {
                            background-color: ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                        }
                        
                        &.active {
                            background-color: ${branding.theme.palette.sidebar.activeBackground}!important;
                            color: ${branding.theme.palette.sidebar.activeText}!important;
                            -webkit-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                            -moz-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                        }
                    }
                }
            }
        }
    
        .Topbar-Toolbar-Items {
            color: ${branding.theme.palette.button.primaryBackground}!important;
            
            button {
                color: ${branding.theme.palette.button.primaryBackground}!important;
                outline: none!important;
            }

            .Toolbar-Options {
                color: ${branding.theme.palette.button.primaryBackground}!important;
            }
        }
    
        .Form-Builder-Topbar .Form-Builder-Top-Title {
            color: ${branding.theme.palette.header.primary}!important;
        }
    
        .Form-Builder-Topbar {
            -webkit-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
            -moz-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }
    
        .Form-Builder-Toolbox,
        .Form-Builder-Settings {
            border-top: 5px solid ${branding.theme.palette.primary.main}!important;
            -webkit-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
            -moz-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .ant-switch-checked {
            background-color: ${branding.theme.palette.primary.main}!important;
        }
    
        .ant-radio-group-solid {
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                background: ${branding.theme.palette.primary.main}!important;
                border-color: ${branding.theme.palette.primary.main}!important;
            }
        }

        .ant-picker:hover,
        .ant-picker-focused {
            border-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
            background: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
            background: ${branding.theme.palette.primary.main}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
            background: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .ant-picker-range .ant-picker-active-bar {
            background: ${branding.theme.palette.primary.main}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
            border-color: ${hexToRgbA(branding.theme.palette.primary.main, 0.6)}!important;
        }
    
        .ant-card-bordered {
            border-color: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }
    
        .ant-tabs-tab {
            &:hover {
                color: ${branding.theme.palette.primary.light}!important;
            }
    
            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: ${branding.theme.palette.primary.main}!important;
                }
            }
        }
    
        .ant-tabs-ink-bar {
            background: ${branding.theme.palette.primary.main}!important;
        }
    
        a,
        .ant-btn-link {
            color: ${branding.theme.palette.primary.main}!important;
        }
    
        .text-primary {
            color: ${branding.theme.palette.primary.main}!important;
        }
    
        .choose-image {
            background-color: ${branding.theme.palette.button.primaryBackground}!important;
            color: ${branding.theme.palette.button.text}!important;
            font-size: 12px;
        }

        .Branding-Logo-Settings-Header {
            color: ${branding.theme.palette.header.primary}!important;
        }

        .Dashboard-Card {
            background: ${hexToRgbA(branding.theme.palette.primary.main, 0.8)}!important;
            border: none!important;
        }
        
        .ant-spin-dot-item {
            background-color: ${branding.theme.palette.primary.main}!important;
        }   

        .loader-inner {
            div {
                background: ${branding.theme.palette.primary.main};
            }
        }

        .With-Mark:not(.Container) {
            border: 1px dashed ${hexToRgbA(branding.theme.palette.primary.main, 0.5)};
            padding: 2px;

            &.Component-Hovered {
                border: 1px solid ${branding.theme.palette.primary.main}!important;
                background: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
                cursor: move;
            }
        }

        .Settings-Title {
            color: ${branding.theme.palette.primary.main}!important;
        }
    `;

    return <GlobalStyle theme={branding.theme} />
}