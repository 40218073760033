import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    LoginOutlined,
    LogoutOutlined
} from "@ant-design/icons";
import {DatePicker, Radio, Select, Tooltip} from 'antd';
import {cancelVisit, checkIn, checkOut, deleteVisit, getVisitors} from "api/visits";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import DeleteModal from "components/Modals/DeleteModal";
import React, {useEffect, useState} from "react";
import CreateVisitorForm from "./components/CreateVisitorForm";
import {Col, Row} from 'react-bootstrap';
import moment from "moment";
import EditVisitorForm from "./components/EditVisitorForm";
import ConfirmationModal from "components/helpers/ConfirmationModal";
import {Link} from "react-router-dom";
import {getAllLocations} from "../../api/locations";
import HospitalSelect from "../../components/location/hospitalSelect";

const Visitors = (props) => {
  const { RangePicker } = DatePicker;
  const [draw, setDraw] = useState(0);
  const [visitor, setVisitor] = useState({});
  const [dataTable, setDataTable] = useState({});
  const [customFilters, setCustomFilters] = useState([]);
  const [cancelVisible, setConfirmationModalVisible] = useState(false);
  const [editVisitorFormVisible, setEditVisitorVisible] = useState(false);
  const [visitId, setVisitId] = useState(0);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
  const [confirmationModalTitle, setConfirmationModalTitle] = useState("");
  const [confirmationModalOnOk, setConfirmationModalOnOk] = useState(() => {
  });
  const {Option} = Select;
  const [hospitalOptions, setHospitalOptions] = useState([]);

  useEffect(() => {
    satisfied();
  }, [])

  const satisfied = async () => {
    let response = await getAllLocations();
    // two nested data calls because it's returning a datatable
    // console.log(response.data.data)
    setHospitalOptions(response.data.data)
  }

  const onChange = value => {
      const paramName = 'locationId';
      const filters = [...customFilters];
      const hospitalFilter = filters.filter(filter => filter.paramName === paramName)[0];

      if (hospitalFilter) {
          hospitalFilter.value = value;
      } else {
          filters.push({ paramName: paramName, value: value })
      }

      setCustomFilters(filters);
  }

  const onBlur = () => {
  }

  const onFocus = () => {
  }

  const onSearch = val => {
  }
  const filterByType = e => {
      const value = e.target.value;
      const paramName = 'visitType';
      const filters = [...customFilters];
      const hospitalFilter = filters.filter(filter => filter.paramName === paramName)[0];

      if (hospitalFilter) {
          hospitalFilter.value = value;
      } else {
          filters.push({ paramName: paramName, value: value })
      }

      setCustomFilters(filters);
  }
  const [visitorId, setVisitorId] = useState("");
  const [editVisitorId, setEditVisitorId] = useState();
  const columns = [
    {
      title: <strong>Visitor ID</strong>,
      dataIndex: "uniqueId",
      key: "Id",
      sorter: true
    },
    {
      title: <strong>Visitor</strong>,
      dataIndex: "visitedPersonFirstName",
      key: "visitedPersonFirstName",
      sorter: true,
      render: (text, record, index) => {
        return ( // todo this should be turned into a link
          <Link onClick={() => {
            setEditVisitorId(record?.id)
            setEditVisitorVisible(true);
            setVisitor(record);
          }}>
            {record?.visitor?.firstName} {record?.visitor?.lastName}
          </Link>
        )
      },
      filter: {
        paramName: 'search',
        label: 'Visitor',
        component: {
          name: 'text',
          placeholder: 'Enter visitor name',
        }
      }
    },
    {
      title: <strong>Type</strong>,
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: <strong>Visit Time</strong>,
      dataIndex: "visitTime",
      key: "visitTime",
      sorter: true,
      render: (text, record, value) => (
        moment(text).format('LLL')
      ),
      filter: {
        paramName: 'date',
        label: 'Visit Date',
        component: {
          name: 'daterange',
        },
        displayFormat: (value) => {
            const dates = value && value.map(date => date.format('DD-MM-yyyy'));
            return `${dates[0]} to ${dates[1]}`;
        },
        beforeRequest: (value) =>
            value && value.map(date => date.format('yyyy-MM-DD'))
                .reduce((result, date, i) => {
                    if(i === 0) result.startDate = date;
                    else result.endDate = date;

                    return result;
                }, {})
      }
    },
    {
      title: <strong>Options</strong>,
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text, record, value) => {
        let checkOption = ''
        let cancelOption = !(record?.cancelled)
          ? <Tooltip title="Cancel">
            <ExclamationCircleOutlined
              onClick={() => {

                setConfirmationModalMessage("Are you sure you want to cancel this visit?")
                setConfirmationModalTitle("Cancel visit")
                setConfirmationModalOnOk(() => () => {
                  cancelVisit(text)
                    .then(() => {
                      setConfirmationModalVisible(false)
                      setDraw(draw + 1)
                    })
                    .catch((err) => {
                      setConfirmationModalVisible(false)
                      setDraw(draw + 1)
                    })
                })
                setConfirmationModalVisible(true)


              }}
              className="hover:cursor-pointer hover:text-danger mx-1"
              style={{fontSize: "18px"}}
            />
          </Tooltip>
          : <span style={{color: "Red"}}>(Cancelled)</span>

        if (!(record?.cancelled)) {
          checkOption = !(record?.checkedIn)
            ? <Tooltip title="Check In">
              <LoginOutlined
                onClick={() => {

                  setConfirmationModalMessage("Confirm Check In")
                  setConfirmationModalTitle("Check In")
                  setConfirmationModalVisible(true)
                  setConfirmationModalOnOk(() => /*async*/() => {
                    // await checkIn(text);
                    checkIn(text)
                      .then(() => {
                        setConfirmationModalVisible(false)
                        setDraw(draw + 1)
                      })
                      .catch((err) => {
                        setConfirmationModalVisible(false)
                        setDraw(draw + 1)
                      })
                  })
                }}
                className="hover:cursor-pointer hover:text-danger mx-1"
                style={{fontSize: "18px"}}
              />
            </Tooltip>
            : (record?.checkedIn) & !(record?.checkedOut)
              ? <Tooltip title="Check Out">
                <LogoutOutlined
                  onClick={() => {
                    setConfirmationModalMessage("Confirm Check Out")
                    setConfirmationModalTitle("Check Out")
                    setConfirmationModalVisible(true)
                    setConfirmationModalOnOk(() => async () => {
                      await checkOut(text);
                      setConfirmationModalVisible(false)
                      setDraw(draw + 1)
                    })

                  }}
                  className="hover:cursor-pointer hover:text-danger mx-1"
                  style={{fontSize: "18px"}}
                />
              </Tooltip>
              : '';

        }
        return (
          <>
            <Tooltip title="Edit">
              <EditOutlined
                onClick={() => {
                  // console.log(text, record, value)
                  setEditVisitorId(record?.id)
                  setEditVisitorVisible(true);
                  setVisitor(record);
                }}
                className="hover:cursor-pointer hover:text-danger mx-1"
                style={{fontSize: "18px"}}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                onClick={() => setVisitorId(text)}
                className="hover:cursor-pointer hover:text-danger mx-1"
                style={{fontSize: "18px"}}
              />
            </Tooltip>
            {cancelOption}
            {checkOption}
          </>
        )
      },
    },
  ];
  return (
    <>
      <Row style={{marginBottom: "20px"}}>
        <Col md='3'>
          <HospitalSelect onChange={onChange}/>
        </Col>
        <Col md='4'>
          <label>Visitor Type</label><br/>
          <Radio.Group onChange={filterByType} defaultValue="ALL" buttonStyle="solid" size="large">
            <Radio.Button value={null}>All</Radio.Button>
            <Radio.Button value="VISITOR">Visitor</Radio.Button>
            <Radio.Button value="PATIENT">Patient</Radio.Button>
            <Radio.Button value="STAFF">Staff</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <ConfirmationModal
        title={confirmationModalTitle}
        visible={cancelVisible}
        onCancel={() => setConfirmationModalVisible(false)}
        okType="primary"
        onOk={confirmationModalOnOk}
        message={confirmationModalMessage}
      />
      <EditVisitorForm
        entity="visitor"
        visible={editVisitorFormVisible}
        setVisibility={setEditVisitorVisible}
        visitId={editVisitorId}
        deleteFunction={deleteVisit}
        onSuccess={() => setDraw(draw + 1)}
      />
      <DeleteModal
        entity="visitor"
        visible={visitorId}
        setVisibility={setVisitorId}
        entityId={visitorId}
        deleteFunction={deleteVisit}
        onSuccess={() => setDraw(draw + 1)}
      />
      <CRUDDataTable
        draw={draw}
        entity="visitor"
        fetchDataFunction={async (params) => {
          return await getVisitors(params);
        }}
        customFilters={customFilters}
        createModal={<CreateVisitorForm/>}
        columns={columns}
      />
    </>
  );
};

export default Visitors;
