import React from "react";
import {FaPlus} from "react-icons/fa";
import {IconContext} from "react-icons";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import QuestionForm from "./QuestionForm";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const QuestionModalForm = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseEvent = () => {
        setOpen(false);
    };

    const createButton = () => {
        if (props.mode === 'Edit') {
            return <button type="button" className="btn btn-success btn-sm" onClick={handleOpen}>
                <FaPlus/> {props.buttonText}
            </button>
        }

        if (props.mode === 'View' && props.actionMenuItem === 'True') {
            return <button type="button" className="btn btn-success btn-sm" onClick={handleOpen}>
                <FaPlus/> View
            </button>
        }


    }

    return (
        <IconContext.Provider value={{style: {fontSize: '26px', paddingRight: '10px'}}}>
            {createButton()}
            <Modal open={open} onClose={handleCloseEvent} aria-labelledby="Questions" aria-describedby="Questions"
            >
                <div style={modalStyle} className={classes.paper}>
                    <QuestionForm id={props.id}
                                    mode={props.mode}
                                    appointmentId={props.appointmentId}
                                    patientId={props.patientId}
                                    question={props.question}
                                    answer={props.answer}
                                    status={props.status}
                                    onClose={handleCloseEvent}/>
                </div>
            </Modal>
        </IconContext.Provider>
    );
};

export default QuestionModalForm;
