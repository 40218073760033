import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { insertSpaceBeforeCapitalLetter } from 'utils';
import { Input } from 'antd';
import { setBrandingList } from 'redux/actions/branding';
import { useSelector, useDispatch } from 'react-redux';

export default ({
    currentState,
    brandingPage 
}) => {
    const dispatch = useDispatch();
    const [currentInputValue, setCurrentInputValue] = useState(currentState.page[brandingPage]);
    const brandingState = useSelector(state => state.branding);

    useEffect(() => {
        setCurrentInputValue(currentState.page[brandingPage]);
    }, [currentState.page[brandingPage]]);

    return <Col xs={12} md={12} lg={12}>
                <label style={{ textTransform: 'capitalize' }}>{insertSpaceBeforeCapitalLetter(brandingPage)}</label><br/>
                <Input
                    placeholder={`Enter ${insertSpaceBeforeCapitalLetter(brandingPage)}`}
                    defaultValue={currentInputValue}
                    value={currentInputValue}
                    onChange={(e) => {
                        const inputValue = e.currentTarget.value;

                        setCurrentInputValue(inputValue);
                    }}
                    onBlur={(e) => {
                        const inputValue = e.currentTarget.value;
                        const { page } = currentState;
                        let brandingList = brandingState.list;

                        const currentBrandingIdx = brandingList.findIndex(brandingItem => brandingItem.tenantUniqueId === currentState.tenantUniqueId);

                        let newBrandingItemState = {...currentState};

                        let newBrandingPage = page;

                        newBrandingPage[brandingPage] = inputValue;

                        newBrandingItemState = {
                            ...newBrandingItemState,
                            page: {...newBrandingPage}
                        }

                        brandingList[currentBrandingIdx] = newBrandingItemState;

                        dispatch(setBrandingList(brandingList));
                    }}
                    style={{ width: '30%' }} />
            </Col>
}