import React from "react";
import {Form} from "formik";
import {FaTrash} from "react-icons/fa/index";

const Delete = (props) => {
    return <div className="modal-dialog">
        <div className="modal-content">

            <Form id="editForm" className="form-horizontal">
                <div className="modal-body">
                    <div className="bootbox-body">Are you sure you would like to delete {props.name}</div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={props.onClose} className="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-danger btn-sm" id="deleteButton"><FaTrash/> Delete</button>
                </div>
            </Form>
        </div>
    </div>
}

export default Delete;