import {
    Editor,
    Frame,
    Element,
} from "@craftjs/core";

import {
    setEditorFocused
} from 'redux/actions/formBuilder';

import 'styles/FormBuilder.scss';

import Button from 'pages/FormBuilder/components/Button';
import Card from 'pages/FormBuilder/components/Card';
import Text from 'pages/FormBuilder/components/Text';
import Container from 'pages/FormBuilder/components/Container';
import Root from 'pages/FormBuilder/components/Root';
import Input from 'pages/FormBuilder/components/Input';
import Panel from 'pages/FormBuilder/components/Panel';
import Select from 'pages/FormBuilder/components/Select';
import Column from 'pages/FormBuilder/components/Column';
import ContainerRow from 'pages/FormBuilder/components/ContainerRow';

import Toolbox from './Toolbox';
import Topbar from './Topbar';
import Settings from './Settings';
import EditorSelector from "./components/Selector";
import CraftEditorInitializer from "./components/CraftEditorInitializer";

import {useDispatch} from 'react-redux';

const FormEditor = ({
    isEditor,
    jsonContent,
    formData ,
    formSubmissionContent = null
}) => {
    const dispatch = useDispatch();

    return (<Editor
                resolver={{
                    Root,
                    Card,
                    Button,
                    Container,
                    Text,
                    Input,
                    Panel,
                    Select,
                    Column,
                    ContainerRow
                }} enabled={isEditor}>
                {isEditor && <><EditorSelector />
                <Topbar formData={formData} />
                <Toolbox/>
                <Settings/></>}
                <div 
                    style={isEditor ? {
                        marginTop: '80px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    } : {}}
                >
                    <div 
                        style={isEditor ? {
                            width: '60vw'
                        }: {}}
                        onMouseEnter={() => {
                            dispatch(setEditorFocused(true));
                        }}
                        onMouseLeave={() => {
                            dispatch(setEditorFocused(false));
                        }}
                    >
                        {jsonContent && <Frame data={jsonContent}>
                            <Element canvas={true} is={Container}>
                            </Element>
                        </Frame>}
                        {formSubmissionContent && 
                            <CraftEditorInitializer 
                                formSubmissionContent={formSubmissionContent} 
                            />}
                    </div>
                </div>
            </Editor>)
}

export default FormEditor;