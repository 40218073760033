import React from "react";
import {FaPlus} from "react-icons/fa";
import {IconContext} from "react-icons";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import LocationForm from "./LocationForm";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 700,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const LocationModalForm = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseEvent = () => {
        setOpen(false);
    };

    return (
        <IconContext.Provider value={{style: {fontSize: '26px', paddingRight: '10px'}}}>
            <button type="button" className="btn btn-success btn-sm" onClick={handleOpen}>
                <FaPlus/> {props.buttonText}
            </button>
            <Modal open={open} onClose={handleCloseEvent} aria-labelledby="Locations" aria-describedby="Locations"
            >
                <div style={modalStyle} className={classes.paper}>
                    <LocationForm id={props.id}
                                  locationName={props.locationName}
                                  childrenAllowed={props.childrenAllowed}
                                  childrenTypeName={props.childrenTypeName}
                                  openingHour={props.openingHour}
                                  closingHour={props.closingHour}
                                  visitCapacity={props.visitCapacity}
                                  onClose={handleCloseEvent}/>
                </div>
            </Modal>
        </IconContext.Provider>
    );
};

export default LocationModalForm;
