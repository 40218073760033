import { Table, message } from 'antd';
import FormItems from 'pages/Settings/FormSelection/FormItems';

message.config({
    duration: 2,
});

const List = ({
    columns,
    listData
}) => (
    listData 
    && listData.length > 0 
    && <Table
        columns={columns}
        showHeader={false}
        bordered
        pagination={false}
        expandable={{
            expandedRowRender: (record => {
                return <FormItems
                            appointmentCodeUniqueId={record.appointmentCodeUniqueId}
                            key={record.uniqueId}
                            data={record?.forms}
                        />
            }),
            defaultExpandAllRows: true
        }}
        dataSource={listData}
    />
)

export default List;
