import React from "react";
import DocumentModalForm from "./DocumentModalForm";
import Aux from "../../components/hoc/AuxFunction";
import DocumentModalDelete from "./DocumentModalDelete";

/* Action menu for Documents

This is used when an admin wants to perform edit and delete actions,

This is encapsulated within Documents component

*/

const DocumentActionMenu = (props) => {

    const editButton = () => {
        if (props.mode === 'Edit') {
            return <DocumentModalForm buttonText="Edit"
                                        id={props.id}
                                        mode={props.mode}
                                        description={props.description}
                                        appointmentId={props.appointmentId}
                                        patientId={props.patientId}
                                        actionMenuItem="True"
            />
        }
    }

    const viewButton = () => {

        if (props.mode === 'View') {
            return <DocumentModalForm buttonText="View"
                                        id={props.id}
                                        mode={props.mode}
                                      description={props.description}
                                        appointmentId={props.appointmentId}
                                        patientId={props.patientId}
                                        actionMenuItem="True"
            />
        }
    }

    const deleteButton = () => {
        if (props.mode === 'Edit') {
            return  <DocumentModalDelete classes="btn btn-danger btn-sm collateral-delete-btn" buttonText="Delete" description={props.description} id={props.id}/>
        }
    }

    return <Aux> {editButton()}      {deleteButton()} {viewButton()}  </Aux>
}

export default DocumentActionMenu;
