import {alertService} from "../../services/AlertService";
import NotificationService from "../../services/MessageService"

export function messagesHasErrored(bool) {
    return {
        type: 'MESSAGES_HAS_ERRORED',
        hasErrored: bool
    };
}

export function messagesIsLoading(bool) {
    return {
        type: 'MESSAGES_IS_LOADING',
        isLoading: bool
    };
}

export function messagesFetchDataSuccess(messages) {
    return {
        type: 'MESSAGES_FETCH_DATA_SUCCESS',
        messages
    };
}

export function messagesFetchData(page, start, length, search, sortColumn, sortDirection, appointmentId) {
    return (dispatch) => {
        dispatch(messagesIsLoading(true));
        NotificationService.getMessages(page, start, length, search, sortColumn, sortDirection, appointmentId)
               .then(function(res) {
                dispatch(messagesIsLoading(false))
                return res;
            }).then(function(res) {
                dispatch(messagesFetchDataSuccess(res.data))
            }).catch(function(res) {
                console.log('OH NO THERE IS AN ERROR', res)
                alertService.error(res, {
                    autoClose: true,
                    keepAfterRouteChange: false
                });
                dispatch(messagesHasErrored(true))
            });
        };
}
