import { connect } from 'react-redux'
import { messagesFetchData } from "redux/actions/messages";
import DataTable from "components/Layout/DataTable";
import React from "react";

const mapStateToProps = (state) => {
    return {
        messages: state.messages,
        hasErrored: state.messagesHasErrored,
        isLoading: state.messagesIsLoading
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchData: (page, start, length, search, sortColumn, sortDirection) => dispatch(messagesFetchData(page, start, length, search, sortColumn, sortDirection, props.appointmentId))
    };
}

class MessageDataTable extends DataTable {

    render(props) {
        return super.render(props, this.props.messages.data, this.props.messages.recordsTotal);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageDataTable);