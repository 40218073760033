import React, { useState, FunctionComponent } from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import {
  ArrowUpOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { message } from 'antd';
import DeleteModal from "components/Modals/DeleteModal";
import ActionButtonsDropdown from "components/ActionButtonsDropdown";
import CreateBroadcastModal from "./CreateBroadcastModal";
import { createBroadcast, getBroadcastDataTable, deleteBroadcast } from 'api/broadcasts';

interface BroadcastsProps {}

const Broadcasts: FunctionComponent<BroadcastsProps> = (props) => {
  const [draw, setDraw] = useState(0);
  const [broadcastId, setBroadcastId] = useState(undefined);

  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      sorter: true,
      // render: (value: any, record: any, index: any) => {
      //     let statusBadge = null;

      //     if (record?.status === 'LIVE') {
      //         statusBadge = <Badge variant='danger'>LIVE</Badge>
      //     }

      //     if (record?.status === 'DRAFT') {
      //         statusBadge = <Badge variant='dark'>DRAFT</Badge>
      //     }

      //     if (record?.status === 'INACTIVE') {
      //         statusBadge = <Badge variant='secondary'>INACTIVE</Badge>
      //     }

      //     return <><Link to={`/form-builder/${record?.id}`}>{value}</Link> {statusBadge}</>;
      // },
    },
    {
      title: <strong>Message</strong>,
      dataIndex: "message",
      key: "message",
      sorter: true,
    },
    {
      title: <strong>Location</strong>,
      dataIndex: "location",
      key: "location",
      sorter: true,
    },
    {
      title: <strong>Recipient</strong>,
      dataIndex: "recipientType",
      key: "recipientType",
      sorter: true,
    },
    {
      title: <strong>Type</strong>,
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: <strong>Date Sent</strong>,
      dataIndex: "dateSent",
      key: "dateSent",
      sorter: true,
    },
    {
      title: <strong>Options</strong>,
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text: any, record: any, value: any) => (
        <ActionButtonsDropdown
          menus={[
            {
              label: "Resend",
              icon: <ArrowUpOutlined />,
              onClick: async () => {
                try {
                  const broadcastIdValue = record.id;

                  await createBroadcast({
                    id: broadcastIdValue
                  }, '?resend=true');
                  setDraw(1);
                  message.success('Broadcast successfully resend', 2);
                } catch (error) {
                  message.error('Broadcast resend failed', 2);
                }
              }
            },
            {
              label: "Delete",
              icon: <DeleteOutlined />,
              onClick: () => {
                const broadcastIdValue = record.id;
                setBroadcastId(broadcastIdValue)
              },
            },
            // {
            //     label: 'Download',
            //     icon: <FileExcelOutlined />,
            //     dividerBefore: true,
            //     hide: record.status !== 'LIVE',
            //     onClick: () => {
            //         setActiveFormDesignId(record.id);
            //         setFilterShown(true);
            //     }
            // }
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <DeleteModal
        entity="broadcast"
        visible={broadcastId}
        setVisibility={setBroadcastId}
        entityId={broadcastId}
        deleteFunction={() => deleteBroadcast(broadcastId)}
        onSuccess={() => setDraw(draw + 1)}
      />
      <CRUDDataTable
        draw={draw}
        entity="broadcast"
        fetchDataFunction={async (params: any) => {
          params = {
            ...params,
            sortColumn: 'createdAt',
            sortDirection: 'DESC',
          }
          return await getBroadcastDataTable(params);
        }}
        createModal={<CreateBroadcastModal setDraw={setDraw} draw={draw} />}
        // button={<Link to='/form-builder'><Button type="primary">Create New Form</Button></Link>}
        columns={columns}
      />
    </>
  );
};

export default Broadcasts;
