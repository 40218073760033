export const hexToRgbA = (hex, opacity) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

export const insertSpaceBeforeCapitalLetter = (string) => {
    string = string.replace(/([a-z])([A-Z])/g, `$1 $2`);
    string = string.replace(/([A-Z])([A-Z][a-z])/g, `$1 $2`)
    return string;
}

export const createGoogleFontLinkTag = (
    source,
    id
) => {
    if (!document.getElementById(id)) {
        const link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.href = source;
        document.head.appendChild(link);
    } else {
        const link = document.getElementById(id);
        link.href = source;
    }
}