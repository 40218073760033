import React from "react";
import { Tooltip } from 'antd';
import Aux from "../../components/hoc/AuxFunction";
import DataTable from "./QuestionDataTable";
import QuestionModalForm from "./QuestionModalForm";
import QuestionActionMenu from "./QuestionActionMenu";
import {Alert} from "../../components/Layout/Alert";

/*

 */
const Questions = (props) => {

    const cols = [

        {
            name: "question",
            label: "Question",
            options: {
                filter: false
            }
        },
        {
            name: "answer",
            label: "Answer",
            options: {
                filter: false
            }
        },{
            name: "status",
            label: "Status"
        },
        // hidden columns
        {
            name: "id",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "appointmentId",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "patientId",
            options: {
                "display": false,
                "searchable": false,
                filter: false
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return <Tooltip title="Update Question">
                        <QuestionActionMenu
                            mode={props.mode}
                            id={tableMeta.rowData[3]}
                            appointmentId={tableMeta.rowData[4]}
                            patientId={tableMeta.rowData[5]}
                            question={tableMeta.rowData[0]}
                            answer={tableMeta.rowData[1]}
                            status={tableMeta.rowData[2]}
                        />
                    </Tooltip>
                }
            },
        },
    ];
    return (
        <Aux>
            <Alert/>
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="page-header">Questions</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className="col-xs-12 col-md-3 col-lg-2 pull-right w-o-gutter">
                                <QuestionModalForm buttonText="New Question" appointmentId={props.appointmentId} patientId={props.patientId} mode={props.mode} actionMenuItem="False"/>
                            </div>
                            <div className="clearfix"/>
                        </div>
                        <div className="panel-body">
                            <DataTable appointmentId={props.appointmentId} patientId={props.patientId} columns={cols} heading="Questions"/>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
};

export default Questions;
