import axios from 'axios';
import BaseService from "./BaseService";

const LOCATION_API_BASE_URL = "/api/locations";

class LocationService extends BaseService {

    constructor() {
        super(LOCATION_API_BASE_URL);

    }

    getSystemProperties(page, start, length, search, sortOrder, sortDirection){
        return axios.get(LOCATION_API_BASE_URL + '/location', {
            timeout: 5000,
            params: {
                page: page,
                draw: 1,
                start: start,
                sortOrder: sortOrder,
                sortDirection: sortDirection,
                length: length,
                search: search
            }
        });
    }
}

export default new LocationService()