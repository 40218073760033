import {alertService} from "../../services/AlertService";
import DocumentService from '../../services/DocumentService'

export function documentsHasErrored(bool) {
    return {
        type: 'DOCUMENTS_HAS_ERRORED',
        hasErrored: bool
    };
}

export function documentsIsLoading(bool) {
    return {
        type: 'DOCUMENTS_IS_LOADING',
        isLoading: bool
    };
}

export function documentsFetchDataSuccess(documents) {
    return {
        type: 'DOCUMENTS_FETCH_DATA_SUCCESS',
        documents
    };
}

export function documentsDeleteDataSuccess(documents) {
    return {
        type: 'DOCUMENTS_REMOVE_QUESTION_SUCCESS',
        documents
    };
}

export function documentsRemoveDocument(id) {
    return (dispatch) => {
        DocumentService.deleteItem(id)
        .then(function(res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(documentsDeleteDataSuccess(id))
            return res;
        }).then(function(res) {
            dispatch(documentsFetchData(0, 0, 5, "", []));
        }).catch(function(res) {
            console.log('OH NO THERE IS AN ERROR', res);
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(documentsHasErrored(true))
        });
    };
}

export function documentsAddDocument(location) {
    return (dispatch) => {
        DocumentService.addItem(location)
        .then(function(res) {
            dispatch(documentsFetchData(0, 0, 5, "", []));
        }).then(function(res) {
            alertService.success('Success!!', {
                autoClose: true,
                keepAfterRouteChange: false
            });
        }).catch(function(res) {
            console.log('OH NO THERE IS AN ERROR', res)
            alertService.error(res, {
                autoClose: true,
                keepAfterRouteChange: false
            });
            dispatch(documentsHasErrored(true))
        });
    };
}

export function documentsFetchData(page, start, length, search, sortOrder, sortDirection, appointmentId, patientId) {

    return (dispatch) => {
        dispatch(documentsIsLoading(true));
            DocumentService.getDocuments(page, start, length, search, sortOrder, sortDirection, appointmentId, patientId)
               .then(function(res) {
                dispatch(documentsIsLoading(false))
                return res;
            }).then(function(res) {
                dispatch(documentsFetchDataSuccess(res.data))
            }).catch(function(res) {
                console.log('OH NO THERE IS AN ERROR', res)
                alertService.error(res, {
                    autoClose: true,
                    keepAfterRouteChange: false
                });
                dispatch(documentsHasErrored(true))
            });
        };
}
