import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  PageHeader,
  Row,
  Tooltip,
  Typography,
  Tag,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { FcMediumPriority, FcMindMap, FcOvertime } from 'react-icons/fc';
import { AiFillPhone } from 'react-icons/ai';
import moment from 'moment';
import Notes from './components/Notes';
import Messages from './components/Messages';
import Heartrate from './components/Heartrate';
import Attachments from './components/Attachments';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAppointment,
  appointmentDetailsFetchDataRequest,
} from '../../redux/actions/appointments';
import { startTelehealthMeeting } from '../../redux/actions/zoom';
import { BsFillCameraVideoFill } from 'react-icons/all';

import AppointmentCollateral from './components/Collateral';
import AppointmentForm from 'pages/Appointment/components/Forms';
import AppointmentTasks from 'pages/Appointment/components/Tasks';
import { useAppSelector } from './../../hooks/use-app-selector';

const { Text, Title } = Typography;

const AppointmentPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [refreshTaskList, setRefreshTaskList] = useState(false);
  const { data, error, isLoading } = useSelector((state) => state.appointment);

  const permissions = useAppSelector((state) => state.permissions.permissions);

  useEffect(() => {
    dispatch(fetchAppointment(id));
    return () => {
      dispatch(appointmentDetailsFetchDataRequest(true));
    };
  }, []);
  return (
    <>
      {data && !isLoading ? (
        <>
          <Row className='mb-4' gutter={[16, 16]}>
            <Col xs={24} sm={24} xl={8} xxl={8}>
              <Card
                className='h-110 appointment-summary-card'
                title={
                  <Title
                    level={3}
                    className='mb-0 d-block d-flex align-items-center text-primary font-weight-bold'
                  >
                    {data?.appointmentType}
                  </Title>
                }
                extra={
                  data?.attendanceType === 'TELEHEALTH' &&
                  permissions.Appointments.includes(
                    'appointment:telehealth'
                  ) ? (
                    <Tooltip
                      visible={data?.zoomMeetingPatientJoinDate !== undefined}
                      title='Patient has entered the meeting'
                    >
                      <Button
                        className='text-white bg-green'
                        onClick={() => {
                          dispatch(startTelehealthMeeting(id));
                        }}
                        icon={
                          <BsFillCameraVideoFill
                            style={{ float: 'left', marginTop: '3px' }}
                            className='hover:border-none'
                          />
                        }
                      >
                        <span style={{ float: 'right', marginLeft: '3px' }}>
                          Start Telehealth Call
                        </span>
                      </Button>
                    </Tooltip>
                  ) : (
                    ''
                  )
                }
              >
                <Row>
                  <Col span={8}>Name:</Col>
                  <Col span={16}>
                    <strong>{`${data?.firstName} ${data?.lastName}`}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Contact #:</Col>
                  <Col span={16}>
                    <strong>{`${data?.contactNumber}`}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Email Address:</Col>
                  <Col span={16}>
                    <strong>{`${data?.email}`}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Attendance Type:</Col>
                  <Col span={16}>
                    <Tag
                      color={
                        data?.attendanceType === 'TELEHEALTH' ? 'green' : 'blue'
                      }
                    >
                      {data?.attendanceType}
                    </Tag>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} xl={16} xxl={16}>
              <Card bodyStyle={{ height: '100%' }}>
                <Row>
                  <Col xs={24} sm={24} xl={8} className='p-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <div className='mb-2 text-primary font-weight-bold'>
                          Date & Time
                        </div>
                        <h1>
                          {moment(data?.appointmentDateTime).format('LLL')}
                        </h1>
                      </div>
                      <FcOvertime style={{ width: 48, height: 48 }} />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} xl={8} className='p-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <div className='mb-2 text-primary font-weight-bold'>
                          Clinic
                        </div>
                        <h1>{data?.locationName}</h1>
                        <div>
                          <div className='mb-2 text-primary font-weight-bold'>
                            Clinician
                          </div>
                          <h4 className='font-weight-bold'>
                            {data?.attendingDoctorName}
                          </h4>
                        </div>
                      </div>
                      <FcMindMap style={{ width: 48, height: 48 }} />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} xl={8} className='p-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <div className='mb-2 text-primary font-weight-bold'>
                          Phone Number
                        </div>
                        <h1>{data?.contactNumber}</h1>
                      </div>
                      <AiFillPhone style={{ width: 48, height: 48 }} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className='mb-4' gutter={[16, 16]}>
            <Col xs={24} sm={24} xl={12} xxl={8}>
              {permissions.Appointments.includes('appointment:tasks') ? (
                <AppointmentTasks
                  appointmentId={data?.id}
                  refreshTaskList={refreshTaskList}
                  setRefreshTaskList={setRefreshTaskList}
                />
              ) : null}
              {permissions.Appointments.includes('appointment:messages') ? (
                <Messages appointmentId={data?.id} />
              ) : null}
              {permissions.Appointments.includes('appointment:notes') ? (
                <Notes appointmentId={data?.id} />
              ) : null}
            </Col>
            <Col xs={24} sm={24} xl={12} xxl={16}>
              {permissions.Appointments.includes('appointment:forms') ? (
                <AppointmentForm appointmentId={data?.id} />
              ) : null}
              {permissions.Appointments.includes('appointment:attachments') ? (
                <Attachments
                  appointmentId={data?.id}
                  setRefreshTaskList={setRefreshTaskList}
                />
              ) : null}
              {permissions.Appointments.includes('appointment:collateral') ? (
                <AppointmentCollateral appointmentId={data?.id} />
              ) : null}
            </Col>
            {process.env.REACT_APP_SHOW_HEART_RATE && (
              <Col>
                <Heartrate />
              </Col>
            )}
          </Row>
        </>
      ) : null}
    </>
  );
};

export default AppointmentPage;
