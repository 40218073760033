import React, { useRef, useState, useEffect } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {getCollateralsDatatableById, openAppointmentCollateralFile} from 'api/appointmentCollaterals';
import CreateCollateralModal from './CreateCollateralModal';
import { DeleteOutlined, EditOutlined, PaperClipOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import {Link} from 'react-router-dom';

const columns = [
  {
    title: <strong>Name</strong>,
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (text, record, value) => {
      return ( 
        <Link onClick={() => openAppointmentCollateralFile(record.id).then(res => window.open(res.data))}>
          {record.collateral.name}
        </Link>
      )
    }
  },
  {
    title: <strong>Description</strong>,
    dataIndex: "description",
    key: "description",
    sorter: true,
    render: (text, record, value) => {
      return record.collateral.description;
    }
  },
  {
    title: <strong>Options</strong>,
    dataIndex: "id",
    key: "id",
    sorter: false,
    render: (text, record, value) => (
      <ActionButtonsDropdown 
        menus={
          [
            {
              label: 'Download Resource',
              icon: <CloudDownloadOutlined />,
              onClick: () => {
                  // window.open(`${BASE_URL}/appointment-collaterals/${record.id}/file`);
                  openAppointmentCollateralFile(record.id)
                    .then(res=> {
                      window.open(res.data);
                    });
              }
            },
            {
              label: 'Delete Resource',
              icon: <DeleteOutlined />,
              onClick: () => {}
            }
          ]
        }
      />
    ),
  },
];

const AppoinmentCollateral = ({appointmentId}) => {
  const [draw, setDraw] = useState(0);

  useEffect(loadAppointmentCollaterals => {
    setDraw(draw + 1);
  }, [appointmentId]);

  return (
    <CRUDDataTable
      fetchDataFunction={async (params) => {
        return await getCollateralsDatatableById(appointmentId, params);
      }}
      title="Resources"
      entity="resource"
      createModal={
        <CreateCollateralModal appointmentId={appointmentId} icon={<PaperClipOutlined className="mr-2"/>} />
      }
      columns={columns}
      draw={draw}
    />
  );
};

export default AppoinmentCollateral;
