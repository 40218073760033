import React, {useEffect, useRef, useState} from "react";
import {Col, message, Modal, Row, Typography} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import {PaperClipOutlined, FormOutlined} from "@ant-design/icons";
import {createForm} from "api/forms";
import lz from 'lzutf8';

const {Title, Text} = Typography;

const CreatePublicForm = ({visible, setVisibility, onSuccess}) => {
    const [files, setFiles] = useState([]);
    const formikRef = useRef(null);

    function onRemoveFile(file) {
        const index = files.indexOf(file);
        const newFiles = files.slice();
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    useEffect(() => {
        if (!visible) {
            reset();
        }
    }, [visible])

    async function create(values) {
        try {
            message.loading('Creating form...', 0);

            // Should find a way to programmatically add a 'Container' via craftjs
            // For the meantime, this is the default container value
            const json = lz.encodeBase64(lz.compress(JSON.stringify(
                {
                    "ROOT": {
                        "type": {"resolvedName": "Container"},
                        "isCanvas": true,
                        "props": {},
                        "displayName": "ie",
                        "custom": {},
                        "hidden": false,
                        "nodes": ["vlhkMnCbF"]
                    },
                    "vlhkMnCbF": {
                        "type": {"resolvedName": "Card"},
                        "isCanvas": true,
                        "props": {"id": "Container"},
                        "displayName": "ie",
                        "custom": {},
                        "hidden": false,
                        "parent": "ROOT"
                    }
                })));
            values.content = json;
            let res = await createForm(values);
            let form = res.data;
            message.destroy();
            // onSuccess(); because instead of refreshing the datatable,
            // should redirect to form builder
            message.success('Form successfully uploaded.');
            setVisibility(false)
            window.location.replace(`/form-builder/${form.id}`)
        } catch (error) {
            message.destroy();
            message.error(error.toString());
        }
    }

    function reset() {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    return (
        <Modal
            title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}><FormOutlined
                className="mr-2"/> New Form</Title>}
            visible={visible}
            onCancel={() => setVisibility(false)}
            footer={null}
        >
            <Formik
                innerRef={formikRef}
                initialValues={{name: ""}}
                // validationSchema={CreateCollateralSchema}
                onSubmit={values => create(values)}
                onReset={() => setFiles([])}
                render={() => (
                    <Form layout="vertical" colon={false}>
                        <Form.Item label="Name" name="name" className="mb-4">
                            <Input name="name"/>
                        </Form.Item>
                        <Row gutter={4} className="d-flex justify-content-end">
                            <Col>
                                <ResetButton>Reset</ResetButton>
                            </Col>
                            <Col>
                                <SubmitButton>Create</SubmitButton>
                            </Col>
                        </Row>
                    </Form>
                )}
            />
        </Modal>
    );
};

export default CreatePublicForm;
