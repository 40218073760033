import React from "react";
import MUIDataTable from "mui-datatables";
import {CircularProgress, Typography} from "@material-ui/core";

class DataTable extends React.Component {

    constructor(props) {
        super(props);

        this.localState = {
            page: 0,
            count: 0,
            rowsPerPage: 5,
            sortDirection: "ASC",
            systemProperties: [["Loading Data..."]],
            columns: [],
            search: "",
            filters: [],
            isLoading: false
        };
    }

    render(props, data, count) {
        const {isLoading, rowsPerPage, sortOrder, sortDirection} = this.localState;

        const options = {
            selectableRows: 'none',
            selectableRowsHideCheckboxes: true,
            filter: true,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: true,
            count: count,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [5, 10, 100],
            sortOrder: sortOrder,
            sortDirection: sortDirection,
            download: false,
            print: false,
            viewColumns: false,
            onSearchChange: (searchText) => {
                this.localState.search = searchText;
                this.search();
            },
            onChangeRowsPerPage: (numberOfRow) => {
                this.localState.rowsPerPage = numberOfRow
                this.changeRowsPerPage();
            },
            // onColumnSortChange: (changedColumn: string, direction: string) => {
                onColumnSortChange: (changedColumn, direction) => {
                this.localState.sortOrder = changedColumn;
                this.localState.sortDirection = direction;
                this.changeSort();
            },
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.sortOrder);
                        break;
                    case 'sort':
                        this.sort(tableState.page, tableState.sortOrder);
                        break;
                    default:
                }
            }
        };

        return (
            <div>
                <MUIDataTable title={<Typography variant="h6">
                    {this.props.heading}
                    {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                </Typography>
                } data={data} columns={this.props.columns} options={options}/>
            </div>
        );

    }


    componentDidMount() {
        this.props.fetchData(this.localState.page, 0, this.localState.rowsPerPage, this.localState.search,
            this.localState.sortOrder, this.localState.sortDirection, this.localState.filters);
        this.localState.isLoading = false;
    }

    sort = () => {
        this.localState.isLoading = true;
        this.props.fetchData(this.localState.page, 0, this.localState.rowsPerPage, this.localState.search,
            this.localState.sortOrder, this.localState.sortDirection, this.localState.filters);
        this.localState.isLoading = false;
    }

    changePage = (page) => {
        this.localState.isLoading = true;
        this.props.fetchData(page, page * this.localState.rowsPerPage, this.localState.rowsPerPage, this.localState.search,
            this.localState.sortOrder, this.localState.sortDirection, this.localState.filters);
        this.localState.isLoading = false;
    };

    changeRowsPerPage = () => {
        this.props.fetchData(this.localState.page, this.localState.page * this.localState.rowsPerPage, this.localState.rowsPerPage, this.localState.search,
            this.localState.sortOrder, this.localState.sortDirection, this.localState.filters);
        this.localState.isLoading = false;
    };

    changeSort = () => {
        this.props.fetchData(this.localState.page, this.localState.page * this.localState.rowsPerPage, this.localState.rowsPerPage, this.localState.search,
            this.localState.sortOrder, this.localState.sortDirection, this.localState.filters);
        this.localState.isLoading = false;
    };

    search = () => {
        this.props.fetchData(this.localState.page, 0, this.localState.rowsPerPage, this.localState.search,
            this.localState.sortOrder, this.localState.sortDirection, this.localState.filters);
        this.localState.isLoading = false;
    }

}

export default DataTable;