import React from "react";
import QuestionModalForm from "./QuestionModalForm";
import Aux from "../../components/hoc/AuxFunction";
import QuestionModalDelete from "./QuestionModalDelete";

/* Action menu for Questions

This is used when an admin wants to perform edit and delete actions,

This is encapsulated within Questions component

*/

const QuestionActionMenu = (props) => {

    const editButton = () => {
        if (props.mode === 'Edit') {
            return <QuestionModalForm buttonText="Answer"
                                        id={props.id}
                                        mode={props.mode}
                                        question={props.question}
                                        answer={props.answer}
                                        status={props.status}
                                        appointmentId={props.appointmentId}
                                        patientId={props.patientId}
                                        actionMenuItem="True"
            />
        }
    }

    const viewButton = () => {

        if (props.mode === 'View') {
            return <QuestionModalForm buttonText="View"
                                        id={props.id}
                                        mode={props.mode}
                                        question={props.question}
                                        answer={props.answer}
                                        status={props.status}
                                        appointmentId={props.appointmentId}
                                        patientId={props.patientId}
                                        actionMenuItem="True"
            />
        }
    }

    const deleteButton = () => {
        if (props.mode === 'Edit') {
            return  <QuestionModalDelete classes="btn btn-danger btn-sm collateral-delete-btn" buttonText="Delete" question={props.question} id={props.id}/>
        }
    }

    return <Aux> {editButton()}      {deleteButton()} {viewButton()}  </Aux>
}

export default QuestionActionMenu;
