import React, {FunctionComponent, useState} from "react";
import ActionButtonsDropdown from "../../components/ActionButtonsDropdown";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import CreateNotificationsTemplateModal from "../NotificationsTemplates/CreateNotificationsTemplateModal";
import {Button} from "antd";
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";

interface CheckedInPatientsProps {

}

const CheckedInPatients: FunctionComponent<CheckedInPatientsProps> = (props) => {
    const [draw, setDraw] = useState<number>(1);
    const columns = [
        {
            title: <strong>No.</strong>,
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: <strong>Name</strong>,
            dataIndex: 'name',
            key: 'name',
            sorter: true
        },
        {
            title: <strong>Queue Number</strong>,
            dataIndex: 'queueNumber',
            key: 'queueNumber',
            sorter: true
        },
        {
            title: <strong>Pod</strong>,
            dataIndex: 'pod',
            key: 'pod',
            sorter: true
        },
        {
            title: <strong>Station</strong>,
            dataIndex: 'station',
            key: 'station',
            sorter: true
        },
        {
            title: <strong>Time Waiting</strong>,
            dataIndex: 'timeWaiting',
            key: 'timeWaiting',
            sorter: true
        },
        {
            title: <strong>Actions</strong>,
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            render: (text: any, record: any, value: any) => (
                <ActionButtonsDropdown
                    menus={
                        [
                            // {
                            //     label: 'Edit Template',
                            //     icon: <EditOutlined />,
                            //     onClick: () => {}
                            // },
                            // {
                            //     label: 'Delete Template',
                            //     icon: <DeleteOutlined />,
                            //     onClick: () => {}
                            // }
                        ]
                    }
                />
            ),
        }
    ]

    return <CRUDDataTable
        draw={draw}
        entity=''
        fetchDataFunction={async (params: any) => {
            return await {
                data: {
                    data: [
                        {
                            id: 1,
                            name: "John Doe",
                            queueNumber: "MATG1",
                            pod: "Pod 1",
                            station: "Station 1",
                            timeWaiting: "15:32"
                        }
                    ]
                }}
        }}
        button={<></>}
        continuousFetchInterval={60000}
        createModal={<></>}
        columns={columns}
        sortOrder='id'
        // button={<Button type='primary'>New Notification Template</Button>}

    />
}

export default CheckedInPatients