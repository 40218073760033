import React, { useEffect, useState } from "react";
import {
  Divider,
  Input,
  InputNumber,
  TimePicker,
  Select,
  Switch,
  Tabs,
  message,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import CardView from "components/Layout/CardView";
import systemPropertiesJson from "mock/systemProperties.json";
import { getAllSettings } from "api/systemSettings";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { setStaging } from "redux/actions/systemProperties";

const StyledContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  z-index: -99;
`;

const SystemProperties = () => {
  const [systemPropertiesData, setSystemPropertiesData] = useState(null);
  const dispatch = useDispatch();
  const systemPropertiesState = useSelector((state) => state.sysProperties);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Save the response to the local/global state
        const response = await getAllSettings();
        setSystemPropertiesData(response.data);
      } catch (err) {
        message.error(
          "Error occurred while fetching the system settings, please refresh the page.",
          1000
        );
      }
    })();
    //setSystemPropertiesData(systemPropertiesJson);
  }, []);

  const setData = (array, json, value) => {
    if (typeof json === "object" && Object.keys(json).includes("name")) {
      if (array && array.length > 1) {
        var key = array[0];
        if (json.name === key) {
          var newJson = json["children"];
          array.shift();
          json["children"] = setData(array, newJson, value);
        }
      } else {
        // expect leaf node here
        if (array && array.length > 0 && json.name === array[0]) {
          json.value = value;
        }
      }
    } else if (Array.isArray(json)) {
      json.forEach((item, i) => {
        var newJson = json[i];
        json[i] = setData(array, newJson, value);
      });
    } else {
      if (array && array.length > 0) {
        var key = array.shift();
        var newJson = json[key];
        json[key] = setData(array, newJson, value);
      } else {
        throw "Unexpected leaf node";
      }
    }

    return json;
  };

  const generateObjectTypeFieldChildren = (field, parent) => {
    var parentId = `${parent}-${field.name}`;
    if (field.dataType === "OBJECT") {
      return (
        field &&
        field.children &&
        field.children.map((fieldChild, fcIdx) => {
          if (
            fieldChild.dataType !== "OBJECT" &&
            fieldChild.dataType !== "ARRAY"
          ) {
            return (
              <Container key={fcIdx} fluid>
                <Row>
                  <Col className="py-2" xs={12} sm={12} md={10} lg={8} xl={8}>
                    <h4 className="font-weight-bolder Settings-Title text-uppercase">
                      {fieldChild.name.replace(/_/g, " ")}
                      <br />
                      <small style={{ textTransform: "capitalize" }}>
                        {fieldChild.label.replace(/_/g, " ")}
                      </small>
                    </h4>
                  </Col>
                  <Col
                    className="py-2 text-right"
                    xs={12}
                    sm={12}
                    md={2}
                    lg={4}
                    xl={4}
                  >
                    {generatePropertyFieldType(fieldChild, parentId)}
                  </Col>
                  <Divider />
                </Row>
              </Container>
            );
          } else {
            return generateObjectTypeField(fieldChild, parentId);
          }
        })
      );
    }
  };

  const generateObjectTypeField = (field, parent) => {
    var parentId = parent;
    return (
      <Container fluid key={parent}>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <h4 className="font-weight-bolder Settings-Title text-uppercase">
              {field.name.replace(/_/g, " ")}
              <br />
              <small style={{ textTransform: "capitalize" }}>
                {field.label.replace(/_/g, " ")}
              </small>
            </h4>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col
            style={{
              marginLeft: "20px",
            }}
          >
            {generateObjectTypeFieldChildren(field, parentId)}
          </Col>
        </Row>
      </Container>
    );
  };

  const generatePropertyFieldType = (systemPropertyField, parent) => {
    const fieldId = `${parent}`;

    switch (systemPropertyField.dataType) {
      case "FREE_TEXT":
        return (
          <Input
            id={fieldId}
            placeholder={systemPropertyField.label.replace(/_/g, " ")}
            defaultValue={systemPropertyField.value}
            onBlur={(e) => {
              const statePath = fieldId
                .concat("-", systemPropertyField.name)
                .split("-");
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(
                statePath,
                newSystemProperty,
                e.target.value
              );

              dispatch(setStaging(newState));
            }}
          />
        );
      case "TIME":
        return (
          <TimePicker
            id={fieldId}
            style={{
              width: "100%",
            }}
            defaultValue={
              systemPropertyField.value
                ? moment(systemPropertyField.value, "h:mm a")
                : null
            }
            use12Hours
            format={"h:mm a"}
            placeholder={systemPropertyField.label.replace(/_/g, " ")}
            onChange={(time, timeString) => {
              const statePath = fieldId
                .concat("-", systemPropertyField.name)
                .split("-");
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(
                statePath,
                newSystemProperty,
                timeString
              );

              dispatch(setStaging(newState));
            }}
          />
        );
      case "HTML":
        return (
          <Input.TextArea
            id={fieldId}
            rows={10}
            defaultValue={systemPropertyField.value}
            onBlur={(e) => {
              const statePath = fieldId
                .concat("-", systemPropertyField.name)
                .split("-");
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(
                statePath,
                newSystemProperty,
                e.target.value
              );

              dispatch(setStaging(newState));
            }}
          />
        );
      case "DROPDOWN":
        return (
          <Select
            id={fieldId}
            style={{
              width: "100%",
              textAlign: "left",
            }}
            defaultValue={systemPropertyField.value}
            placeholder={systemPropertyField.label.replace(/_/g, " ")}
            onSelect={(value) => {
              const statePath = fieldId
                .concat("-", systemPropertyField.name)
                .split("-");
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(statePath, newSystemProperty, value);

              dispatch(setStaging(newState));
            }}
          >
            {systemPropertyField.options &&
              systemPropertyField.options.length > 0 &&
              systemPropertyField.options.map((option, idx) => {
                const systemPropertyKey = Object.keys(option).toString();
                const systemPropertyValue =
                  systemPropertyField.options[idx][systemPropertyKey];
                return (
                  <Select.Option key={idx} value={systemPropertyKey}>
                    {systemPropertyValue}
                  </Select.Option>
                );
              })}
          </Select>
        );
      case "BOOLEAN":
        return (
          <Switch
            id={fieldId}
            defaultChecked={systemPropertyField.value}
            onChange={(value) => {
              const statePath = fieldId
                .concat("-", systemPropertyField.name)
                .split("-");
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(statePath, newSystemProperty, value);

              dispatch(setStaging(newState));
            }}
          />
        );
      case "NUMBER":
        return (
          <InputNumber
            id={fieldId}
            style={{
              width: "100%",
            }}
            defaultValue={systemPropertyField.value}
            placeholder={systemPropertyField.label.replace(/_/g, " ")}
            onBlur={(e) => {
              const statePath = fieldId
                .concat("-", systemPropertyField.name)
                .split("-");
              const newSystemProperty = Object.assign({}, systemPropertiesData);

              const newState = setData(
                statePath,
                newSystemProperty,
                e.target.value
              );

              dispatch(setStaging(newState));
            }}
          />
        );
      case "OBJECT":
        return generateObjectTypeField(systemPropertyField, fieldId);
      default:
        break;
    }
  };

  return (
    <StyledContainer fluid style={{ minHeight: "200vh" }}>
      <Row className="mx-1 mb-4 m-h-100v">
        <CardView
          style={{
            paddingBottom: "20px",
            width: "100%",
          }}
        >
          <Tabs defaultActiveKey="1" tabPosition="left">
            {systemPropertiesData &&
              Object.keys(systemPropertiesData).map((systemProperty, i) => (
                <Tabs.TabPane tab={`${systemProperty} Settings`} key={i}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Container key={i} className="mt-4" fluid>
                        <Row>
                          <h3 className="font-weight-bolder">
                            {systemProperty} Settings
                          </h3>
                          <Divider />
                        </Row>
                        {systemPropertiesData[systemProperty] &&
                          systemPropertiesData[systemProperty].length > 0 &&
                          systemPropertiesData[systemProperty].map(
                            (systemPropertyFields, idx) => (
                              <Row key={idx}>
                                {systemPropertyFields.dataType !== "OBJECT" ? (
                                  <>
                                    <Col
                                      className="py-2 pt-4"
                                      xs={12}
                                      sm={12}
                                      md={10}
                                      lg={8}
                                      xl={8}
                                    >
                                      <h4 className="font-weight-bolder Settings-Title">
                                        {systemPropertyFields.name.replace(
                                          /_/g,
                                          " "
                                        )}
                                        <br />
                                        <small
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {systemPropertyFields.label.replace(
                                            /_/g,
                                            " "
                                          )}
                                        </small>
                                      </h4>
                                    </Col>
                                    <Col
                                      className="text-right py-2 pt-3"
                                      xs={12}
                                      sm={12}
                                      md={2}
                                      md={2}
                                      lg={4}
                                      xl={4}
                                    >
                                      {generatePropertyFieldType(
                                        systemPropertyFields,
                                        systemProperty
                                      )}
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col
                                      className="py-2 pt-4"
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      {generatePropertyFieldType(
                                        systemPropertyFields,
                                        systemProperty
                                      )}
                                    </Col>
                                  </>
                                )}
                                <Divider />
                              </Row>
                            )
                          )}
                      </Container>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              ))}
          </Tabs>
        </CardView>
      </Row>
    </StyledContainer>
  );
};

export default SystemProperties;
