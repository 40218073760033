//export const groupBy = (list, keyGetter) => {
//  const map = new Map();
//  list.forEach((item) => {
//    const key = keyGetter(item);
//    const collection = map.get(key);
//    if (!collection) {
//      map.set(key, [item]);
//    } else {
//      collection.push(item);
//    }
//  });
//  return map;
//};

export const groupBy = (items, prop) => {
  var arr = [];
  for (var i = 0, _len = items.length; i < _len; i++) {
    var bfound = false;
    for (var j = 0; j < arr.length; j++) {
      if (arr[j][prop] === items[i][prop]) {
        bfound = true;
      }
    }
    if (bfound === false) {
      arr.push({ [prop]: items[i][prop] });
    }
  }
  return arr;
};
