import React, {Dispatch, FunctionComponent, useState} from "react";
import {Button, Modal, Row, Select, Tabs, Upload, Col, Input} from "antd";
import {PlusCircleFilled, DeleteFilled, UploadOutlined,} from "@ant-design/icons";
import {Color} from "../../../enums/color";

interface InvitePatientModalProps {
    visible: boolean;
    setVisibility: Dispatch<boolean>
}

type EmailRecipient = {
    name: string;
    email: string;
}

type SMSRecipient = {
    name: string;
    mobile: string;
}

const { TabPane } = Tabs;

const InvitePatientModal: FunctionComponent<InvitePatientModalProps> = ({setVisibility, visible}) => {
    const [emailsToInvite, setEmailsToInvite] = useState<EmailRecipient[]>([{
        name: '',
        email: ''
    }])
    const [mobileNumbersToInvite, setMobileNumbersToInvite] = useState<SMSRecipient[]>([{
        name: '',
        mobile: ''
    }])

    function updateArrayMemberAtIndex(value: string, array: Array<any>, index: number, field: any, setter: Dispatch<Array<any>>) {
        const temp = array.slice()
        temp[index][field] = value
        setter(temp)
    }

    function deleteArrayMemberAtIndex(array: Array<any>, index: number, setter: Dispatch<Array<any>>) {
        const temp = array.slice()
        if (typeof temp[index] !== 'undefined') {
            temp.splice(index, 1)
        }
        setter(temp)
    }

    return <Modal
        onCancel={() => setVisibility(false)}
        visible={visible}
        title='Contact Patient'
        footer={[
            <Button key='cancel' onClick={() => setVisibility(false)}>
                Cancel
            </Button>,
            <Button key='send' type='primary' onClick={() => {}}>
                Send
            </Button>,
        ]}>
        <div style={{ marginBottom: 16 }}>
            <p>Message Template</p>
            <Select placeholder='Select Message Template' onChange={() => {}}>
                <Select.Option key={"Invitation - SOP - Pfizer"} value={"Invitation - SOP - Pfizer"}>
                    Invitation - SOP - Pfizer
                </Select.Option>
            </Select>
        </div>
        <Tabs onChange={(activeKey) => {}}>
            <TabPane tab='Email' key='Email'>
                <p>Emails to Invite</p>
                <Row gutter={[8, 8]}>
                    {emailsToInvite.map((email, index) => (
                        <>
                            <Col span={24} key={index}>
                                <Row>
                                    <Col span={9}>
                                        <Input
                                            type='text'
                                            value={emailsToInvite[index].name}
                                            onChange={(e) =>
                                                updateArrayMemberAtIndex(e.target.value, emailsToInvite, index, 'name', setEmailsToInvite)
                                            }
                                            placeholder='John Smith'
                                        />
                                    </Col>
                                    <Col span={10} offset={1}>
                                        <Input
                                            value={emailsToInvite[index].email}
                                            onChange={(e) =>
                                                updateArrayMemberAtIndex(e.target.value, emailsToInvite, index, 'email', setEmailsToInvite)
                                            }
                                            type='email'
                                            placeholder='e.g. john.smith@example.com'
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '100%',
                                            }}>
                                            {index === 0 ? (
                                                <PlusCircleFilled
                                                    onClick={() =>
                                                        setEmailsToInvite((emailsToInvite) => [...emailsToInvite, {
                                                            name: '',
                                                            email: ''
                                                        }])
                                                    }
                                                    style={{
                                                        color: Color.Green,
                                                        fontSize: '2rem',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            ) : (
                                                <DeleteFilled
                                                    onClick={() => deleteArrayMemberAtIndex(emailsToInvite, index, setEmailsToInvite)}
                                                    style={{
                                                        color: Color.Red,
                                                        fontSize: '2rem',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    ))}
                </Row>
            </TabPane>
            <TabPane tab='SMS' key='SMS'>
                <p>Mobile Numbers to Invite</p>
                <Row gutter={[8, 8]}>
                    {mobileNumbersToInvite.map((email, index) => (
                        <>
                            <Col span={24} key={index}>
                                <Row>
                                    <Col span={9}>
                                        <Input
                                            type='text'
                                            value={mobileNumbersToInvite[index].name}
                                            onChange={(e) =>
                                                updateArrayMemberAtIndex(
                                                    e.target.value,
                                                    mobileNumbersToInvite,
                                                    index,
                                                    'name',
                                                    setMobileNumbersToInvite
                                                )
                                            }
                                            placeholder='John Smith'
                                        />
                                    </Col>
                                    <Col span={10} offset={1}>
                                        <Input
                                            value={mobileNumbersToInvite[index].mobile}
                                            onChange={(e) =>
                                                updateArrayMemberAtIndex(
                                                    e.target.value,
                                                    mobileNumbersToInvite,
                                                    index,
                                                    'mobile',
                                                    setMobileNumbersToInvite
                                                )
                                            }
                                            type='number'
                                            placeholder='e.g. 04 XXXX XXXX'
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '100%',
                                            }}>
                                            {index === 0 ? (
                                                <PlusCircleFilled
                                                    onClick={() =>
                                                        setMobileNumbersToInvite((mobileNumbersToInvite) => [
                                                            ...mobileNumbersToInvite,
                                                            {
                                                                name: '',
                                                                mobile: ''
                                                            }
                                                        ])
                                                    }
                                                    style={{
                                                        color: Color.Green,
                                                        fontSize: '2rem',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            ) : (
                                                <DeleteFilled
                                                    onClick={() =>
                                                        deleteArrayMemberAtIndex(mobileNumbersToInvite, index, setMobileNumbersToInvite)
                                                    }
                                                    style={{
                                                        color: Color.Red,
                                                        fontSize: '2rem',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    ))}
                </Row>
            </TabPane>
            <TabPane tab='CSV' key='CSV'>
                <p>
                    Please select a CSV file - <strong>Format: first name, last name, email, mobile</strong>
                </p>
                <Upload
                    accept='.csv'
                    fileList={[]}
                    onChange={(info) => {
                        let fileList = [...info.fileList]
                        fileList = fileList.slice(-1)
                        // setCSVInviteFile(fileList)
                    }}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </TabPane>
        </Tabs>
    </Modal>
}

export default InvitePatientModal;