import {Tooltip} from 'antd';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {deleteLocation, getAllLocations, getLocationChildren, getLocationTree} from "api/locations";
import DeleteModal from "components/Modals/DeleteModal";
import React, {useState} from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import CreateCollateralForm from "../Collateral/components/CreateCollateralForm";
import {Link, useParams} from "react-router-dom";
import LocationForm from "./components/LocationForm";
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';

const Locations = (props) => {
  const {id} = useParams();
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState("");
  const [locationId, setLocationId] = useState();
  const [draw, setDraw] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(() => {
  });
  const [isEditModalVisible, setEditModalVisible] = useState(false);

    const cols = [
        {
            title: <strong>Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
        /*    render: (text, record, value) => {
                return record?.childrenAllowed
                    ? <Link to={`/locations/${record?.id}`}>{text}</Link>
                    : text
            }*/
        },
        {
            title: <strong>Opening Hours</strong>,
            dataIndex: "openingHour",
            key: "openingHour",
            sorter: true,
            render: (text, record, value) => {
                return `${record?.openingHour} to ${record?.closingHour}`
            }
        },
        {
            title: <strong>Visit Capacity</strong>,
            dataIndex: "visitCapacity",
            key: "visitCapacity",
            sorter: true,
        },
        {
            title: <strong>Options</strong>,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text, record, value) => (
                <ActionButtonsDropdown
                    menus={
                        [
                            {
                                label: 'Update Location',
                                icon: <EditOutlined />,
                                onClick: () => {
                                    setLocationId(text)
                                    setSelectedLocation(record)
                                    setEditModalVisible(true)
                                }
                            },
                            {
                                label: 'Delete Location',
                                icon: <DeleteOutlined />,
                                onClick: () => {
                                    setDeleteModalVisibility(text)
                                    setLocationId(text)
                                }
                            }
                        ]
                    }
                />
            ),
        },
    ];

  return (
    <>
      <DeleteModal
        entity="location"
        visible={isDeleteModalVisible}
        setVisibility={setDeleteModalVisibility}
        entityId={locationId}
        deleteFunction={deleteLocation}
        onSuccess={() => setDraw(draw + 1)}
      />
      <LocationForm
        onSuccess={() => setDraw(draw + 1)}
        visible={isEditModalVisible}
        setVisibility={setEditModalVisible}
        locationId={locationId}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />
      <CRUDDataTable
        draw={draw}
        entity="location"
        fetchDataFunction={async (params) => {
          // if (id!==undefined)
          //     return await getLocationChildren(id);
          let res = await getLocationTree();
          addKeys(res.data.data);
          return res;
        }}
        createModal={<LocationForm/>}
        columns={cols}
        rowKey="id" // this SUPPOSEDLY should be the solution, but it doesn't work.
      />
    </>
  );
};

/**
 * Reason for this is because upon further investigation,
 * the expandable tree in ant table is actually reliant on
 * them having "keys" though i'm not sure as to how to make
 * it reliant on the provided id instead, for now this is a temporary hack
 * @param entry
 * @param i
 */
const addKeys = (entry, i = 0) => {
  entry.forEach((item, index)=> {
    item.key = i++;
    if (item.children.length > 0) {
      addKeys(item.children, i);
    }
    else {
      delete item.children
    }
  })
}

export default Locations;
