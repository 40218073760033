import React, { useState, useEffect } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {downloadAttachmentFileById, getAttachmentsDatatableById, getAttachmentUrl} from 'api/attachments';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import CreateAttachmentModal from './CreateAttachmentModal';
import { message, Tooltip, Button } from 'antd';
import fileDownload from 'js-file-download';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CreatePatientTaskModal from 'pages/Appointment/components/Tasks/CreatePatientTaskModal';

function downloadAttachment(record) {
  return async () => {
    const res = await getAttachmentUrl(record.id)
    window.open(res.data)
  };
}

const columns = [
  {
    title: <strong>Description</strong>,
    dataIndex: 'description',
    key: 'description',
    sorter: true,
    render: (text, record, value) => (
      <>
        <Tooltip title='Download Attachment'>
          <span className='hover:cursor-pointer hover:text-primary mx-1' onClick={downloadAttachment(record)}>
            {text}
          </span>
        </Tooltip>
      </>
    ),
  },
  {
    title: <strong>Options</strong>,
    dataIndex: 'id',
    key: 'id',
    sorter: false,
    render: (text, record, value) => (
      <ActionButtonsDropdown
        menus={
          [
            {
              label: 'Download Attachment',
              icon: <CloudDownloadOutlined />,
              onClick: downloadAttachment(record)
            },
            {
              label: 'Delete Attachment',
              icon: <DeleteOutlined />,
              onClick: () => {}
            }
          ]
        }
      />
    ),
  },
];

const AppointmentAttachments = ({appointmentId, setRefreshTaskList}) => {
  const [ createPatientTaskVisible, setCreatePatientTaskVisibility ] = useState(false);
  const [ draw, setDraw ] = useState(0);

  useEffect(loadAppointmentAttachments => {
    setDraw(draw + 1);
  }, [appointmentId]);

  return (
    <>
       <CreatePatientTaskModal 
          taskType='ATTACHMENT'
          visible={createPatientTaskVisible}
          setVisibility={setCreatePatientTaskVisibility}
          appointmentId={appointmentId} 
          icon={<UnorderedListOutlined className='mr-2' />} 
          onSuccess={() => { 
            setCreatePatientTaskVisibility(false); 
            setRefreshTaskList(true); 
          }}
          onCancel={()=> setCreatePatientTaskVisibility(false) }
        />
      <CRUDDataTable
        fetchDataFunction={async (params) => {
          return await getAttachmentsDatatableById(appointmentId, params);
        }}
        title='Attachments'
        entity='attachment'
        createModal={
          <CreateAttachmentModal
            appointmentId={appointmentId}
            icon={<PaperClipOutlined className='mr-2' />}
          />
        }
        columns={columns}
        draw={draw}
        actions={<Button onClick={() => setCreatePatientTaskVisibility(true) }>Request Attachment</Button>}
      />
    </>
  );
};

export default AppointmentAttachments;
