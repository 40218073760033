import React, {useEffect, useState} from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {getPatientTasks, updatePatientTaskStatus} from 'api/patientTasks';
import {getStaffTasks} from 'api/staffTasks';
import CreatePatientTaskModal from 'pages/Appointment/components/Tasks/CreatePatientTaskModal';
import CreateStaffTaskModal from 'pages/Appointment/components/Tasks/CreateStaffTaskModal';
import RejectStaffTaskModal from 'pages/Appointment/components/Tasks/RejectStaffTaskModal';
import {Button, message, Modal, Space, Tabs, Tag} from 'antd';
import {CheckCircleFilled, CloseCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {updateStatus} from 'api/tasks';
import { useSelector, useDispatch } from 'react-redux';
import { setWillTaskListRefresh } from 'redux/actions/appointment';

const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    bottom: -15px;
    z-index: 10;
    left: 25px;
  }

  & .ant-tabs-nav {
    margin: 0 !important;
    position: absolute;
  }

  & .ant-tabs-tab {
    padding: 15px !important;
  }
`

const AppointmentTasks = (props) => {
  const {refreshTaskList, setRefreshTaskList, appointmentId} = props;
  const [createPatientTaskVisible, setCreatePatientTaskVisibility] = useState(false);
  const [createStaffTaskVisible, setCreateStaffTaskVisibility] = useState(false);
  const [isRejectStaffTaskModalVisible, setRejectStaffModalVisibilty] = useState(false);
  const [selectedStaffTaskId, setSelectedStaffTaskId] = useState(null);
  const [selectedTab, setSelectedTab] = useState('patient');
  const [drawPatientTasks, setDrawPatientTasks] = useState(0);
  const [drawStaffTasks, setDrawStaffTasks] = useState(0);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [entityId, setEntityId] = useState();
  const [newStatus, setNewStatus] = useState('NEW');
  const [modalMessage, setModalMessage] = useState('');
  const [taskType, setTaskType] = useState('PATIENT')
  const [patientTaskId, setPatientTaskId] = useState()
  const appointmentState = useSelector(state => state.appointment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (refreshTaskList) {
      setDrawPatientTasks(drawPatientTasks + 1);
      setRefreshTaskList(false);
    }
  }, [refreshTaskList]);

  useEffect(() => {
    if (appointmentState.willTaskListRefresh) {
      setDrawPatientTasks(drawPatientTasks + 1);
      dispatch(setWillTaskListRefresh(false));
    }
  }, [appointmentState.willTaskListRefresh]);

  useEffect(() => {
    if (appointmentId) {
      setDrawPatientTasks(drawPatientTasks + 1);
    }
  }, [appointmentId]);

  const renderPatientTasksAction = (text, record, value) => {
    let action = null;
    if (record.status === 'NEW') {
      action = <Button
        type='primary'
        onClick={() => {
          setPatientTaskId(text)
          setModalMessage('Mark task as Complete?')

          setNewStatus('COMPLETED')
          setConfirmationVisible(true)
        }}
      >
        Complete
      </Button>
    } else {
      action = <Button
        onClick={() => {
          setPatientTaskId(text)
          setModalMessage('Undo Completed task?')

          setNewStatus('NEW')
          setConfirmationVisible(true)
        }}
      >
        Undo
      </Button>
    }
    return action;
  }

  const onOk = () => {
    updateStatus(entityId, {
      rejectionReason: '',
      status: newStatus
    })

      .then(res => {
        setDrawStaffTasks(drawStaffTasks + 1);
        setConfirmationVisible(false);
      })

  }

  const onPatientOk = () => {
    setDrawPatientTasks(0);
    updatePatientTaskStatus(patientTaskId, newStatus)
      .then(res => {
        message.success('Successfully updated task.');
        setDrawPatientTasks(drawPatientTasks + 1);
        setConfirmationVisible(false)
      })
  }

const renderStaffTasksAction = (text, record, value) => {
  let action = null;
  if (record.status === 'NEW') {
    action = (
      <Space>
        <Button
          type='primary'
          onClick={() => {
            setEntityId(text)
            setModalMessage('Mark task as Complete?')

            setNewStatus('COMPLETED')
            setConfirmationVisible(true)
          }}
        >
          Complete
        </Button>
        <Button
          type='danger'
          ghost
          onClick={() => {
            setEntityId(text)
            setModalMessage('Reject task?')

            setNewStatus('REJECTED')
            setConfirmationVisible(true)
            // showCompleteConfirmation(text)
          }}
        >
          Reject
        </Button>
      </Space>
    )
  } else if (record.status === 'COMPLETED') {
    action = <>
      <Button
        onClick={() => {
          setEntityId(text)
          setModalMessage('Undo Completed task?')
          setNewStatus('NEW')
          setConfirmationVisible(true)
        }}
      >
        Undo
      </Button>
    </>
  } else if (record.status === 'REJECTED') {
    action = <>
      <Button
        onClick={() => {
          setEntityId(text)
          setModalMessage('Undo Rejected task?')
          setNewStatus('NEW')
          setConfirmationVisible(true)

        }}
      >
        Undo
      </Button>
    </>
  }
  return action;
}

const fetchPatientTasks = async (params) => {
  params = {
    ...params,
    sortColumn: 'id',
    appointmentId
  }
  return await getPatientTasks(params)
}

const fetchStaffTasks = async (params) => {
  params = {
    ...params,
    sortColumn: 'id',
    appointmentId
  }
  return await getStaffTasks(params);
}

const columns = [
  {
    title: <strong>Task</strong>,
    dataIndex: 'description',
    key: 'description',
    sorter: true,
    render: (text, record, value) => {
      return <>{text}
        <Tag visible={record.status === 'COMPLETED'}
             color='green'
             icon={<CheckCircleFilled style={{position: 'relative', top: '-2px', height: '10px'}}/>}
             style={{marginLeft: '5px'}}>
          DONE
        </Tag>
        <Tag
          visible={record.status === 'REJECTED'}
          color='red'
          icon={<CloseCircleOutlined style={{position: 'relative', top: '-2px', height: '10px'}}/>}
          style={{marginLeft: '5px'}}>
          REJECTED
        </Tag>
      </>

    }
  },
  {
    title: <strong>Actions</strong>,
    dataIndex: 'id',
    key: 'id',
    sorter: false,
    render: selectedTab === 'patient' ? renderPatientTasksAction : renderStaffTasksAction
  },
];


return (
  <>
    <Modal
      visible={confirmationVisible}
      onCancel={() => setConfirmationVisible(false)}
      title={null}
      onOk={taskType === 'STAFF' ? onOk : onPatientOk}
    >
      <strong>{modalMessage}</strong>
    </Modal>
    <RejectStaffTaskModal
      visible={isRejectStaffTaskModalVisible}
      setVisibility={setRejectStaffModalVisibilty}
      id={selectedStaffTaskId}
      onSuccess={() => setDrawStaffTasks(drawStaffTasks + 1)}
    />
    <StyledTabs
      onChange={(tab) => {
        if (tab === 'patient') {
          setTaskType('PATIENT')
          setDrawPatientTasks(drawPatientTasks + 1);
        } else {
          setTaskType('STAFF')
          setDrawStaffTasks(drawStaffTasks + 1);
        }
        setSelectedTab(tab);
      }}
    >
      <StyledTabs.TabPane tab={<span className='text-primary font-weight-bold'>Patient Tasks</span>} key='patient'>
        <CRUDDataTable
          draw={drawPatientTasks}
          tabs={true}
          fetchDataFunction={fetchPatientTasks}
          entity='patient task'
          createModal={
            <CreatePatientTaskModal
              taskType='CUSTOM'
              visible={createPatientTaskVisible}
              setVisibility={setCreatePatientTaskVisibility}
              appointmentId={props.appointmentId}
              icon={<UnorderedListOutlined className='mr-2'/>}
              onSuccess={() => setDrawPatientTasks(0)}
              onCancel={() => {
                setDrawPatientTasks(0);
                setCreatePatientTaskVisibility(false)
              }}
            />
          }
          columns={columns}
        />
      </StyledTabs.TabPane>
      <StyledTabs.TabPane tab={<span className='text-primary font-weight-bold'>Staff Tasks</span>} key='staff'>
        <CRUDDataTable
          draw={drawStaffTasks}
          tabs={true}
          fetchDataFunction={fetchStaffTasks}
          entity='staff task'
          createModal={
            <CreateStaffTaskModal
              visible={createStaffTaskVisible}
              setVisibility={setCreateStaffTaskVisibility}
              appointmentId={props.appointmentId}
              icon={<UnorderedListOutlined className='mr-2'/>}
              fetchData={() => setDrawStaffTasks(0)}
              onCancel={() => {
                setDrawStaffTasks(0);
                setCreateStaffTaskVisibility(false)
              }}
            />
          }
          columns={columns}
        />
      </StyledTabs.TabPane>
    </StyledTabs>
  </>
)
}
;

export default AppointmentTasks
