import { Col, Row, Select } from "antd";
import { Checkbox } from "formik-antd";
import React, { useEffect, useState } from "react";
import { getCollateralList } from "../../../api/collaterals";
import { getForms } from "../../../api/forms";
import { groupBy } from "../../../utils/helpers";

const TaskCreation = ({
  addType,
  addValue,
  index,
  updateCheckBox,
  value,
  actionOptionTemplates,
}) => {
  const { Option } = Select;
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setValues(value?.taskType);
  }, [value]);

  function setValues(val) {
    let value = val;
    if (value === "COLLATERAL") {
      getCollateralList().then((res) => {
        setOptions(res.data.data);
      });
    } else if (value.toUpperCase() === "FORM") {
      let params = {
        length: 10000,
        page: 0,
        sortColumn: "id",
        sortDirection: "ASC",
      };
      getForms(params).then((res) => {
        /*
          TODO for now, this will do because form entity
          also has id and name. But it should be modified
          to cater to possibilities in which a form will
          not have a name
           */
        setOptions(res.data.data);
      });
    }
  }

  const addTaskType = (val) => {
    setValues(val);
    addType(val, index);
  };
  const addTaskValue = (val) => {
    addValue(`${val}`, index);
  };

  const updatePatientCheckbox = (val) => {
    updateCheckBox(val.target.checked, index);
  };

  const rules = actionOptionTemplates.filter((f) => f.actionType === "TASK");
  const groupedTasks = groupBy(rules, "valueType");

  return (
    <>
      <label>Required Value:</label>

      <Row gutter={[4,4]} style={{ width: "100%" }}>
        <Col span={12}>
          <Select
            onChange={addTaskType}
            size="large"
            style={{ width: "100%" }}
            defaultValue={value.taskType ?? "FORM"}
          >
            {/*<Option value='FORM'>Form</Option>
            <Option value='COLLATERAL'>Collateral</Option>*/}
            {groupedTasks.map((item) => {
              return (
                <Option value={item.valueType} key={item.valueType}>
                  {item.valueType}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={12}>
          <Select
            onChange={addTaskValue}
            size="large"
            style={{ width: "100%" }}
          >
            {options.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <Checkbox
              onChange={updatePatientCheckbox}
              value={index}
              name={`createPatientChecklist${[index]}`}
          >
            Create as Task for Patient
          </Checkbox>
        </Col>
      </Row>
    </>
  );
};

export default TaskCreation;
