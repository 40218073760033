import React from 'react';
import {Formik, Field, Form, ErrorMessage } from 'formik';
import {appointmentsAddMessage} from "redux/actions/appointments";
const SendMessageForm = (props) => {

    const submitButton = () => {

        console.log('send message form props is',props);
        if (props.mode === 'Edit') {
            return <button type="submit" className="btn btn-success"
                           id="sendButton">Send
            </button>
        } else {
            return <p>{props.mode}</p>
        }
    }

    return (
        <Formik
            initialValues={
                {
                id: props.id,
                patientId: props.patientId,
                appointmentId: props.appointmentId,
                emailAddress: props.emailAddress,
                subject: props.subject,
                messageText: props.messageText,
                phoneNumber: props.phoneNumber
                }
            }
            onSubmit={(values, {setSubmitting}) => {

                const message = {
                    id: values.id,
                    patientId: values.patientId,
                    appointmentId: values.appointmentId,
                    emailAddress: values.emailAddress,
                    subject: values.subject,
                    messageText: values.messageText,
                    phoneNumber: values.phoneNumber
                }

                appointmentsAddMessage(message);
                props.onClose();
            }

            }
        >
            <div className="modal-dialog">
                <div className="modal-content">

                    <Form id="addMessageForm" className="form-horizontal">
                        <Field name="id" type="hidden"/>
                        <Field name="patientId" type="hidden"/>
                        <Field name="appointmentId" type="text"/>
                        <div className="modal-header">
                            {/*<h3>Send Message to {props.firstName}</h3>*/}
                            <h3>{props.mode} Message</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="emailAddress">emailAddress</label>
                                <Field name="emailAddress" type="text"/>
                                <ErrorMessage name="emailAddress"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="subject">subject</label>
                                <Field name="subject" type="text"/>
                                <ErrorMessage name="subject"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="messageText">messageText</label>
                                <Field name="messageText" type="text"/>
                                <ErrorMessage name="messageText"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="phoneNumber">phoneNumber</label>
                                <Field name="phoneNumber" type="text"/>
                                <ErrorMessage name="phoneNumber"/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-default pull-left" data-dismiss="modal" onClick={props.onClose}>Close</button>
                            {submitButton()}
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
};

export default SendMessageForm;