import React, {useState} from "react";
import {Button, Card, Modal, Space, Tag, Tabs, Tooltip} from "antd";
import TaskRejectionForm from "./TaskRejectionForm";
import {Link} from "react-router-dom";
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";
import {getTaskDatatable, updateStatus} from "../../api/tasks";

const Tasks = (props) => {
  const [rejectionVisible, setRejectionVisibility] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [entityId, setEntityId] = useState();
  const [status, setStatus] = useState('NEW');
  const {TabPane} = Tabs;
  const [modalMessage, setModalMessage] = useState('');

  // yes, confusing naming convention. will fix later sorry
  const [newStatus, setNewStatus] = useState('NEW');

  const columns = [
    {
      title: <strong>Description</strong>,
      dataIndex: 'description',
      key: 'description',
      render: (text, record, value) => {
        let color = record.status === 'COMPLETED' ? 'green' : record.status === 'NEW' ? 'geekblue' : 'red';
        return <Tooltip title={record.rejectionReason}
                        style={{display: record.status === 'REJECTED' ? 'display' : 'none'}}>
          {text} <Tag color={color}>{record.status}</Tag><br/>
          <Link to={`appointments/${record?.appointment?.id}`}>{record?.appointment?.appointmentType}</Link>
        </Tooltip>
      }
    },
    {
      title: <strong>Assignee</strong>,
      dataIndex: 'assigneeType',
      key: 'assigneeType',
    },
    {
      title: <strong>Options</strong>,
      dataIndex: 'id',
      key: 'id',
      render: (text, record, value) => {
        let buttons = (status) => {
          switch (status) {
            case 'NEW':
              return <>
                <Button
                  type='primary'
                  onClick={() => {
                    setEntityId(text)
                    setModalMessage('Mark task as Complete?')
                    setNewStatus('COMPLETED')
                    setConfirmationVisible(true)
                    // showCompleteConfirmation(text)
                  }}
                >Complete</Button>
                <Button danger onClick={() => {
                  setRejectionVisibility(true)
                  setEntityId(text);
                }}>Reject</Button>
              </>
            case 'COMPLETED':
              return <Button onClick={() => {
                setEntityId(text)
                setModalMessage('Undo Completed Task?')

                setNewStatus('NEW')
                setConfirmationVisible(true)
                // showCompleteConfirmation(text)
              }}>Undo</Button>
            case 'REJECTED':
              return <Button onClick={() => {
                setEntityId(text)
                setModalMessage('Undo Rejected Task?')
                setNewStatus('NEW')
                setConfirmationVisible(true)
                // showCompleteConfirmation(text)
              }}>Undo</Button>
          }
        }
        return (
          <Space>
            {buttons(record.status)}

          </Space>
        )
      }
    }
  ];
  const [draw, setDraw] = useState(0);
  const changeTab = key => {
    // setActiveTab(key)
    switch (key) {
      case '1':
        setStatus('NEW')
        setDraw(draw + 1);
        break;
      case '2':
        setStatus('COMPLETED')
        setDraw(draw + 1);
        break;
      case '3':
        setStatus('REJECTED')
        setDraw(draw + 1);
        break;
    }
  }
  return (<>
    <Modal
      visible={confirmationVisible}
      onCancel={() => setConfirmationVisible(false)}
      title={null}

      onOk={() => {
        // console.log(newStatus)
        updateStatus(entityId, {
          rejectionReason: '',
          status: newStatus
        })

          .then(res => {
            // console.log(res)
            setDraw(draw + 1)
            setConfirmationVisible(false);
          })

      }}
    >
      <strong>{modalMessage}</strong>
    </Modal>
    <TaskRejectionForm
      visible={rejectionVisible}
      setVisibility={setRejectionVisibility}
      id={entityId}
      onSuccess={() => {
        setDraw(draw + 1)
      }}
    />
    <Tabs
      onChange={changeTab}
    >
      <TabPane tab='New' key={1}>

      </TabPane>
      <TabPane tab='Completed' key={2}>

      </TabPane>
      <TabPane tab='Rejected' key={3}>

      </TabPane>
    </Tabs>
    <CRUDDataTable
      draw={draw}
      entity="Tasks"
      fetchDataFunction={async (params) => {
        params.status = status
        return await getTaskDatatable(params);
      }}
      createModal={<></>}
      columns={columns}
      sortOrder="id"
      button={<></>}
    />
  </>)
}
export default Tasks