import React, {FunctionComponent, useState} from "react";
import {Col, Row, Checkbox, Select, Input, Form} from "antd";

interface ScheduleCreationProps {

}

const ScheduleCreation: FunctionComponent<ScheduleCreationProps> = (props) => {
    const [immediate, setImmediate] = useState<boolean>(true);

    return <Row gutter={[4,4]} style={{ width: "100%", marginBottom: "10px" }}>
        <Col span={24} style={{
            marginBottom: "10px"
        }}>
            <Checkbox
                onChange={(e) => setImmediate(e.target.checked)}
                checked={immediate}
            >
                Create immediately
            </Checkbox>
        </Col>
        {!immediate ? <><Col span={6}>
            <Form.Item label="Duration">
            <Input style={{
                height: "40px"
            }} /></Form.Item>
        </Col><Col span={18}>
            <Form.Item label=" ">
            <Select
                size="large"
                style={{ width: "100%" }}
                defaultValue="days"
                options={[
                    {
                        value: "days",
                        label: "Days"
                    },
                    {
                        value: "weeks",
                        label: "Weeks"
                    },
                    {
                        value: "months",
                        label: "Months"
                    }
                ]}
            >
            </Select>
            </Form.Item>
        </Col></> : null}
    </Row>
}

export default ScheduleCreation;