import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Button, Card, Col, DatePicker, message, Row, Space, Statistic, Typography} from 'antd';
import {useQuery} from 'react-query';
import {getDailyAppointmentData, getDashboardSummary} from 'api/dashboard';
import HospitalSelect from 'components/location/hospitalSelect';
import StackedBarChart from 'components/graphs/StackedBarChart';
import moment from 'moment';

const {Title} = Typography;
const {RangePicker} = DatePicker;
const Dashboard = () => {
  const REFETCH_INTERVAL_IN_MILLISECONDS = 10000;
  const [params, setParams] = useState({});
  const {data, isLoading, error, refetch} = useQuery('getDashboardSummary', async () => {
    let res = await getDashboardSummary(params);
    return res.data;
  }, {
    refetchInterval: REFETCH_INTERVAL_IN_MILLISECONDS
  })

  const ref = useRef(null);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }



  const [dailyAppointmentStackedBarData, setDailyAppointmentStackedBardData] = useState([]);
  const [graphWidth, setGraphWidth] = useState(0);
  const [winWidth] = useWindowSize();

  useEffect(() => {
      setGraphWidth(ref.current.offsetWidth)
  }, [winWidth])

  useEffect(() => {
      getDailyAppointmentData()
          .then(res => {
              // remove 'Appointments' on key,
              // and convert to presentable text
              const newData = res.data.map(d => {
                  Object.keys(d).forEach(k => {
                      const toRemove = 'Appointments'
                      const index = k.indexOf(toRemove);
                      if(index > -1) {
                          let newKey = index > -1? k.replace(toRemove, ''): k;

                          for(let i = 0; i < newKey.length; ++i) {
                            const char = newKey.charAt(i);
                            if(char === char.toLocaleUpperCase()) {
                                const temp = newKey.substr(i, newKey.length - i);
                                newKey = newKey.replace(temp, ' ' + temp );
                                ++i;
                            }
                          }

                          newKey = newKey.replace(newKey.charAt(0), newKey.charAt(0).toLocaleUpperCase());

                          Object.defineProperty(d, newKey, Object.getOwnPropertyDescriptor(d, k));
                          delete d[k];
                      }
                  })
                  return d;
              })
              setDailyAppointmentStackedBardData(newData);
          }).catch(err => {
              message.error(err.toString());
          })
  }, [])

  return (
    <>
      <Row>
        <Space>
          <Col md='3'>
            <HospitalSelect
              hideLabel={true}
              width={{width: '275px'}}
              direction='horizontal'
              onChange={async value => {
                let newParams = params;
                newParams.locationId = value;
                setParams(newParams);
                await refetch();
              }}/>
          </Col>
          <Col>
            <RangePicker
              size='large'
              onChange={async value => {
                let newParams = params;
                if (value === undefined || value === null) {
                  newParams.start = null;
                  newParams.end = null;
                } else {
                  newParams.start = `${moment(value[0]).format('YYYY-MM-DD')}`
                  newParams.end = `${moment(value[1]).format('YYYY-MM-DD')}`
                }
                setParams(newParams);
                await refetch();
              }}
              allowClear={true}
            />
          </Col>
        </Space>
      </Row>
      <br/>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Card
                title={
                  <span className='text-primary font-weight-bold'>
                    Total Expected Appointments
                  </span>
                }
                extra={
                  <span style={{fontSize: '1.75rem'}} className='text-primary font-weight-bold'>
                    {data?.expectedAppointments}
                  </span>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col xs={12} md={8}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Confirmed</span>}
                        value={data?.confirmedAppointments}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={8}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Checked In</span>}
                        value={data?.checkedInAppointments}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={8}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>In Consult</span>}
                        value={data?.inConsultAppointments}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={8}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Unconfirmed</span>}
                        value={data?.unconfirmedAppointments}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={8}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>No Shows</span>}
                        value={data?.noShows}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={8}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Declined</span>}
                        value={data?.declined}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={6}>
              <Card
                title={
                  <span className='text-primary font-weight-bold'>
                    Split By Method
                  </span>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Walk In</span>}
                        value={data?.walkIn}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={24}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Telehealth</span>}
                        value={data?.telehealthVisits}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={6}>
              <Card
                title={
                  <span className='text-primary font-weight-bold'>
                    Split By Type
                  </span>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>1st Appointment</span>}
                        value={150}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                  <Col xs={24}>
                    <Card
                      className='Dashboard-Card'
                      style={{
                        borderRadius: '7px',
                      }}
                    >
                      <Statistic
                        className='text-left'
                        title={<span className='text-white'>Follow up</span>}
                        value={50}
                        //   precision={2}
                        valueStyle={{color: '#fff'}}
                        // prefix={<ArrowUpOutlined />}
                        //   suffix='%'
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col xs={18}>
              <Card
                title={
                  <span className='text-primary font-weight-bold'>
                    Daily Appointments
                  </span>
                }
              >
                  <div ref={ref}>
                  {
                      dailyAppointmentStackedBarData?.length > 0 &&
                      <StackedBarChart
                          data={dailyAppointmentStackedBarData}
                          width={graphWidth}
                          height={400}
                          columns={{ 0: Object.keys(dailyAppointmentStackedBarData[0])}}
                          domainData={Object.keys(dailyAppointmentStackedBarData[0])[0]}
                      />
                  }
                  </div>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                title={
                  <span className='text-primary font-weight-bold'>
                    Forms
                  </span>
                }
              >
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <Card
                        className='Dashboard-Card'
                        style={{
                          borderRadius: '7px',
                        }}
                      >
                        <Statistic
                          className='text-left'
                          title={<span className='text-white'>Total Forms Completed</span>}
                          value={data?.formsCompleted}
                          //   precision={2}
                          valueStyle={{color: '#fff'}}
                          // prefix={<ArrowUpOutlined />}
                          //   suffix='%'
                        />
                      </Card>
                    </Col>
                    <Col xs={24}>
                      <Card
                        className='Dashboard-Card'
                        style={{
                          borderRadius: '7px',
                        }}
                      >
                        <Statistic
                          className='text-left'
                          title={<span className='text-white'>Total Forms Active</span>}
                          value={data?.formsActive}
                          //   precision={2}
                          valueStyle={{color: '#fff'}}
                          // prefix={<ArrowUpOutlined />}
                          //   suffix='%'
                        />
                      </Card>
                    </Col>
                    <Col xs={24}>
                      <Card
                        className='Dashboard-Card'
                        style={{
                          borderRadius: '7px',
                        }}
                      >
                        <Statistic
                          className='text-left'
                          title={<span className='text-white'>Total Forms Incomplete</span>}
                          value={data?.formsIncomplete}
                          //   precision={2}
                          valueStyle={{color: '#fff'}}
                          // prefix={<ArrowUpOutlined />}
                          //   suffix='%'
                        />
                      </Card>
                    </Col>
                  </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
// TODO
