import React, {useState} from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {DeleteOutlined, EditOutlined, FileExcelOutlined} from '@ant-design/icons';
import { Badge } from 'react-bootstrap';
import DeleteModal from 'components/Modals/DeleteModal';
import {Link} from 'react-router-dom';
import {deleteForm, getForms} from 'api/forms';
import CreatePublicForm from './CreatePublicForm';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import DateRangeModal from 'components/Modals/DateRangeModal';
import ExcelIcon from 'assets/icons/excel-icon';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {downloadFormResponses} from 'api/formsSubmissions';
import {message} from 'antd'

const Forms = () => {
    const [draw, setDraw] = useState(0);

    const [collateralId, setCollateralId] = useState("");
    const [filterShown, setFilterShown] = useState(false);
    const [activeFormDesignId, setActiveFormDesignId] = useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            title: <strong>Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (value, record, index) => {
                let statusBadge = null;

                if (record?.status === 'LIVE') {
                    statusBadge = <Badge variant='danger'>LIVE</Badge>
                }

                if (record?.status === 'DRAFT') {
                    statusBadge = <Badge variant='dark'>DRAFT</Badge>
                }

                if (record?.status === 'INACTIVE') {
                    statusBadge = <Badge variant='secondary'>INACTIVE</Badge>
                }

                return <><Link to={`/form-builder/${record?.id}`}>{value}</Link> {statusBadge}</>;
            },
        },
        {
            title: <strong>Options</strong>,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text, record, value) => (
                <ActionButtonsDropdown 
                    menus={
                        [
                            {
                                label: 'Update Form', 
                                icon: <EditOutlined />, 
                                link: `/form-builder/${record && record.id}`
                            },
                            {
                                label: 'Delete Form',
                                icon: <DeleteOutlined />,
                                onClick: () => {
                                    setCollateralId(text)
                                }
                            }, {
                                label: 'Download',
                                icon: <FileExcelOutlined />,
                                dividerBefore: true,
                                hide: record.status !== 'LIVE',
                                onClick: () => {
                                    setActiveFormDesignId(record.id);
                                    setFilterShown(true);
                                }
                            }
                        ]
                    }
                />
            ),
        },
    ];

    return (
        <>
            <DeleteModal
                entity="form"
                visible={collateralId}
                setVisibility={setCollateralId}
                entityId={collateralId}
                deleteFunction={deleteForm}
                onSuccess={() => setDraw(draw + 1)}
            />
            <DateRangeModal
                title={<><ExcelIcon width='50' height='50' />Download Data</>}
                subtitle={
                    <>
                        <p>Form responses will be downloaded as an excel file.</p>
                        <p>To continue, filter out the data by selecting date below</p>
                    </>
                }
                visible={filterShown}
                setVisible={setFilterShown}
                pickerSize='large'
                fullScreen={fullScreen}
                okText='Download'
                onOk={(e, dateArr) => {
                    const startDate = dateArr[0].format('yyyy-MM-DD');
                    const endDate = dateArr[1].format('yyyy-MM-DD');
                    downloadFormResponses(activeFormDesignId, startDate, endDate)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'test.xls'); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }).catch(err => {
                            if(err.response.status === 409) {
                                message.error('No data to download');
                            } else {
                                message.error(err.toString());
                            }

                        })
                }}
                onCancel={() => {
                    setActiveFormDesignId(null);
                }}
            />
            <CRUDDataTable
                draw={draw}
                entity="forms"
                fetchDataFunction={async (params) => {
                    return await getForms(params);
                }}
                createModal={<CreatePublicForm/>}
                // button={<Link to='/form-builder'><Button type="primary">Create New Form</Button></Link>}
                columns={columns}
            />
        </>
    );
};

export default Forms;
