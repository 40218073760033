import React from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {locationsAddLocation} from "../../redux/actions/locations";
import {connect} from "react-redux";

const LocationForm = (props) => {

    return (
        <Formik
            initialValues={{
                id: props.id,
                locationName: props.locationName,
                childrenAllowed: props.childrenAllowed,
                childrenTypeName: props.childrenTypeName,
                openingHour: props.openingHour,
                closingHour: props.closingHour,
                visitCapacity: props.visitCapacity
            }}
            validationSchema={Yup.object({
                locationName: Yup.string()
                    .max(25, 'Must be 3 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, {setSubmitting}) => {
                const location = {
                    id: values.id,
                    locationName: values.locationName,
                    childrenAllowed: values.childrenAllowed,
                    childrenTypeName: values.childrenTypeName,
                    openingHour: values.openingHour,
                    closingHour: values.closingHour,
                    visitCapacity: values.visitCapacity
                }
                props.addLocation(location);
                props.onClose();
            }

            }
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <Form id="locationform" className="form-horizontal">
                        <Field name="id" type="text"/>
                        <div className="modal-header">

                            <h3><span id="locationActionSpan">New</span> Location</h3>
                        </div>
                        <div className="modal-body">
                            <Field name="id" type="hidden"/>

                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="locationName">Name</label>
                                <Field name="locationName" type="text"/>
                                <ErrorMessage name="locationName"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="childrenAllowed">Sublocation</label>
                                <Field name="childrenAllowed" type="checkbox"/>
                                <p>This location can have sublocations</p>
                                <ErrorMessage name="childrenAllowed"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label" htmlFor="childrenTypeName">Sublocations Name</label>
                                <Field name="childrenTypeName" type="text"/>
                                <ErrorMessage name="childrenTypeName"/>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Opening Hours</label>

                                <div className="col-lg-4">
                                    <Field name="openingHour" type="text" className="form-control"/>
                                </div>
                                <label className="col-lg-1 control-label">To</label>
                                <div className="col-lg-4">
                                    <Field name="closingHour" type="text" className="form-control"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Visit Capacity (per hour)</label>
                                <div className="col-lg-4">
                                    <Field name="visitCapacity" type="number"/>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-default pull-left" data-dismiss="modal"
                                    onClick={props.onClose}>Close
                            </button>
                            <button type="submit" className="btn btn-success"
                                    id="savePropertyButton">Save
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
};

const mapStateToProps5 = (state) => {
    return {
        locations: state.locations,
        hasErrored: state.locationsHasErrored,
        isLoading: state.locationsIsLoading
    };
};
const mapDispatchToProps5 = (dispatch) => {
    return {
        addLocation: (location) => dispatch(locationsAddLocation(location))
    };
}


export default connect(mapStateToProps5, mapDispatchToProps5)(LocationForm);