import React, {Dispatch, FunctionComponent, useRef} from "react";
import {Col, Modal, Row, Typography} from "antd";
import {FormOutlined} from "@ant-design/icons";
import {Formik, FormikProps} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import {Notifications} from "@material-ui/icons";

const { Title } = Typography;
const { TextArea } = Input;

const CreateNotificationsTemplateModal = (props: any) => {
    const formikRef = useRef<FormikProps<any>>(null);

    return  <Modal
        title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}><Notifications className="mr-2"  />New Notifications Template</Title>}
        visible={props.visible}
        onCancel={() => props.setVisibility(false)}
        footer={null}
    >
        <Formik
            innerRef={formikRef}
            initialValues={{name: ""}}
            // validationSchema={CreateCollateralSchema}
            onSubmit={values => console.log(values)}
            onReset={() => {
                formikRef?.current?.resetForm();
            }}
            render={() => (
                <Form layout="vertical" colon={false}>
                    <Form.Item label="Template Name" name="name" className="mb-4">
                        <Input name="name"/>
                    </Form.Item>
                    <Form.Item label="Email Subject" name="emailSubject" className="mb-4">
                        <Input name="emailSubject"/>
                    </Form.Item>
                    <Form.Item label="Email Body" name="emailBody" className="mb-4">
                        <TextArea rows={4} name="emailBody"/>
                    </Form.Item>
                    <Form.Item label="SMS Body" name="smsBody" className="mb-4">
                        <TextArea rows={4} name="smsBody"/>
                    </Form.Item>
                    <Row gutter={4} className="d-flex justify-content-end">
                        <Col>
                            <ResetButton>Reset</ResetButton>
                        </Col>
                        <Col>
                            <SubmitButton>Create</SubmitButton>
                        </Col>
                    </Row>
                </Form>
            )}
        />
    </Modal>
}

export default CreateNotificationsTemplateModal;