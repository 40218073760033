import { Select, Space, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import HospitalSelect from "../../../components/location/hospitalSelect";
import { Form } from "formik-antd";
import { groupBy } from "../../../utils/helpers";

const RuleCreation = ({
  index,
  addRule,
  addValue,
  value,
  actionOptionTemplates,
}) => {
  const { Option } = Select;
  const defaultDisplay = (
    <Select size="large" style={{ width: "100%" }}></Select>
  );
  const [option, setOption] = useState(defaultDisplay);
  const appointmentTypes = ["CLINIC", "TELEHEALTH"];

  const addNewValue = (val) => {
    addValue(`${val}`, index);
  };

  useEffect(() => {
    setValues(value?.checkedValue);
  }, [value]);

  function setValues(val) {
    if (val === "APPOINTMENT_TYPE" || val === "Appointment Type") {
      let selectOptions = (
        <Select
          size="large"
          style={{ width: "100%" }}
          onChange={addNewValue}
          defaultValue={value.requiredValue}
        >
          {/*{appointmentTypes.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })}*/}

          {actionOptionTemplates
            .filter((f) => f.actionType === "RULE")
            .map((item) => {
              return (
                <Option value={item.valueDisplay} key={item.valueDisplay}>
                  {item.valueDisplay}
                </Option>
              );
            })}
        </Select>
      );
      setOption(selectOptions);
    } else if (val === "APPOINTMENT_LOCATION") {
      let hospitalSelect = (
        <HospitalSelect
          onChange={addNewValue}
          removeAllOption={true}
          // direction='horizontal'
          hideLabel={true}
          defaultValue={value.requiredValue}
        />
      );
      setOption(hospitalSelect);
    }
  }

  const addNewRule = (val) => {
    console.log(val);
    setValues(val);
    addRule(val, index);
  };

  const rules = actionOptionTemplates.filter((f) => f.actionType === "RULE");
  const groupedRules = groupBy(rules, "valueType");

  return (
    <>
      <label>Required Value:</label>
      <Row gutter={[4,4]} style={{ width: "100%" }}>
        <Col span={12}>
          <Select
            onChange={addNewRule}
            size="large"
            style={{ width: "100%" }}
            defaultValue={value.checkedValue ?? "APPOINTMENT_TYPE"}
          >
            {/*<Option value="APPOINTMENT_TYPE" key="APPOINTMENT_TYPE">
              Type
            </Option>
            <Option value="APPOINTMENT_LOCATION" key="APPOINTMENT_LOCATION">
              Location
            </Option>*/}
            {groupedRules.map((item) => {
              return (
                <Option value={item.valueType} key={item.valueType}>
                  {item.valueType}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={12}>{option}</Col>
      </Row>
    </>
  );
};

export default RuleCreation;
