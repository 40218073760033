import React from 'react';
import Dashboard from "../pages/Dashboard/Dashboard";
import AppointmentsList from "pages/Appointments/AppointmentsList";
import Queue from "../pages/Queue/Queue";
import CheckIn from "../pages/CheckIn/CheckIn";
import Locations from "../pages/Location/Locations";
import Settings from "../pages/Settings/Settings";
import LocationModalForm from "../pages/Location/LocationModalForm";
// import Collaterals from "../pages/Collateral/Collaterals";
import Visitors from "pages/Visitors/Visitors";
import Messages from "../pages/Messages/Messages";
import Questions from "../pages/Questions/Questions";
import Documents from "../pages/Documents/Documents";
import Users from "../pages/Users/Users";
// import CollateralDetails from "../pages/Collateral/CollateralDetails";
import Appointment from 'pages/Appointment';
import CollateralPage from 'pages/Collateral';
import FormBuilder from 'pages/FormBuilder';
import Forms from "../pages/forms/forms";
import Tasks from "../pages/tasks/Tasks";

import { useAppSelector } from './../hooks/use-app-selector';
import Broadcasts from './../pages/Broadcasts/index';
import NotificationsTemplates from 'pages/NotificationsTemplates';
import CheckedInPatients from "../pages/CheckedInPatients";

const Routes =(enableQuery = false) => {

    const permissions = useAppSelector(state => state.permissions.permissions);
    console.log(permissions);

    return ([
        permissions.Dashboard ? {
            path: '/dashboard',
            title: 'Dashboard',
            component: <Dashboard/>
        } : null,
        permissions.Appointments ? {
            path: '/appointments',
            title: 'Appointments',
            component: <AppointmentsList/>,
            components: [
                {
                    path: '/:id',
                    // title: 'View Appointment',
                    component: <Appointment/>
                }
            ]
        } : null,
        {
            path: '/queue',
            title: 'Queue',
            component: <Queue/>
        },
        permissions.Check_In_Out ? {
            path: '/checkin',
            title: 'Check In/Out',
            component: <CheckIn/>
        } : null,
        permissions.Locations ? {
            path: '/locations',
            title: 'Locations',
            component: <Locations/>,
            components: [
                {
                    path: '/:id',
                    component: <Locations/>
                }
            ]
        } : null,
        {
            path: '/locationmodalform',
            title: 'LocationModalForm',
            component: <LocationModalForm/>
        },
        permissions.Users ? {
            path: '/users',
            title: 'Users',
            component: <Users/>
        } : null,
        {
            path: '/form-builder',
            title: 'Form Builder',
            component: <FormBuilder/>,
            components: [
                {
                    path: '/:id',
                    component: <FormBuilder/>
                }
            ]
        },
        permissions.Forms ? {
            path: '/forms',
            title: 'Forms',
            component: <Forms/>
        } : null,

        permissions.Settings ? {
            path: '/settings',
            title: 'Settings',
            component: <Settings/>
        } : null,
        permissions.Visitors ? {
            path: '/visitors',
            title: 'Accepted Visitors',
            component: <Visitors/>
        } : null,
        {
            path: '/questions',
            title: 'Questions',
            component: (props: any) => <Questions {...props} mode={"Edit"}/>
        },
        {
            path: '/documents',
            title: 'Documents',
            component: (props: any) => <Documents {...props} mode={"Edit"}/>
        },
        {
            path: '/messages',
            title: 'Messages',
            component: (props: any) => <Messages {...props} mode={"Edit"}/>
        },
        permissions.Resources ? {
            path: '/collateral',
            title: 'Resources',
            component: <CollateralPage/>,
            // components: [
            //     {
            //         path: '/:id',
            //         title: 'View Collateral',
            //         component: <CollateralDetails />
            //     }
            // ]
        } : null,
        permissions.Tasks ? {
            path: '/tasks',
            title: 'Tasks',
            component: <Tasks/>
        } : null,
        permissions.Broadcasts ? {
            path: '/broadcasts',
            title: 'Broadcasts',
            component: <Broadcasts />
        } : null,
        {
            path: "/notifications-templates",
            title: "Notifications Templates",
            component: <NotificationsTemplates />
        },
        {
            path: "/checked-in-patients",
            title: "Checked In Patients",
            component: <CheckedInPatients />
        },
        {
            path: "/queue-control",
            title: "Queue Control",

        }
    ]).filter(route => route);
}
export default Routes;